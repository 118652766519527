import { setStore, getStore } from '@/utils/store'

const gamerole = {
    state: {
        roleId: getStore({ name: 'roleId' }) || 0,
        name: getStore({ name: 'roleName' }) || "",
        vip: getStore({ name: 'roleVip' }) || "",
        level: getStore({ name: 'roleLevel' }) || 0,
        serverName: getStore({ name: 'roleServerName' }) || "",
        serverId: getStore({ name: 'roleServerId' }) || "",
    },
    mutations: {
        setRoleId(state, value) {
            state.roleId = value
            setStore("roleId", state.roleId)
        },
        setName(state, value) {
            state.name = value
            setStore("roleName", state.name)
        },
        setVip(state, value) {
            state.vip = value
            setStore("roleVip", state.vip)
        },
        setLevel(state, value) {
            state.level = value
            setStore("roleLevel", state.level)
        },
        setServerName(state, value) {
            state.serverName = value
            setStore("roleServerName", state.serverName)
        },
        setServerId(state, value) {
            state.serverId = value
            setStore("roleServerId", state.serverId)
        }
    },
    actions: {
        initGameRole (state, info) {
            state.commit('setRoleId', parseInt(info.roleId))
            state.commit('setVip', info.vip)
            state.commit('setLevel', parseInt(info.roleLevel))
            let roleName = '';
            if (info.roleName) {
                roleName = info.roleName
            } else {
                roleName = info.rolename
            }
            state.commit('setName', decodeURI(roleName))
            let server = '';
            if (info.serId) {
                server = info.serId
            } else {
                server = info.server
            }
            let serName = info.serName || server;
            state.commit('setServerId', server)
            state.commit('setServerName', serName)
        }
    }
}

export default gamerole;