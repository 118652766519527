module.exports = {
    LOCAL:{
        zh_CN:"简体中文",
        zh_TW:"繁體中文",
        en_US:"English",
        ko_KR:"한국어",
        ru_RU:"Русский",
        de_DE:"Deutsch",
        ja_JP:"日本",
        pt_BR:"Português",
        tr_TR:"Türkiye",
        vi_VN:"Tiếng Việt",
        es_LA:"Español Americano",
        ar_SA:"عربي",
    },
    SERVER:{
        server_wz_limit_count:"次數已達上限",
        server_wz_task_cd:"CD冷卻中",
        server_wz_lack_money:"餘額不足",
        server_result_success:"操作成功",
        server_result_fail:"操作失敗",
    },
    LAN:{
        change_pwd:"修改密碼",
        change_pwd_tips:"您正在為帳號修改密碼，請妥善保管您的新密碼",
        unbound:"(未綁定)",
        default_language:"默認語言",
        user_service:"用戶服務協議",
        bind_email:"綁定郵箱",
        loading:"加載中...",
        helper:"幫助",
        gift:"禮包",
        mine:"我的",
        confirm:"確定",
        cancel:"取消",
        bind_email_tips:"您正在為帳號綁定郵箱，綁定郵箱能有效保障帳號安全，可通過郵箱找回密碼。",
        input_old_email:"請輸入舊郵箱",
        input_old_verify_code:"請輸入舊郵箱驗證碼",
        input_new_verify_code:"請輸入郵箱驗證碼",
        input_validator_email:"請正確輸入郵箱帳號",
        input_bind_email:"請輸入您要綁定的郵箱",
        verify_code:"驗證碼",
        next:"下一步",
        bind_now:"立即綁定",
        tips:"*溫馨提示",
        email_tips:"部分免費email郵箱，信件有可能被誤判為垃圾信，請先至【垃圾信箱】查看獲取驗證碼。",
        send_verify_code:"發送驗證碼",
        sending:"發送中...",
        send_success:"發送成功",
        resend:"重新發送",
        old_pwd:"原密碼",
        input_old_pwd:"請輸入原密碼",
        new_pwd:"新密碼",
        input_new_pwd:"請輸入新密碼,長度6-15個字元",
        confirm_pwd:"確認新密碼",
        input_confirm_pwd:"請重複輸入新密碼",
        change_now:"立即修改",
        switch_account:"切換帳號",
        playing:"您正在玩",
        search_placeholder:"請輸入搜索關鍵字",
        search:"搜索",
        empty:"暫無數據",
        self_service:"自助服務",
        submit_feedback:"提交回饋",
        online_service:"線上客服",
        official_verification:"官方驗證",
        myservice:"我的回饋",
        questionTabs_1:"熱門問題",
        questionTabs_2:"充值相關",
        questionTabs_3:"遊戲相關",
        questionTabs_4:"登錄相關",
        questionTabs_999:"更多問題",
        question_detail:"問題詳情",
        question_common:"常見問題",
        search_empty:"無搜索內容",
        feedback_detail:"回饋詳情",
        contact_email:"聯繫郵箱",
        screenshot:"截圖",
        detailed_info:"詳細資訊",
        my_reply:"我的回復",
        kf_reply:"客服/0/的回復",
        input_feedback:"請您輸入詳細描述問題",
        service_score:"請為本次服務打分",
        score:"/0/分",
        scored:"已評分，感謝您的回饋",
        thank_feedback:"已評分，感謝您的回饋",
        score_tips:"請先選擇評分",
        feedback_processing:"您的回饋正在處理中，請耐心等待哦",
        feedback_tips:"如果您的問題仍未解決，請不要提交重複回饋，您可以繼續回復此問題",
        submit:"提交",
        processing:"正在處理中",
        replied:"已回復",
        completed:"已完成",
        uploading:"上傳中...",
        upload_failed:"上傳失敗",
        upload_tips:"請等待圖片上傳完成",
        robot_name:"機器人小助手",
        robot_name2:"小助手為您服務",
        welcom_msg:"感謝來訪~可通過發送問題關鍵字進行詢問哦~小助手會儘量提供幫助的",
        change_batch:"換一批",
        forget_pwd:"忘記密碼",
        game_web:"遊戲官網",
        contact_customer:"轉人工",
        ques_placeholder:"請輸入您想提問的問題",
        send:"發送",
        connection:"會話連接中...",
        enter_question:"請先輸入問題",
        are_you_asking:"您是想問:",
        robot_not_found:"抱歉沒有明白所說的意思~為了更好的解決您的問題，建議您可以換一種問法。如您需要人工客服的幫助，請點擊轉人工按鈕",
        customer_service:"人工客服",
        feedback_0:"充值未到賬",
        feedback_1:"BUG相關",
        feedback_2:"舉報角色",
        feedback_3:"建議回饋",
        feedback_4:"帳號找回",
        feedback_5:"提交截图",
        feedback_server:"所在服",
        feedback_rolename:"角色名",
        feedback_price:"訂單金額",
        feedback_order:"商戶訂單號",
        feedback_report:"舉報角色",
        feedback_username:"帳號",
        feedback_registerTime:"帳號註冊時間",
        feedback_registerDevice:"註冊手機型號",
        feedback_lastLoginTime:"最後登錄時間",
        feedback_lastPayTime:"最近1個月充值時間",
        feedback_lastPayPrice:"最近1個月充值金額",
        feedback_lastPayOrder:"最近1個月充值訂單號",
        feedback_server_tips:"請輸入所在服",
        feedback_rolename_tips:"請輸入角色名",
        feedback_price_tips:"請輸入訂單金額",
        feedback_order_tips:"請輸入商戶訂單號",
        feedback_report_tips:"請輸入舉報角色",
        feedback_username_tips:"請輸入找回帳號",
        feedback_registerTime_tips:"請輸入帳號註冊時間",
        feedback_registerDevice_tips:"請輸入註冊手機型號",
        feedback_lastLoginTime_tips:"請輸入最後登錄時間",
        feedback_lastPayTime_tips:"請輸入最近1個月充值時間",
        feedback_lastPayPrice_tips:"請輸入最近1個月充值金額",
        feedback_lastPayOrder_tips:"請輸入最近1個月充值訂單號",
        order_helper:"如何查詢訂單號?",
        max_img:"圖片，最多只能上傳3個",
        contact:"聯繫方式",
        detailed_desc:"詳細描述",
        myservice_count:"您當前有",
        myservice_count2:"個回饋正在處理中",
        nomore:"沒有更多了",
        feedback_state:"回饋狀態",
        feedback_type:"問題類型",
        create_time:"創建時間",
        gift_title:"遊戲禮包",
        mygift_title:"我的禮包",
        recommend:"推薦遊戲",
        popularity:"人氣",
        play:"開始玩",
        hot:"火爆",
        gift_empty:"暫無禮包,敬請期待",
        input_contact_email:"請輸入聯繫郵箱",
        same_password:"原始密碼與新密碼一致，無需修改",
        success:"操作成功",
        input_email:"請輸入郵箱",
        email:"郵箱",
        forgot:"找回密碼",
        forgot_from_email:"通過綁定郵箱找回",
        forgot_from_service:"聯繫客服找回",
        reset_password:"確認重置密碼",
        unbind_email:"請先綁定郵箱後再進行解綁",
        unbind_fb:"解綁後將不能用Facebook繼續登錄此帳號，您確認解綁嗎？",
        unbind_google:"解綁後將不能用Google繼續登錄此帳號，您確認解綁嗎？",
        unbind_twitter:"解綁後將不能用Twitter繼續登錄此帳號，您確認解綁嗎？",
        tips_title:"提示",
        copy_success:"複製成功~",
        copy_faild:"複製失敗,該流覽器不支持複製",
        gift_rate_task:"請正確完成評論後才可領取本禮包喔",
        requesting:"請求中",
        get_gift:"領取禮包",
        gift_rate:"前往評論",
        gift_rate2:"前往評價",
        gift_share:"前往分享",
        gift_bind:"前往綁定",
        gift_fill:"前往填寫",
        gift_ads:"立即觀看",
        gift_copy:"複製禮包碼",
        gift_exchange:"兌換方法",
        gift_exchange_desc:"福利-啟動碼-輸入禮包碼啟動",
        password_help:"如何查看自己的帳號密碼?",
        password_help_1:"1.在遊戲內點擊左上角頭像",
        password_help_2:"2.點擊底部的紅色按鈕“用戶中心”",
        password_help_3:"3.點擊“切換帳號”",
        password_help_4:"4.點擊圖中的小眼睛按鈕，即可查看到當前帳號密碼?",
        payhelp_title:"請選擇支付平臺",
        payhelp_google:"使用Google play進行查詢",
        payhelp_google1:"1.打開google play點擊左側菜單欄",
        payhelp_google2:"2.點擊我的帳戶",
        payhelp_google3:"3.點擊訂單記錄",
        payhelp_google4:"4.點擊對應的訂單查看收據",
        payhelp_wechat:"使用Wechat進行查詢",
        payhelp_wechat1:"1.點擊打開wechat",
        payhelp_wechat2:"2.點擊【我】的選項",
        payhelp_wechat3:"3.點擊支付-錢包",
        payhelp_wechat4:"4.點擊帳單",
        payhelp_wechat5:"5.點擊對應訂單，查看訂單收據",
        payhelp_paypal:"使用Paypal進行查詢",
        payhelp_paypal1:"1.登錄paypal帳號",
        payhelp_paypal2:"2.點擊交易記錄",
        payhelp_paypal3:"3.點擊詳情查看",
        payhelp_paypal4:"4.點擊對應訂單查看詳情",
        payhelp_alipay:"使用Alipay進行查詢",
        payhelp_alipay1:"1.點擊打開Alipay",
        payhelp_alipay2:"2.點擊【我的】選項",
        payhelp_alipay3:"3.點擊帳單",
        payhelp_alipay4:"4.點擊對應訂單，查看收據",
        bind_mobile:"綁定手機",
        bind_mobile_tips:"您正在為帳號綁定手機號，綁定手機號能有效保障帳號安全，可通過手機號找回密碼。",
        input_old_mobile:"請輸入舊手機號",
        input_validator_mobile:"請正確輸入手機號",
        input_bind_mobile:"請輸入您要綁定的手機號",
        mobile_tips:"部分手機號，資訊有可能被誤判為垃圾資訊，請先至【垃圾信箱】查看獲取驗證碼。",
        contact_mobile:"聯繫手機號",
        input_contact_mobile:"請輸入聯繫手機號",
        input_mobile:"請輸入手機號",
        mobile:"手機號",
        unbind_mobile:"請先綁定郵箱後再進行解綁",
        input_old_verify_mobile:"請輸入舊手機號驗證碼",
        input_new_verify_mobile:"請輸入手機號驗證碼",
        pay_title:"本次消費約為",
        pay_change_currency:"切換幣種",
        pay_tips:"（最終支付金額會根據不同的支付方式和匯率有所變動）",
        pay_select_mode:"請選擇支付方式",
        wechat:"微信支付",
        alipay:"支付寶支付",
        pay_paying:"正在支付",
        pay_fail:"充值失敗,請稍後再試",
        pay_return_tips:"如支付已完成，請關閉此頁面，否則請重新發起支付。",
        pay_discount:"(優惠/0/%)",
        pay_late_mode:"最近選擇支付方式",
        pay_other_mode:"其他支付方式",
        pay_more:"更多支付方式",
        pay_point:"/0/點送/1/點",
        points:"遊戲點數充值",
        tally:"點數",
        give_away:"含點送",
        voucher:"代金卷",
        waiting_used: "待使用",
        current_points:"當前遊戲點：",
        pay:"充值",
        pwd:"密碼",
        pay_setting:"設置支付密碼",
        select_amount:"請選擇要充值的遊戲點",
        forget:"找回支付密碼",
        forget_pay:"忘記支付密碼",
        input_pwd:"請輸入6位數字支付密碼",
        different:"密碼和確認密碼不一致",
        setting_tips:"您正在為帳號設置支付密碼，請妥善保管您的支付密碼",
        remain:"剩餘",
        add:"遊戲點儲值",
        pay_mode:"支付方式",
        change_payPwd_tips:"您正在為帳號修改支付密碼，請妥善保管您新的支付密碼",
        unsetting:"未設置支付密碼",
        email_unbound:"郵箱未綁定",
        confirm_pay_pwd:"確認支付密碼",
        input_confirm_pay_pwd:"請重複輸入支付密碼",
        payPassword_error:"支付密碼錯誤",
        balance:"餘額不足",
        gamePoints:"遊戲點",
        pay_complete:"支付已完成",
        pay_new_tips:"充值完成前請不要關閉此窗口",
        pay_new_message:"請在新開的頁面完成充值",
        pay_cancel:"取消支付",
        back:"返回",
        fee_name:"推廣合作創收服務",
        fee_text1:"與HandZone Games攜手共進",
        fee_text2:"成為HandZone Games的合夥夥伴後，您就能夠獲得自己的推廣鏈接，通過次鏈接下載安裝的用戶充值後，您可以獲得對應的充值分紅！",
        fee_text3:"如需加入HandZone Games推廣合作創收服務，您需要滿足下列條件。",
        fee_price:"等額充值金額:",
        fee_text4:"滿足條件後將自動開啟推廣合作創收服務，開通後您將可獲得開通合作計畫後，通過您推廣新增加的玩家的充值金額最低10%~70%(根據推廣級別不同分成不同)的充值分成。",
        fee_text5:"恭喜您開通推廣合作創收服務",
        fee_text6:"您的推廣鏈接為：",
        fee_copy:"複製鏈接",
        fee_text7:"當前推廣級別分紅示意圖如下：",
        fee_text8:"A.B.C分別充值/0/",
        fee_text9:"您可獲得：",
        fee_text10:"A可以無限發展",
        fee_text11:"當前您得一級推廣人員為/0/人，二級為/1/人，三級為/2/人",
        fee_price2:"可提現金額:",
        fee_start:"開始推廣",
        fee_submit:"提現",
        fee_tips1:"未滿足提現條件",
        contact_kf:"聯繫客服",
        fee_tips2:"請聯繫客服進行提現",
        account_z:"賬號",
        account_destroy:"帳號註銷",
        account_destroy_now:"申請註銷帳號",
        account_destroy_cancel:"取消註銷帳號",
        account_destroy_time:"註銷帳號剩餘時間：",
        account_destroy_time2:"/0/ 天後",
        account_destroy_cancel_tips:"您已取消註銷帳號申請",
        account_destroy_read_tips:"我已經看過並同意",
        account_destroy_read_tips2:"《帳號註銷須知》",
        account_destroy_content1:"● 1. 註銷成功後該帳號將<a>無法在我司的帳號平臺及旗下任一款產品上進行登錄</a>且<a>帳號以及數據不可恢復</a>；",
        account_destroy_content2:"● 2. 帳號註銷需要<a>收集您的個人敏感資訊（包括郵箱驗證碼）</a>用於核驗身份，帳號註銷後，我司將會<a>刪除上述資訊或匿名化處理</a>；",
        account_destroy_content3:"● 3. 帳號成功註銷後，該帳號下的所有內容、資訊、記錄將會被<a>刪除或匿名化處理</a>，您也無法再檢索、訪問、獲取、繼續使用和找回，也無權要求我司找回（但法律法規另有規定或監管部門另有要求的除外）；",
        account_destroy_content4:"● 4. 帳號註銷之後，對應的帳號交易平臺將無法登錄使用，<a>您申請帳號註銷的行為即表明您自願放棄該帳號下的利益</a>，<a>在註銷前，請再三確認平臺上的交易和提現申請已完成</a>，避免帳號註銷後因無法登錄而造成不必要損失；",
        account_destroy_content5:"● 5. 帳號註銷後，<a>我司有權不再為您提供任何該帳戶有關的服務</a>，您與我司曾簽署過的相關用戶協議、其他權利義務性檔等相應終止（但我司與您之間已約定繼續生效的或法律法規另有規定的除外）；",
        account_destroy_content6:"● 6. 因註銷帳戶所帶來的不便或不利後果，由您自行承擔，與我司無關；",
        account_destroy_content7:"● 7. 如果帳號註銷期間存在權屬糾紛，我司有權終止註銷申請；",
        account_destroy_content8:"● 8. 帳號註銷有7天的確認期，期間您可以再次來此介面取消註銷，確認期過後將無法再取消註銷；",
        unbind_account_destroy:"請先綁定郵箱後再進行註銷帳號",
        pay_go:"前往儲值",
        either_or:" 或 ",
        my_info_avatar:"頭像",
        avatar:"頭像   ",
        look_avatar:"查看頭像",
        change_avatar:"更換頭像",
        avatar_reviewing:"審核中",
        avatar_review_fail:"審核失敗",
        zbm0006:"尊敬的玩家，你上傳的自定義頭像因涉及/0/，審核未通過",
        shhz0001:"政治敏感內容",
         shhz0002:"色情內容",
          shhz0003:"賭博內容",
        shhz0004:"毒品內容",
        shhz0005:"宗教敏感內容",
        shhz0006:"帶有其他網站或者網站地址",
        shhz0007:"帶有廣告內容",
        shhz0008:"不雅、低俗內容",
        account:"用戶名",
        vip_kf:"VIP客服",
        super_vip:"超級會員",
        super_vip_kf:"超級會員專屬客服",
        super_vip_be:"成為尊貴的超級會員<br/>獲得專屬客服",
        super_vip_info1:"超級會員卡專屬服務",
        super_vip_info2:"（僅對成年玩家開放）",
        super_vip_info3:"1、專屬通道<br/>專屬客服一對一貼心服務，意見建議直通策劃！ <br/>2、專屬優先<br/>遊戲第一手資料，優先獲取<br/>3.專屬禮包<br/>成為超級會員，領取超級大禮包！ <br/>4、專屬活動<br/>專屬不定時活動和福利！",
        super_vip_gain1:"如何獲取超級會員",
        super_vip_gain2:"方法一：歷史累計充值/0/",
        super_vip_get:"立即獲取",
        super_vip_gift:"超級VIP專屬禮包",
        super_vip_gift_tips:"驗證成功後，可領取禮包，快去聯繫客服吧！",
        super_vip_gift_1:"遊戲稱號",
        super_vip_gift_2:"稀有裝扮",
        super_vip_gift_3:"寵物",
        super_vip_gift_4:"定制禮包",
        super_vip_gift_5:"稀有道具",
        super_vip_gift_wechat:"微信號：",
        feedback_reward_1:"打賞客服",
        feedback_reward_2:"打賞",
        feedback_reward_3:"打賞是您自願對客服的贈與，將全額打給客服",
        wechat_native_1:"支付金額：",
        wechat_native_2:"請打開手機微信，掃一掃完成支付",
        wechat_native_3:"已完成支付",
        login_usern:"登錄賬號",
        register_username:"註冊賬號",
        quick_register:"快速註冊",
        enterNow:"立即登入",
        btn_register:"立即註冊",
        agreed1:"我同意",
        btn_have_usern:"已有賬號",
        to_login:"前往登錄",
        login_success:"登錄成功",
        agree_protocol:"請先同意遊戲用戶服務協議",
        UserLogin:"登錄",
        UserLogin_text1:"請填寫用戶名",
        UserLogin_text2:"請填寫密碼",
        UserLogin_text4:"登錄中",
        UserLogin_text5:"註冊中",
        UserLogin_text6:"註冊成功",
        online_earning:"網賺",
        online_earning_center:"網賺中心",
        online_earning_sigtiaoin:"簽到挑戰",
        online_earning_text1:"做任務<br>賺現金",
        online_earning_text2:"每天觀看1次視頻可進行簽到<br/>累計簽到7天可獲得豐厚獎勵",
        online_earning_text3:"可在兌換中心兌換獎勵",
        online_earning_text4:"看一看，領取獎勵",
        online_earning_text5:"剩/0/次",
        online_earning_text6:"次數不足",
        online_earning_text7:"待領取",
        exchange:"兌換/0/",
        exchange_center:"兌換中心",
        exchange_prompt:"兌換提示",
        exchange_confirm:"確認兌換",
        exchange_consume:"消耗:/0/",
        exchange_balance:"餘額",
        exchange_detail:"明細",
        exchange_balance_detail:"餘額明細",
        exchange_reward:"兌換獎勵",
        exchange_text1:"/0/點",
        exchange_text2:"/0/元",
        exchange_text3:"遊戲點數",
        exchange_text4:"現金紅包",
        exchange_text5:"永久限兌：/0///1/",
        exchange_text6:"每日限兌：/0///1/",
        exchange_text7:"請輸入邀請碼進行綁定",
        exchange_text8:"請輸入PAYPAL賬號",
        exchange_text9:"邀請人綁定",
        exchange_text10:"確認綁定",
        exchange_text11:"兌換成功，可前往用戶中心查看結果",
        exchange_text12:"數量不足",
        exchange_text13:"僅展示最近100條記錄",
        exchange_rule:"規則",
        exchange_rule2:"兌換規則",
        exchange_rule2_html:"1、賬號需要綁定郵箱才能使用賬號兌換功能；<br/>2、兌換時請填寫準確信息，如因個人原因綁定到錯誤賬號或者兌換錯誤，損失由您個人承擔；<br />3 、如兌換失敗，請提供查看失敗提示，無法解決時，請聯繫客服處理；<br />4、您一旦發起兌換，視為同意以上規則，兌換最終解釋權歸我司所有",
        invite_Friends:"邀請好友",
        invite_binding:"邀請人綁定",
        new_envelope:"新人紅包",
        signReward:"完成簽到",
        completeTask:"完成/0/任務",
        completeExchange:"兌換/0/消耗",
        language:"語言",
        country:"國家/地區",
        select_pay_mode:"選擇以獲得合適的本地付款",
        exchange_text14:"暫未開啟",
        game_hub:"遊戲中心",
        hyuk_jang:"張",
        not_login:"未登錄",
        unlock:"登錄解鎖更多精彩內容",
        for_the_account:"你正在為帳號",
        for_the_account_1:"綁定郵箱，綁定郵箱能有效保障帳號安全，可通過郵箱找回密碼。",
        account_length:"賬號長度為6-18位字符",
        modify_successfully: "修改成功",
        binding_successful :"綁定成功",
        start_game: "開始",
        game_down : "下載",
        new_travel: "新游",
        hint_func: "功能暫未開放，敬請期待待",
        please_acc_code:"請輸入帳號",
        please_paw_code:"請輸入密碼",
        agree: "同意",
        install: "設置",
        logout:"退出登錄",
        is_logout_account:"是否退出登錄帳號",
        top_free:"熱門免費",
        top_jackpot:"熱門暢銷",
        peace:"和",
        priv_policy:"隱私政策"
    }
}
