import { createStore } from "vuex";
import app from "./modules/app";
import user from "./modules/user";
import gamerole from "./modules/gamerole";
import pay from "./modules/pay";
import getters from './getters'

export default createStore({
    modules: {
        app,
        user,
        gamerole,
        pay
    },
    getters,
});
