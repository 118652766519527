import store from "@/store";

//vip样式

const obj={
//字体： 1白 2金 3灰 (4金) (5白) 6黑
1:"color: #fff;",2:'background-image:-webkit-linear-gradient(#ffeb98,#caab62); -webkit-background-clip:text;-webkit-text-fill-color:transparent; ',3:'color: #888f98;',4:"#e7ce81",5:"white",6:"color: #1d1d1d;",

//背景： 10黑 11灰 (12黑 )13金
10:"background-color: #1d1d1d;",11:"background-color: #2b292e;",12:"#1d1d1d",13:"background-image:-webkit-linear-gradient(#ffeb98,#caab62);",

//图片
20:"1",

//边框
30:'border-bottom: 1px solid #888f98;',
}

//@param:a 

export function Vip_Normal(a) {
  return store.getters.userVip? obj[a] || 'V' : "";
}

//阿拉伯样式
const ar_SA={
  1:'text-align: right;',
  2:'text-align: left;',
}


export function lang(a) {
  return store.getters.lang=='ar_SA'? ar_SA[a] || 'L' : "";
}
//多语言切换
export function I18n(p,res) {
  p.$i18n.locale= res || 'en_US'
  store.commit('setLang', res)
}

export function language() {
  var lang = navigator.language||navigator.userLanguage;//常规浏览器语言和IE浏览器
  lang = lang.toLowerCase();
  var sdkLang = "";
  if(lang == 'zh-TW' || lang == 'zh-HK') {
      sdkLang = "zh_TW";
  } else if (lang.substr(0, 2) == "ko") {
      sdkLang = "ko_KR";
  } else if (lang.substr(0, 2) == "ru") {
      sdkLang = "ru_RU";
  } else if (lang.substr(0, 2) == "zh") {
      sdkLang = "zh_CN";
  } else if (lang.substr(0, 2) == "ja") {
      sdkLang = "ja_JP";
  } else if (lang.substr(0, 2) == "de") {
      sdkLang = "de_DE";
  } else if (lang.substr(0, 2) == "tr") {
      sdkLang = "tr_TR";
  } else if (lang.substr(0, 2) == "pt") {
      sdkLang = "pt_BR";
  }
  store.commit('setLang', sdkLang)
}

export function showRegister() {
  var u = randomWord(1, 1) + Date.parse(new Date()) / 1000 + randomWord(1, 1);
  var p = randomWord(10, 10);
  return { u, p };
}
export function randomWord(min, max) {
  var str = "";
   var range = min;
   const arr = ['a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n', 'o', 'p', 'q', 'r', 's', 't', 'u', 'v', 'w', 'x', 'y', 'z', '1', '2', '3', '4', '5', '6', '7', '8', '9', '0'];
    range = Math.round(Math.random() * (max - min)) + min;
  for (var i = 0; i < range; i++) {
   let pos = Math.round(Math.random() * (arr.length - 1));
    str += arr[pos];
  }
  return str;
}

export function afterTiem(tiem) {
  const res = tiem - ((-480 - new Date().getTimezoneOffset()) * 60 * 1000) - Date.now()
  return res
}