import { setStore, getStore } from '@/utils/store'

const user = {
    state: {
        token:  getStore({ name: 'token' }) || '',
        lastUsername: getStore({ name: 'lastUsername' })  || '',
        lastPassword: getStore({ name: 'lastPassword' })  || '',
        username:  getStore({ name: 'username' }) || '',
        password:  getStore({ name: 'password' }) || '',
        userId:  getStore({ name: 'userId' }) || -1,
        isLogin: getStore({ name: 'isLogin' }) || false,
        needLogin: false,
        email: getStore({ name: 'email' }) || "",
        points: 0,
        payPasswordFlag: false,
        currency: 'CNY',
        mobile: getStore({ name: 'mobile' }) || "",
        paymodes: "",
        official: "", // 支付模式
        isOtherPayMode: false, // 支付模式
        avatar: "",
        userVip:  getStore({ name: 'userVip' }) || -1 ,
        loginType: getStore({name: 'loginType'}) || 1
    },
    mutations: {
        setUserId(state, value) {
            state.userId = value
            setStore({ name: 'userId', content: state.userId })
        },
        setToken(state, value) {
            state.token = value
            setStore({ name: 'token', content: state.token })
        },
        setUserName(state, value) {
            state.username = value
            setStore({ name: 'username', content: state.username })
        },
        setPassword(state, value) {
            state.password = value
            setStore({ name: 'password', content: state.password })
        },
        setLoginState(state, value) {
            state.isLogin = value
            setStore({ name: 'isLogin', content: state.isLogin })
        },
        setNeedLogin(state, value) {
            state.needLogin = value;
        },
        setLastUserName(state, value) {
            state.lastUsername = value
            setStore({ name: 'lastUsername', content: state.lastUsername })
        },
        setLastPassword(state, value) {
            state.lastPassword = value
            setStore({ name: 'lastPassword', content: state.lastPassword })
        },
        setPoints(state, value) {
            state.points = value || 0
        },
        setPayPasswordFlag(state, value) {
            state.payPasswordFlag = value || false
        },
        setCurrency(state, value) {
            state.currency = value || getStore({ name: 'currency' }) || "CNY"
        },
        setEmail(state, value) {
            state.email = value,
            setStore({
                name: 'email' , content: state.email
            })
        },
        setMobile(state,value){
            state.mobile = value;
            setStore({
                name: 'mobile' , content: state.mobile
            })
        },
        setLoginType(state,value){
            state.loginType = value;
            setStore({
                name: 'loginType' , content: state.loginType
            })
        }
    },
    actions: {
        initUser (state, info) {
            state.commit('setUserId', info.userId)
            state.commit('setToken', decodeURI(info.token))
            console.log(decodeURI(info.token), info.token)
            state.commit('setUserName', info.userName)
            state.commit('setPassword', info.password)
            state.commit('setCurrency', info.currency)
        },
        login(state, info) {
            console.log(info)
            state.commit('setUserId', info.uid)
            state.commit('setToken', info.token)
            state.commit('setUserName', info.username)
    
            state.commit('setPassword', info.password)
            state.commit('setLastUserName', info.username)

            state.commit('setLoginState', true)
            if(info.lastPassword){
                state.commit('setLastPassword', info.lastPassword)
            }
            //缓存邮箱跟手机号，登录方式
            if(info.email){
                state.commit('setEmail',info.email)
            }
            if(info.mobile){
                state.commit('setMobile',info.mobile)
            }
            if(info.loginType){
                state.commit('setLoginType',info.loginType)
            }
        },
        logout(state) {
            state.commit('setUserId', -1)
            state.commit('setToken', "")
            state.commit('setUserName', "")
            state.commit('setPassword', "")
            state.commit('setLoginState', false)
        },
        updatePoints(state, info) {
            state.commit('setPoints', info.points)
            state.commit('setPayPasswordFlag', info.payPasswordFlag)
        },
        updateInfo(state, info) {
            state.commit('setEmail', info.email)
        }
    }
}

export default user;