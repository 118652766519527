import axios from 'axios'
import { Toast  } from 'vant'
import store from "@/store";
import { LANG } from "@/configs/global";
const http = axios.create( {
   // baseURL:  "https://www.handzone.xyz/api",
     baseURL: 'https://www.handzonegame.com/api',
   // baseURL: 'http://192.168.0.43/api',
   //   baseURL: 'http://192.168.0.42:8081/api',
     timeout: 30000,
     headers: {
      'Content-Type': 'application/json;charset=utf-8',
     }
})

http.interceptors.request.use(function(config){
    const token = store.getters.token || '';
    token && (config.headers.Authorization = token);
    const locale = store.getters.lang || LANG.ZH_CN; 
    var u1 = config.url.indexOf("?");
    var str = u1 > 0 ? "&locale=" + locale : "?locale=" + locale;
    config.url = config.url + str;
    return config
})

http.interceptors.response.use(function (response) {

  if (response.data.code != 0) {
      if (response.data.msg) {
          if (response.config.url.indexOf("device") < 0) {
            Toast.fail({
                message: response.data.msg,
                className: 'noticeWidth'
            })
          }
      }
      return Promise.reject(response.data)
  }

  return response.data;
}, function (error) {
    // 对响应错误做点什么
    console.log(error.response.status)
    if (error.response.status && error.response.status === 401) {
              console.log("未登录")
              //清空掉缓存
              store.dispatch('logout')
              store.commit("setNeedLogin", true)
        return;
    }
    return;
});

export default http