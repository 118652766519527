module.exports = {
    LOCAL:{
        zh_CN:"简体中文",
        zh_TW:"繁體中文",
        en_US:"English",
        ko_KR:"한국어",
        ru_RU:"Русский",
        de_DE:"Deutsch",
        ja_JP:"日本",
        pt_BR:"Português",
        tr_TR:"Türkiye",
        vi_VN:"Tiếng Việt",
        es_LA:"Español Americano",
        ar_SA:"عربي",
    },
    SERVER:{
        server_wz_limit_count:"Limit erreicht",
        server_wz_task_cd:"Im Cooldown",
        server_wz_lack_money:"Mangelhaftes Gleichgewicht",
        server_result_success:"Erfolgreich",
        server_result_fail:"Gescheitert",
    },
    LAN:{
        change_pwd:"Passwort ändern",
        change_pwd_tips:"Sie ändern gerade Ihr Kontopasswort, bitte bewahren Sie Ihr neues Passwort sicher auf",
        unbound:"(Nicht verknüpft)",
        default_language:"Standardsprache",
        user_service:"Nutzungsbedingungen",
        bind_email:"Mit Mailbox verknüpfen",
        loading:"Wird geladen…",
        helper:"Hilfe",
        gift:"Paket",
        mine:"Ich",
        confirm:"Bestätigen",
        cancel:"Abbrechen",
        bind_email_tips:"Sie sind mit einer Mailbox verknüpft. So bleibt Ihr Konto sicher und Sie können Ihr Passwort jederzeit abrufen.",
        input_old_email:"Bitte geben Sie die alte Mailbox ein",
        input_old_verify_code:"Bitte geben Sie den Verifizierungscode der ehemaligen Mailbox ein",
        input_new_verify_code:"Bitte geben Sie den Verifizierungscode der neuen Mailbox ein",
        input_validator_email:"Bitte geben Sie die korrekte Mailbox ein",
        input_bind_email:"Bitte geben Sie die Mailbox ein, mit der Sie sich verknüpfen möchten",
        verify_code:"Verifizierungscode",
        next:"Weiter",
        bind_now:"Jetzt verknüpfen",
        tips:"*Erinnerung",
        email_tips:"Die E-Mail wird von einigen kostenlosen E-Mail-Servern möglicherweise fälschlicherweise als Junk-Mail eingestuft. Bitte gehen Sie auf Ihre [Junk-Mails], um den Verifizierungscode anzuzeigen.",
        send_verify_code:"Code senden",
        sending:"Wird gesendet…",
        send_success:"Erfolgreich gesendet",
        resend:"Erneut senden",
        old_pwd:"Altes Passwort",
        input_old_pwd:"Bitte geben Sie das alte Passwort ein",
        new_pwd:"Neues Passwort",
        input_new_pwd:"Bitte geben Sie das neue Passwort ein. Es muss zwischen 6 und 15 Zeichen lang sein.",
        confirm_pwd:"Passwort bestätigen",
        input_confirm_pwd:"Bitte geben Sie das neue Passwort erneut ein",
        change_now:"Jetzt ändern",
        switch_account:"Konto wechseln",
        playing:"Sie spielen gerade",
        search_placeholder:"Bitte geben Sie das Stichwort ein",
        search:"Suche",
        empty:"Keine Daten",
        self_service:"Selbsthilfe-Service",
        submit_feedback:"Feedback senden",
        online_service:"Online-CS",
        official_verification:"Offizielle Verifizierung",
        myservice:"Mein Feedback",
        questionTabs_1:"Aktuelle Probleme",
        questionTabs_2:"Aufladungsbezogen",
        questionTabs_3:"Spielbezogen",
        questionTabs_4:"Anmeldungsbezogen",
        questionTabs_999:"Mehr",
        question_detail:"Details",
        question_common:"FAQ",
        search_empty:"Keine Ergebnisse",
        feedback_detail:"Feedback-Details",
        contact_email:"Kontakt-Mailbox",
        screenshot:"Screenshot",
        detailed_info:"Details",
        my_reply:"Meine Antwort",
        kf_reply:"Antwort von CS /0/",
        input_feedback:"Bitte beschreiben Sie das Problem im Detail",
        service_score:"Bitte bewerten Sie diesen Dienst",
        score:"/0/",
        scored:"Bewertet, danke für Ihr Feedback",
        thank_feedback:"Bewertet, danke für Ihr Feedback",
        score_tips:"Bitte wählen Sie eine Bewertung",
        feedback_processing:"Ihr Feedback wird bearbeitet, bitte warten Sie…",
        feedback_tips:"Wenn das Problem ungelöst bleibt, reichen Sie es bitte nicht erneut ein",
        submit:"Absenden",
        processing:"Wird verarbeitet",
        replied:"Beantwortet",
        completed:"Abgeschlossen",
        uploading:"Wird hochgeladen…",
        upload_failed:"Hochladen fehlgeschlagen",
        upload_tips:"Bitte warten Sie, bis das Bild hochgeladen ist",
        robot_name:"Bot",
        robot_name2:"Ihr Bot ist bereit",
        welcom_msg:"Vielen Dank für Ihren Besuch. Sie können eine Anfrage stellen, indem Sie das Stichwort eingeben. Der Bot hilft Ihnen dann weiter.",
        change_batch:"Neuer Stapel",
        forget_pwd:"Passwort vergessen",
        game_web:"Offizielle Website",
        contact_customer:"Realer Mitarbeiter",
        ques_placeholder:"Bitte geben Sie das Problem ein",
        send:"Senden",
        connection:"Mit Chat verbinden…",
        enter_question:"Bitte geben Sie zuerst das Problem ein",
        are_you_asking:"Fragen Sie nach",
        robot_not_found:"Entschuldigung, ich kann nicht ganz folgen. Um das Problem besser angehen zu können, schlage ich vor, dass Sie Ihre Anfrage anders formulieren. Wenn Sie einen echten Kundendienst benötigen, tippen Sie bitte auf ‚Realer Mitarbeiter‘.",
        customer_service:"Realer Kundenservice",
        feedback_0:"Aufladung",
        feedback_1:"Bugs",
        feedback_2:"Melden",
        feedback_3:"Vorschläge",
        feedback_4:"Konto",
        feedback_5:"Bildschirmfoto",
        feedback_server:"Server",
        feedback_rolename:"Charaktername",
        feedback_price:"Auftragsbetrag",
        feedback_order:"Auftragsnummer",
        feedback_report:"Melden",
        feedback_username:"Konto",
        feedback_registerTime:"Registrierungsdatum:",
        feedback_registerDevice:"Gerät:",
        feedback_lastLoginTime:"Letzte Anmeldung",
        feedback_lastPayTime:"Aufladezeit im letzten Monat",
        feedback_lastPayPrice:"Aufladebetrag im letzten Monat",
        feedback_lastPayOrder:"Auftragsnummer der Aufladung im letzten Monat",
        feedback_server_tips:"Bitte geben Sie den Servernamen ein",
        feedback_rolename_tips:"Bitte geben Sie den Charakternamen ein",
        feedback_price_tips:"Bitte geben Sie den Auftragsbetrag ein",
        feedback_order_tips:"Bitte geben Sie die Auftragsnummer ein",
        feedback_report_tips:"Bitte geben Sie den Charakter ein, den Sie melden möchten",
        feedback_username_tips:"Bitte geben Sie das Konto ein, das Sie abrufen möchten",
        feedback_registerTime_tips:"Bitte geben Sie die Daten ein, mit denen das Konto registriert wurde",
        feedback_registerDevice_tips:"Bitte geben Sie das Modell des registrierten Gerätes ein",
        feedback_lastLoginTime_tips:"Bitte geben Sie die letzte Anmeldezeit ein",
        feedback_lastPayTime_tips:"Bitte geben Sie die Daten Ihrer Aufladung im letzten Monat ein",
        feedback_lastPayPrice_tips:"Bitte geben Sie den Betrag Ihrer Aufladung im letzten Monat ein",
        feedback_lastPayOrder_tips:"Bitte geben Sie die Auftragsnummer Ihrer Aufladung des letzten Monats ein",
        order_helper:"Wie kann ich die Auftragsnummer abfragen?",
        max_img:"Maximal 3 Bilder",
        contact:"Kontaktinformationen",
        detailed_desc:"Details",
        myservice_count:"Ihr Feedback",
        myservice_count2:"wird gerade bearbeitet ",
        nomore:"Nichts weiter",
        feedback_state:"Status",
        feedback_type:"Typ",
        create_time:"Erstellungszeit",
        gift_title:"Spielpakete",
        mygift_title:"Meine Pakete",
        recommend:"Empfohlenes Spiel",
        popularity:"Beliebt",
        play:"Neu",
        hot:"Beliebt",
        gift_empty:"Keine Pakete, bitte bleiben Sie dran",
        input_contact_email:"Bitte geben Sie die Kontakt-Mailbox ein",
        same_password:"Passwörter stimmen überein. Keine Änderung erforderlich",
        success:"Aktion erfolgreich",
        input_email:"Bitte geben Sie die Mailbox ein",
        email:"Mailbox",
        forgot:"Passwort wiederherstellen",
        forgot_from_email:"Per E-Mail",
        forgot_from_service:"Über Kundenservice",
        reset_password:"Bestätigen und Zurücksetzen",
        unbind_email:"Verknüpfung mit einer Mailbox erforderlich, um die Verknüpfung aufzuheben",
        unbind_fb:"Wenn Sie die Verknüpfung aufheben, können Sie sich nicht mehr mit Ihrem Facebook-Konto bei diesem Konto anmelden. Verknüpfung dennoch aufheben?",
        unbind_google:"Wenn Sie die Verknüpfung aufheben, können Sie sich nicht mehr mit Ihrem Google-Konto bei diesem Konto anmelden. Verknüpfung dennoch aufheben?",
        unbind_twitter:"Wenn Sie die Verknüpfung aufheben, können Sie sich nicht mehr mit Ihrem Twitter-Konto bei diesem Konto anmelden. Verknüpfung dennoch aufheben?",
        tips_title:"Aufforderung",
        copy_success:"Erfolgreich kopiert",
        copy_faild:"Kopieren fehlgeschlagen. Der Browser unterstützt das Kopieren nicht",
        gift_rate_task:"Bitte füllen Sie Ihren Kommentar aus, bevor Sie dieses Paket anfordern",
        requesting:"Anfrage an",
        get_gift:"Paket beanspruchen",
        gift_rate:"Jetzt kommentieren",
        gift_rate2:"Jetzt bewerten",
        gift_share:"Jetzt teilen",
        gift_bind:"Jetzt verknüpfen",
        gift_fill:"Jetzt ausfüllen",
        gift_ads:"Watch Now",
        gift_copy:"Geschenkcode kopieren",
        gift_exchange:"Erlösung",
        gift_exchange_desc:"Vorteile – Aktivierungscode – Geben Sie den Geschenkcode zur Aktivierung ein",
        password_help:"Wie kann ich mein Kontopasswort einsehen?",
        password_help_1:"1. Tippen Sie auf den Avatar in der oberen linken Ecke",
        password_help_2:"2. Tippen Sie unten auf die rote Schaltfläche „Benutzer-Center“.",
        password_help_3:"3. Tippen Sie auf „Konto wechseln“.",
        password_help_4:"4. Tippen Sie auf das Augensymbol, um das Passwort des aktuellen Kontos anzuzeigen.",
        payhelp_title:"Bitte wählen Sie eine Zahlungsplattform",
        payhelp_google:"Abfrage über Google Play",
        payhelp_google1:"1. Öffnen Sie Google Play und klicken Sie auf die linke Menüleiste",
        payhelp_google2:"2. Klicken Sie auf „Mein Konto“",
        payhelp_google3:"3. Klicken Sie auf den Auftragssatz",
        payhelp_google4:"4. Klicken Sie auf den entsprechenden Auftrag, um die Quittung anzuzeigen",
        payhelp_wechat:"Abfrage über Wechat",
        payhelp_wechat1:"1. Klicken Sie, um Wechat zu öffnen",
        payhelp_wechat2:"2. Klicken Sie auf die Option [Me].",
        payhelp_wechat3:"3. Klicken Sie zum Bezahlen auf „Wallet“",
        payhelp_wechat4:"4. Klicken Sie auf „Bill“",
        payhelp_wechat5:"5. Klicken Sie auf den entsprechenden Auftrag, um den Auftragseingang zu sehen",
        payhelp_paypal:"Anfrage über PayPal",
        payhelp_paypal1:"1. Melden Sie sich bei Ihrem PayPal-Konto an.",
        payhelp_paypal2:"2. Klicken Sie auf den Transaktionsdatensatz",
        payhelp_paypal3:"3. Klicken Sie, um die Details anzuzeigen",
        payhelp_paypal4:"4. Klicken Sie auf den entsprechenden Auftrag, um die Details anzuzeigen",
        payhelp_alipay:"Abfrage über Alipay",
        payhelp_alipay1:"1. Klicken Sie, um Alipay zu öffnen",
        payhelp_alipay2:"2. Klicken Sie auf die Option [My]",
        payhelp_alipay3:"3. Klicken Sie auf „Bill“",
        payhelp_alipay4:"4. Klicken Sie auf den entsprechenden Auftrag, um die Quittung anzuzeigen",
        bind_mobile:"綁定手機",
        bind_mobile_tips:"您正在為帳號綁定手機號，綁定手機號能有效保障帳號安全，可通過手機號找回密碼。",
        input_old_mobile:"請輸入舊手機號",
        input_validator_mobile:"請正確輸入手機號",
        input_bind_mobile:"請輸入您要綁定的手機號",
        mobile_tips:"部分手機號，資訊有可能被誤判為垃圾資訊，請先至【垃圾信箱】查看獲取驗證碼。",
        contact_mobile:"聯繫手機號",
        input_contact_mobile:"請輸入聯繫手機號",
        input_mobile:"請輸入手機號",
        mobile:"手機號",
        unbind_mobile:"請先綁定郵箱後再進行解綁",
        input_old_verify_mobile:"請輸入舊手機號驗證碼",
        input_new_verify_mobile:"請輸入手機號驗證碼",
        pay_title:"Gesamt etwa",
        pay_change_currency:"Währung",
        pay_tips:"(Der endgültige Zahlungsbetrag variiert je nach Zahlungsmöglichkeiten und Wechselkurs)",
        pay_select_mode:"Zahlungsmethode auswählen",
        wechat:"WeChat Pay",
        alipay:"Alipay",
        pay_paying:"Zahlung wird übermittelt…",
        pay_fail:"Aufladung fehlgeschlagen. Bitte versuchen Sie es später erneut.",
        pay_return_tips:"Bitte schließen Sie diese Seite, wenn die Zahlung abgeschlossen ist, andernfalls wird eine weitere Zahlung eingeleitet.",
        pay_discount:"(Rabatte /0/%)",
        pay_late_mode:"Zuletzt gewählte Zahlungsmethode",
        pay_other_mode:"Sonstige Zahlung",
        pay_more:"Weitere Methoden ",
        pay_point:"/0/ Punkte, um /1/ Punkte gratis zu erhalten",
        points:"Aufladung für Punkte",
        current_points:"Aktuelle Punkte:",
        pay:"Aufladung",
        pwd:"Passwort",
        pay_setting:"Zahlungspasswort festlegen",
        select_amount:"Bitte wählen Sie die aufzuladenden Punkte aus",
        forget:"Zahlungspasswort abrufen",
        forget_pay:"Zahlungspasswort vergessen",
        input_pwd:"Bitte geben Sie ein 6-stelliges Zahlungspasswort ein",
        different:"Passwörter stimmen nicht überein",
        setting_tips:"Sie legen gerade ein Zahlungspasswort fest. Bitte bewahren Sie Ihr Zahlungspasswort sicher auf",
        remain:"Behalten",
        add:"Aufladung für Punkte",
        pay_mode:"Zahlungsmethode",
        change_payPwd_tips:"Sie ändern Ihr Zahlungspasswort. Bitte bewahren Sie Ihr neues Zahlungspasswort sicher auf",
        unsetting:"Kein Zahlungspasswort",
        email_unbound:"Keine verknüpfte E-Mail",
        confirm_pay_pwd:"Zahlungspasswort bestätigen",
        input_confirm_pay_pwd:"Bitte geben Sie das Zahlungspasswort erneut ein",
        payPassword_error:"Falsches Zahlungspasswort",
        balance:"Unzureichendes Guthaben",
        gamePoints:"Punkte",
        pay_complete:"Zahlung abgeschlossen",
        pay_new_tips:"Schließen Sie dieses Fenster nicht, bis das Aufladen abgeschlossen ist",
        pay_new_message:"Bitte schließen Sie die Aufladung auf der neuen Seite ab",
        pay_cancel:"Zahlung stornieren",
        back:"Zurück",
        fee_name:"Partner-Marketingprogramm",
        fee_text1:"Arbeiten Sie mit HandZone Games zusammen",
        fee_text2:"Als Partner von HandZone Games erhalten Sie einen individuellen Partnerlink. Sie erhalten eine bestimmte Provision auf den Betrag, der von Nutzern aufgeladen wird, die das Spiel über Ihren Link herunterladen und installieren.",
        fee_text3:"Sie müssen die folgenden Voraussetzungen erfüllen, bevor Sie am Partner-Marketingprogramm von HandZone Games teilnehmen können.",
        fee_price:"Entsprechend dem Aufladebetrag:",
        fee_text4:"Das Partner-Marketingprogramm ist für Sie verfügbar, wenn Sie die Voraussetzungen erfüllen. Sie erhalten 10% bis 70% (variiert je nach Partnerstufe) des Betrags, der von neuen Spielern, die das Spiel über Ihr Angebot installieren, aufgeladen wird.",
        fee_text5:"Herzlichen Glückwunsch! Sie haben sich für das Partner-Marketingprogramm angemeldet",
        fee_text6:"Ihr Partnerlink:",
        fee_copy:"Link kopieren",
        fee_text7:"Die aktuellen Provisionen der Partnerstufen:",
        fee_text8:"A.B.C Aufladung bzw. /0/",
        fee_text9:"Sie erhalten:",
        fee_text10:"A hat keine Begrenzung",
        fee_text11:"Derzeit haben Sie /0/ Lv.1 Partner, /1/ Lv.2 Partner, /2/ Lv.3 Partner",
        fee_price2:"Verfügbarer Betrag zur Abhebung:",
        fee_start:"Angebot beginnen",
        fee_submit:"Rücktritt",
        fee_tips1:"Rücktrittsbedingungen nicht erfüllt",
        contact_kf:"Kundendienst kontaktieren",
        fee_tips2:"Kundendienst für Abhebungen kontaktieren",
        account_destroy:"账号注销",
        account_destroy_now:"申请注销账号",
        account_destroy_cancel:"取消注销账号",
        account_destroy_time:"注销账号剩余时间：",
        account_destroy_time2:"/0/ 天后",
        account_destroy_cancel_tips:"您已取消注销账号申请",
        account_destroy_read_tips:"我已经看过并同意",
        account_destroy_read_tips2:"《账号注销须知》",
        account_destroy_content1:"● 1. 注销成功后该账号将<a>无法在我司的账号平台及旗下任一款产品上进行登录</a>且<a>账号以及数据不可恢复</a>；",
        account_destroy_content2:"● 2. 账号注销需要<a>收集您的个人敏感信息（包括邮箱验证码）</a>用于核验身份，账号注销后，我司将会<a>删除上述信息或匿名化处理</a>；",
        account_destroy_content3:"● 3. 账号成功注销后，该账号下的所有内容、信息、记录将会被<a>删除或匿名化处理</a>，您也无法再检索、访问、获取、继续使用和找回，也无权要求我司找回（但法律法规另有规定或监管部门另有要求的除外）；",
        account_destroy_content4:"● 4. 账号注销之后，对应的账号交易平台将无法登录使用，<a>您申请账号注销的行为即表明您自愿放弃该账号下的利益</a>，<a>在注销前，请再三确认平台上的交易和提现申请已完成</a>，避免账号注销后因无法登录而造成不必要损失；",
        account_destroy_content5:"● 5. 账号注销后，<a>我司有权不再为您提供任何该账户有关的服务</a>，您与我司曾签署过的相关用户协议、其他权利义务性文件等相应终止（但我司与您之间已约定继续生效的或法律法规另有规定的除外）；",
        account_destroy_content6:"● 6. 因注销账户所带来的不便或不利后果，由您自行承担，与我司无关；",
        account_destroy_content7:"● 7. 如果账号注销期间存在权属纠纷，我司有权终止注销申请；",
        account_destroy_content8:"● 8. 账号注销有7天的确认期，期间您可以再次来此界面取消注销，确认期过后将无法再取消注销；",
        unbind_account_destroy:"请先绑定邮箱后再进行注销账号",
        pay_go:"Zum Aufladen gehen",
        either_or:" or ",
        my_info_avatar:"Benutzerbild",
        avatar:"Benutzerbild   ",
        look_avatar:"Avatar ansehen",
        change_avatar:"Avatar ändern",
        avatar_reviewing:"unter Überprüfung",
        avatar_review_fail:"Prüfungsfehler",
        zbm0006:"Lieber Spieler, der von dir hochgeladene Avatar beinhaltet {0} und ist daher nicht genehmigt",
        shhz0001:"Politisch heikle Inhalte",
         shhz0002:"Pornografische Inhalte",
          shhz0003:"Glücksspiel-Inhalte",
        shhz0004:"Drogenbezogene Inhalte",
        shhz0005:"Religiös heikle Inhalte",
        shhz0006:"Enthält andere Websites oder Website-Adressen",
        shhz0007:"Enthält Werbung",
        shhz0008:"Unanständige oder vulgäre Inhalte",
        account:"Nutzername",
        vip_kf:"VIP Service",
        super_vip:"Super-VIP",
        super_vip_kf:"Super-VIP Karte Exklusiver Service",
        super_vip_be:"Werden Sie ein Super-VIP<br/>Genießen Sie exklusiven Service",
        super_vip_info1:"Super-VIP Karte Exklusiver Service",
        super_vip_info2:"(nur für erwachsene Spieler)",
        super_vip_info3:"1. Exklusiver Kanal<br/>Exklusiver Eins-zu-Eins-Service. Ihre Vorschläge werden direkt an die Entwickler weitergeleitet!<br/>2. Exklusive Priorität<br/>Vorrangiger Zugriff auf die besten Spielinformationen.<br/>3. Exklusives Paket<br/>Werden Sie unser Super-VIP und sichern Sie sich das Super-Grand-Paket!<br/>4. Exklusives Event<br/>Exklusive, unregelmäßige Events und Vorteile!",
        super_vip_gain1:"Voraussetzungen für ein Upgrade zum Super-VIP",
        super_vip_gain2:"1. Methode:  Der Gesamtaufladebetrag erreicht/0/",
        super_vip_get:"None",
        super_vip_gift:"Exklusives Super-VIP-Paket",
        super_vip_gift_tips:"Beanspruchen Sie das Geschenkpaket nach erfolgreicher Überprüfung. Kontaktieren Sie uns für weitere Informationen!",
        super_vip_gift_1:" Titel im Spiel",
        super_vip_gift_2:"Seltene Dekoration",
        super_vip_gift_3:"Begleittier",
        super_vip_gift_4:"Individuelles Paket",
        super_vip_gift_5:"Seltener Gegenstand",
        super_vip_gift_wechat:"WeChat:",
        feedback_reward_1:"Belohnungen-Kundenservice",
        feedback_reward_2:"Belohnung",
        feedback_reward_3:"Die Belohnung ist Ihr freiwilliges Geschenk an unseren Kundendienst und wird in voller Höhe an den zuständigen Kundendienstmitarbeiter ausgezahlt",
        wechat_native_1:"Zahlungsbetrag:",
        wechat_native_2:"Bitte öffnen Sie WeChat und scannen Sie den QR-Code, um die Zahlung abzuschließen.",
        wechat_native_3:"Zahlung abgeschlossen",
        login_usern:"Anmelden",
        register_username:"Registrieren",
        quick_register:"Schnelle Registrierung",
        enterNow:"Jetzt anmelden",
        btn_register:"Jetzt registrieren",
        agreed1:"Zustimmen",
        btn_have_usern:"Einen Account besitzen",
        to_login:"Zur Anmeldung gehen",
        login_success:"Anmeldung erfolgreich",
        agree_protocol:"Bitte den Nutzungsbedingungen zustimmen",
        UserLogin:"Anmelden",
        UserLogin_text1:"Benutzername eingeben",
        UserLogin_text2:"Passwort eingeben",
        UserLogin_text4:"Wird angemeldet",
        UserLogin_text5:"Wird registriert",
        UserLogin_text6:"Registrierung erfolgreich",
        online_earning:"Online-Erträge",
        online_earning_center:"Erträge-Center",
        online_earning_sigtiaoin:"Anmeldungs-Herausforderung",
        online_earning_text1:"Mission<br>Geld verdienen",
        online_earning_text2:"Sehen Sie sich das Video einmal an, um sich anzumelden. Melden Sie sich insgesamt 7 Tage lang an, um Prämien zu erhalten",
        online_earning_text3:"Einlösbare Prämien",
        online_earning_text4:"Anschauen und Belohnungen erhalten",
        online_earning_text5:"Verbleibend: /0/",
        online_earning_text6:"Unzureichende Versuche",
        online_earning_text7:"Nicht beansprucht",
        exchange:"Einlösen: /0/",
        exchange_center:"Erlösungs-Center",
        exchange_prompt:"Erlösungs-Tipps",
        exchange_confirm:"Bestätigen",
        exchange_consume:"Kosten: /0/",
        exchange_balance:"Bilanz",
        exchange_detail:"Details",
        exchange_balance_detail:"Bilanz-Details",
        exchange_reward:"Belohnung einlösen",
        exchange_text1:"/0/ Punkt(e)",
        exchange_text2:"$/0/",
        exchange_text3:"Punkte",
        exchange_text4:"Geld-Paket",
        exchange_text5:"Erlösungs-Limit: /0///1/",
        exchange_text6:"Tägliches Limit：/0///1/",
        exchange_text7:"Einladungscode eingeben zum Verbinden",
        exchange_text8:"Geben Sie Ihren PayPal-Account an",
        exchange_text9:"Einladung-Verbindung",
        exchange_text10:"Bestätigen",
        exchange_text11:"Eingelöst. Sie können die Ergebnisse im Benutzer-Center einsehen",
        exchange_text12:"Unzureichende Menge",
        exchange_text13:"Nur die letzten 100 Einträge anzeigen",
        exchange_rule:"Regeln",
        exchange_rule2:"Erlösungsregeln",
        exchange_rule2_html:"1. Eine E-Mail-Anmeldung ist erforderlich, um den Account wiederherzustellen;<br/>2. Bitte stellen Sie sicher, dass die Angaben für die Erlösung korrekt sind. Der Verlust der Verbindung oder die Erlösung durch fehlerhafte Angaben liegen in Ihrer Verantwortung;<br />3. Falls die Erlösung scheitert, senden Sie bitte die Fehlermeldung mit. Wenn sich das Problem nicht lösen lässt, kontaktieren Sie den Kundensupport;<br />4. Mit dem Anstoßen der Erlösung stimmen Sie diesen Regeln zu. Die finale Auslegung der Erlösungsregeln liegt bei Handzone.",
        invite_Friends:"Freunde einladen",
        invite_binding:"Einladung-Verbindung",
        new_envelope:"Starter-Paket",
        signReward:"Anmeldung abschließen",
        completeTask:"/0/ Mission(en) abgeschlossen",
        completeExchange:"Einlösen: /0/, Kosten",
        language:"Sprache",
        country:"Land / Region",
        select_pay_mode:"Wählen Sie die richtige lokale Zahlung",
        exchange_text14:"Noch nicht geöffnet",
        hint_func: "Die Funktion ist noch nicht verfügbar, bitte bleiben Sie dran",
        game_hub:"Spielzentrum",
        not_login:"Nicht eingeloggt",
        unlock:"Melden Sie sich an, um weitere spannende Inhalte freizuschalten",
        for_the_account:"Sie erstellen ein Konto",
        for_the_account_1:"Durch das Binden Ihrer E-Mail können Sie die Sicherheit Ihres Kontos effektiv gewährleisten.",
        account_length:"Die Länge des Kontos beträgt 6–18 Zeichen",
        modify_successfully:"Erfolgreich geändert",
        binding_successful:"Bindung erfolgreich",
        start_game:"Start",
        game_down:"herunterladen",
        new_travel:"neue Tour",
        please_acc_code:"Bitte geben Sie den Benutzernamen ein",
        please_paw_code:"Bitte Passwort eingeben",
        agree:"zustimmen",
        install:"aufstellen",
        logout:"Abmelden",
        is_logout_account:"Ob vom Anmeldekonto abgemeldet werden soll",
        top_free:"Beliebt kostenlos",
        top_jackpot:"Beliebter Bestseller",
        peace:"Und",
        priv_policy:"Datenschutzrichtlinie",
        tally:"Punkte",
        give_away:"Inklusive Geschenke",
        voucher:"Gutscheine",
        waiting_used:"Benutzt werden"
    }
}
