module.exports = {
    LOCAL:{
        zh_CN:"简体中文",
        zh_TW:"繁体中文",
        en_US:"English",
        ko_KR:"한국어",
        ru_RU:"Русский",
        de_DE:"Deutsch",
        ja_JP:"日本",
        pt_BR:"Português",
        tr_TR:"Türkçe",
        vi_VN:"Tiếng Việt",
        es_LA:"Español Americano",
        ar_SA:"عربي",
    },
    SERVER:{
        server_wz_limit_count:"Limite máx. atingido",
        server_wz_task_cd:"Em Recarga",
        server_wz_lack_money:"Saldo insuficiente",
        server_result_success:"Bem-sucedido",
        server_result_fail:"Falhou",
    },
    LAN:{
        change_pwd:"Trocar Senha",
        change_pwd_tips:"Você está trocando a senha da conta, mantenha a nova senha segura",
        unbound:"(Sem Vínculo)",
        default_language:"Idioma Padrão",
        user_service:"Termos de Serviço",
        bind_email:"Vincular com E-mail",
        loading:"Carregando...",
        helper:"Ajuda",
        gift:"Pct.",
        mine:"Meu",
        confirm:"Confirmar",
        cancel:"Cancelar",
        bind_email_tips:"Você está vinculando sua conta com um e-mail. Ao fazer isto, sua conta estará mais protegida e você poderá recuperar a senha com seu e-mail vinculado.",
        input_old_email:"Insira o e-mail antigo",
        input_old_verify_code:"Insira o código de verif. do e-mail antigo",
        input_new_verify_code:"Insira o código de verif. do e-mail",
        input_validator_email:"Insira o endereço de e-mail correto",
        input_bind_email:"Insira o e-mail a ser vinculado",
        verify_code:"Código de Verif.",
        next:"Próx.",
        bind_now:"Vinc. Agora",
        tips:"*Lembrar",
        email_tips:"Nossa mensagem pode ser mal-interpretada como sendo spam. Verifique sua pasta de spam p/ ver o código de verificação.",
        send_verify_code:"Enviar Código de Verif.",
        sending:"Enviando...",
        send_success:"Foi enviado",
        resend:"Reenviar",
        old_pwd:"Senha Antiga",
        input_old_pwd:"Insira a senha antiga",
        new_pwd:"Nova Senha",
        input_new_pwd:"Insira a nova senha (entre 6 e 15 caracteres)",
        confirm_pwd:"Confirme a Senha",
        input_confirm_pwd:"Insira de novo a nova senha",
        change_now:"Mudar Agora",
        switch_account:"Mudar de Conta",
        playing:"Você está jogando",
        search_placeholder:"Insira a palavra-chave",
        search:"Procurar",
        empty:"Sem Dados",
        self_service:"Autosserviço",
        submit_feedback:"Enviar Feedback",
        online_service:"Atend. Online",
        official_verification:"Verif. Oficial",
        myservice:"Meu Feedback",
        questionTabs_1:"Problemas Atuais",
        questionTabs_2:"Recarga",
        questionTabs_3:"Jogo",
        questionTabs_4:"Login",
        questionTabs_999:"Mais",
        question_detail:"Detalhes",
        question_common:"FAQ",
        search_empty:"Sem resultados",
        feedback_detail:"Detalhes do Feedback",
        contact_email:"E-mail de Contato",
        screenshot:"Captura de Tela",
        detailed_info:"Detalhes",
        my_reply:"Minha Resposta",
        kf_reply:"Resposta do Atend. /0/",
        input_feedback:"Descreva o problema com detalhes",
        service_score:"Avalie esse serviço",
        score:"/0/",
        scored:"Avaliado, obrigado pelo feedback",
        thank_feedback:"Avaliado, obrigado pelo feedback",
        score_tips:"Escolha uma avaliação",
        feedback_processing:"Processando o feedback...",
        feedback_tips:"Se o problema continuar, não o envie várias vezes. Você pode responder aqui de novo.",
        submit:"Submeter",
        processing:"Processando",
        replied:"Respondido",
        completed:"Completado",
        uploading:"Enviando...",
        upload_failed:"Envio falhou",
        upload_tips:"Espere a imagem ser enviada",
        robot_name:"Bot",
        robot_name2:"Bot à sua disposição",
        welcom_msg:"Obrigado pela visita. Você pode perguntar ao inserir a palavra-chave e o Bot te ajudará.",
        change_batch:"Mais Opções",
        forget_pwd:"Esqueceu a Senha",
        game_web:"Site Oficial",
        contact_customer:"Acessoria",
        ques_placeholder:"Descreva o problema",
        send:"Enviar",
        connection:"Conectando...",
        enter_question:"Primeiro insira o problema",
        are_you_asking:"Você está tentando perguntar:",
        robot_not_found:"Desculpe, mas não entendi direito. Para resolver melhor o seu problema, reescreva de outra forma. Se precisar de ajuda auxiliar, toque no botão Acessoria.",
        customer_service:"Acessoria",
        feedback_0:"Recarga não completada",
        feedback_1:"Bugs",
        feedback_2:"Reportar",
        feedback_3:"Sugestões",
        feedback_4:"Recuperar Conta",
        feedback_5:"captura de tela",
        feedback_server:"Servidor",
        feedback_rolename:"Nome do Personagem",
        feedback_price:"Quant. de Pedido",
        feedback_order:"Nº de Pedido",
        feedback_report:"Reportar",
        feedback_username:"Conta",
        feedback_registerTime:"Horário de Registro",
        feedback_registerDevice:"Aparelho",
        feedback_lastLoginTime:"Último Login",
        feedback_lastPayTime:"Horário da Recarga Mês Passado",
        feedback_lastPayPrice:"Valor da Recarga Mês Passado",
        feedback_lastPayOrder:"Nº de Pedido de Recarga Mês Passado",
        feedback_server_tips:"Insira o nome do servidor",
        feedback_rolename_tips:"Insira o nome do personagem",
        feedback_price_tips:"Insira a quantidade do pedido",
        feedback_order_tips:"Insira o número do pedido",
        feedback_report_tips:"Insira o personagem a ser reportado",
        feedback_username_tips:"Insira a conta a ser recuperada",
        feedback_registerTime_tips:"Insira o horário de registro da conta",
        feedback_registerDevice_tips:"Insira o aparelho usado para registrar a conta",
        feedback_lastLoginTime_tips:"Insira o horário do seu último login",
        feedback_lastPayTime_tips:"Insira o horário da sua recarga do mês passado",
        feedback_lastPayPrice_tips:"Insira o valor da sua recarga do mês passado",
        feedback_lastPayOrder_tips:"Insira o nº do seu pedido do mês passado",
        order_helper:"Como eu procuro meu número de pedido",
        max_img:"Imagens (até 3)",
        contact:"Info. de Contato",
        detailed_desc:"Detalhes",
        myservice_count:"Processando o(a)",
        myservice_count2:"feedbacks atuais",
        nomore:"Mais nada",
        feedback_state:"Situação:",
        feedback_type:"Tipo",
        create_time:"Horário de Criação",
        gift_title:"Pcts. de Jogo",
        mygift_title:"Meus Pcts.",
        recommend:"Jogos Recomendados",
        popularity:"Popular",
        play:"Novo",
        hot:"Pop",
        gift_empty:"Sem pct., fique ligado(a)",
        input_contact_email:"Insira o e-mail de contato",
        same_password:"A senha insirida é igual à antiga. Não precisa mudar",
        success:"Bem-sucedido",
        input_email:"Insira o endereço de e-mail",
        email:"E-mail",
        forgot:"Recuperar Senha",
        forgot_from_email:"Pelo e-mail vinculado",
        forgot_from_service:"Pelo atend. ao cliente",
        reset_password:"Confirmar a redef. de senha",
        unbind_email:"Vincule seu e-mail antes de desvincular",
        unbind_fb:"Após desvincular, você não poderá entrar nessa conta com sua conta do Facebook. Continuar?",
        unbind_google:"Após desvincular, você não poderá entrar nessa conta com sua conta do Google. Continuar?",
        unbind_twitter:"Após desvincular, você não poderá entrar nessa conta com sua conta do Twitter. Continuar?",
        tips_title:"Dicas",
        copy_success:"Copiado~",
        copy_faild:"Falha ao copiar. Seu navegador não suporta a cópia de textos",
        gift_rate_task:"Escreva seu comentário antes de resgatar este pacote",
        requesting:"Solicitando",
        get_gift:"Resgatar Pct.",
        gift_rate:"Comentar",
        gift_rate2:"Avaliar",
        gift_share:"Compartilhar",
        gift_bind:"Vincular",
        gift_fill:"Preencher",
        gift_ads:"Assistir Agora",
        gift_copy:"Copiar Código de Pres.",
        gift_exchange:"Resgate",
        gift_exchange_desc:"Vantagens - Código de Ativação - Insira o Código de Pres. p/ ativar",
        password_help:"Como vejo a senha da minha conta?",
        password_help_1:"1. Toque no avatar no canto superior esquerdo",
        password_help_2:"2. Toque no botão vermelho 'Central do Usuário' na parte inferior",
        password_help_3:"3. Toque em “Mudar de Conta”",
        password_help_4:"4. Toque no ícone Olho p/ ver a senha da conta atual",
        payhelp_title:"Selecione uma plataforma de pagamento",
        payhelp_google:"Pesquisa no Google Play",
        payhelp_google1:"1. Abra o Google Play e clique na barra de menu à esquerda",
        payhelp_google2:"2. Clique em Minha Conta",
        payhelp_google3:"3. Clique em Pedidos",
        payhelp_google4:"4. Clique no pedido correspondente p/ ver o recibo",
        payhelp_wechat:"Pesquisa no Wechat",
        payhelp_wechat1:"1. Clique p/ abrir o Wechat",
        payhelp_wechat2:"2. Clique em [Meu]",
        payhelp_wechat3:"3. Clique em Pagar - Carteira",
        payhelp_wechat4:"4. Clique em Fatura",
        payhelp_wechat5:"5. Clique no pedido correspondente p/ ver o recibo do pedido",
        payhelp_paypal:"Pesquisa no Paypal",
        payhelp_paypal1:"1. Logue na sua conta Paypal",
        payhelp_paypal2:"2. Clique em Registros de Transações",
        payhelp_paypal3:"3. Clique em Detalhes",
        payhelp_paypal4:"4. Clique no pedido correspondente p/ ver os detalhes",
        payhelp_alipay:"Pesquisa no Alipay",
        payhelp_alipay1:"1. Clique p/ abrir o Alipay",
        payhelp_alipay2:"2. Clique em [Meu]",
        payhelp_alipay3:"3. Clique em Fatura",
        payhelp_alipay4:"4. Clique no pedido correspondente p/ ver o recibo",
        bind_mobile:"Vinc. ao celular",
        bind_mobile_tips:"Você está vinculando esta conta ao seu celular",
        input_old_mobile:"Insira seu nº de celular antigo",
        input_validator_mobile:"Insira o nº de celular correto",
        input_bind_mobile:"Insira o nº de celular que deseja vincular",
        mobile_tips:"Nossas mensagens podem ser mal-interpretadas por alguns celulares como mensagens indesejadas. Acesse [Mensagens indesejadas] p/ verificar o código de verificação.",
        contact_mobile:"Nº de Celular de Contato",
        input_contact_mobile:"Insira o seu nº de contato de celular",
        input_mobile:"Insira seu nº de celular",
        mobile:"Nº de Celular",
        unbind_mobile:"Vincule seu e-mail antes de desvincular",
        input_old_verify_mobile:"Insira o código de verif. do seu celular antigo",
        input_new_verify_mobile:"Insira o código de verif. do seu telefone",
        pay_title:"Total:",
        pay_change_currency:"Trocar de Moeda",
        pay_tips:"(O pagamento final pode variar de acordo com os diferentes métodos de pagamentos e taxas de câmbio)",
        pay_select_mode:"Selecionar método de pagamento",
        wechat:"WeChat Pay",
        alipay:"Alipay",
        pay_paying:"Solicitando pagamento...",
        pay_fail:"Recarga falhou. Tente novamente mais tarde.",
        pay_return_tips:"Feche esse página se seu pagamento for concluído, ou outro pagamento será iniciado.",
        pay_discount:"(Desconto /0/%)",
        pay_late_mode:"Último método usado",
        pay_other_mode:"Outros métodos",
        pay_more:"Mais métodos ",
        pay_point:"Recarregue /0/ pts p/ obter /1/ pt de graça",
        points:"Recarga de Pts de Jogo",
        current_points:"Pontos Atuais:",
        pay:"Recarga",
        pwd:"Senha",
        pay_setting:"Definir senha de pagamento",
        select_amount:"Selecione o nº de pontos p/ recarregar",
        forget:"Recuperar senha de pagamento",
        forget_pay:"Esqueci a senha de pagamento",
        input_pwd:"Insira senha de pagamento de 6 dígitos",
        different:"As senhas não correspondem",
        setting_tips:"Você está definindo uma senha de pagamento. Mantenha-a segura",
        remain:"Saldo",
        add:"Pts de Recarga",
        pay_mode:"Métodos de Pagamento",
        change_payPwd_tips:"Você está alterando sua senha de pagamento. Mantenha sua nova senha segura",
        unsetting:"Sem senha de pagamento",
        email_unbound:"Nenhum e-mail vinculado",
        confirm_pay_pwd:"Confirme a senha de pagamento",
        input_confirm_pay_pwd:"Insira novamente a senha de pagamento",
        payPassword_error:"Senha de pagamento errada",
        balance:"Saldo Insuficiente",
        gamePoints:"Pontos",
        pay_complete:"Pagamento completo",
        pay_new_tips:"Não feche a página até a recarga ser concluída",
        pay_new_message:"Conclua a recarga na nova página",
        pay_cancel:"Cancelar pagamento",
        back:"Voltar",
        fee_name:"Programa de Marketing de Afiliados",
        fee_text1:"Trabalhe com HandZone Games",
        fee_text2:"Como parceiro da HandZone Games, você receberá um link de marketing exclusivo. Quaisquer recargas feitas por meio deste link trarão a você um dividendo da recarga.",
        fee_text3:"Você precisa atender aos seguintes requisitos p/ ingressar no Programa de Marketing de Afiliados da HandZone Games.",
        fee_price:"Valor de recarga igual:",
        fee_text4:"O Programa de Marketing de Afiliados estará disponível p/ você quando os requisitos forem atendidos, e você receberá de 10% a 70% (varia de acordo com os níveis de afiliado) do valor recarregado por novos jogadores que instalarem o jogo através do seu link.",
        fee_text5:"Parabéns! Você se inscreveu no Programa de Marketing de Afiliados",
        fee_text6:"Seu link de marketing:",
        fee_copy:"Copiar Link",
        fee_text7:"Níveis atuais de comissões:",
        fee_text8:"Três jogadores, A, B e C, recarregaram /0/ respectivamente",
        fee_text9:"Você obterá:",
        fee_text10:"A pode ser mais desenvolvido",
        fee_text11:"Agora você tem /0/ parceiros Nv. 1, /1/ parceiros Nv. 2 e /2/ parceiros Nv. 3",
        fee_price2:"Valor disponível p/ saque:",
        fee_start:"Começar a promover",
        fee_submit:"Saque",
        fee_tips1:"Requisitos de saque não atendidos",
        contact_kf:"Contate o atend. ao cliente",
        fee_tips2:"Contate o atend. ao cliente p/ saques",
        account_destroy:"Cancelamento de Conta",
        account_destroy_now:"Fazer uma solicitação de cancelamento de conta",
        account_destroy_cancel:"Revogar o pedido de cancelamento da sua conta",
        account_destroy_time:"Tempo restante p/ cancelamento da conta:",
        account_destroy_time2:"/0/ dia(s) depois",
        account_destroy_cancel_tips:"Você revogou a solicitação de cancelamento da conta",
        account_destroy_read_tips:"Eu li e concordo com",
        account_destroy_read_tips2:"Regras de Cancel. de Conta",
        account_destroy_content1:"●1. Quando cancelada, esta conta <a>não poderá logar na plataforma de contas da nossa empresa ou em nenhum produto</a> e <a>a conta e os dados não poderão ser recuperados</a>;",
        account_destroy_content2:"●2. Precisamos <a>coletar suas informações pessoais confidenciais (incluindo código de verificação de e-mail)</a> p/ fins de verificação de identidade p/ processar sua solicitação de cancelamento de conta. Quando sua conta for cancelada, nossa empresa <a>excluirá as informações acima ou as tornarão anônimas</a>;",
        account_destroy_content3:"●3. Quando sua conta for cancelada, todo o conteúdo, informações e registros em sua conta serão <a>excluídos ou ficarão anônimos</a>. Você não poderá mais pesquisar, acessar, obter, continuar a usar ou recuperar nada em sua conta, ou solicitar que nossa empresa recupere p/ você (a menos que exigido por lei ou autoridades reguladoras);",
        account_destroy_content4:"●4. Quando sua conta for cancelada, você não poderá mais logar na plataforma de transação de conta correspondente. <a>Sua solicitação de cancelamento de conta indica que você desiste voluntariamente de tudo que possui nesta conta</a>. <a>Antes do cancelamento, certifique-se de que suas transações e solicitações de saque na plataforma foram concluídas</a> p/ evitar qualquer perda desnecessária por sua incapacidade de acessar a plataforma após o cancelamento da conta;",
        account_destroy_content5:"●5. Quando sua conta for cancelada, <a>nossa empresa tem o direito de parar de fornecer qualquer serviço relacionado a esta conta</a>. O contrato de usuário e outros documentos que carregam direitos e obrigações que você assinou com nossa empresa serão rescindidos (a menos que acordado de outra forma entre nós ou exigido por lei);",
        account_destroy_content6:"●6. Você é o único responsável por qualquer inconveniente ou consequências negativas causadas pelo cancelamento de sua conta. Nossa empresa não será responsável por nada disso.",
        account_destroy_content7:"●7. Se houver qualquer disputa de propriedade durante o cancelamento da conta, nossa empresa tem o direito de encerrar sua solicitação de cancelamento;",
        account_destroy_content8:"●8. Existe um período de confirmação de cancelamento de conta de 7 dias, durante o qual você pode entrar nesta interface p/ revogar sua solicitação. Após o período de confirmação, você não poderá revogar sua solicitação de cancelamento de conta.",
        unbind_account_destroy:"Vincule seu e-mail antes de cancelar a conta.",
        pay_go:"Recarga",
        either_or:"ou",
        my_info_avatar:"Avatar",
        avatar:"Avatar",
        look_avatar:"Ver Avatar",
        change_avatar:"Mudar Avatar",
        avatar_reviewing:"Analisando",
        avatar_review_fail:"Falha na Análise",
        zbm0006:"Caro jogador, o avatar personalizado que você carregou contém /0/ e não foi aprovado",
        shhz0001:"Conteúdo politicamente sensível",
         shhz0002:"Conteúdo Pornográfico",
          shhz0003:"Cont. de jogos de azar",
        shhz0004:"Cont. relacionado a drogas",
        shhz0005:"Conteúdo religioso sensível",
        shhz0006:"Outros sites ou endereços de sites",
        shhz0007:"Publicidade",
        shhz0008:"Conteúdo indecente ou vulgar",
        account:"Nome de usuário",
        vip_kf:"Atend. ao Cliente VIP",
        super_vip:"Súper VIP",
        super_vip_kf:"Atend. ao Cliente Exclusivo Súper VIP",
        super_vip_be:"Torne-se um Súper VIP<br/>p/ desfrutar de um Serviço Exclusivo",
        super_vip_info1:"Serviço Exclusivo Súper VIP",
        super_vip_info2:"(Jogadores +18)",
        super_vip_info3:"1. Canal Exclusivo<br/>Atendimento Exclusivo Individual. Sua sugestão será enviada diretamente aos desenvolvedores!<br/>2. Prioridade Exclusiva<br/>Obtenha acesso prioritário às informações do jogo mais confiáveis<br/>3. Pct. Exclusivos<br/>Seja nosso Súper VIP p/ resgatar pct. muito grandiosos!<br/>4. Eventos Exclusivos<br/>Desfrute de eventos e vantagens exclusivos de tempos em tempos!",
        super_vip_gain1:"Como se tornar um Súper VIP",
        super_vip_gain2:"Método 1: O valor de recarga total atinge /0/",
        super_vip_get:"Obter Agora",
        super_vip_gift:"Pct. Exclusivo Súper VIP",
        super_vip_gift_tips:"Você pode resgatar o pct. exclusivo após a verificação bem-sucedida. Contate nosso serviço ao cliente!",
        super_vip_gift_1:"Título no Jogo",
        super_vip_gift_2:"Decoração Rara",
        super_vip_gift_3:"Pet",
        super_vip_gift_4:"Pct. Person.",
        super_vip_gift_5:"Item Raro",
        super_vip_gift_wechat:"WeChat:",
        feedback_reward_1:"Recompensar o Atend. ao Cliente",
        feedback_reward_2:"Bonif.",
        feedback_reward_3:"A bonificação é o seu presente voluntário p/ o nosso atendimento ao cliente e o valor total será pago à equipe de atendimento ao cliente responsável",
        wechat_native_1:"Valor do pagamento:",
        wechat_native_2:"Abra seu WeChat e escaneie o código QR p/ concluir o pagamento",
        wechat_native_3:"Pagamento concluído",
        login_usern:"Logar",
        register_username:"Inscrever-se",
        quick_register:"Inscrição Rápida",
        enterNow:"Logar Agora",
        btn_register:"Inscrever-se Agora",
        agreed1:"Concordo",
        btn_have_usern:"Tenho uma conta",
        to_login:"Ir Logar",
        login_success:"Login Bem-sucedido",
        agree_protocol:"Concorde com os Termos de Serviço",
        UserLogin:"Logar",
        UserLogin_text1:"Insira Nome de Usuário",
        UserLogin_text2:"Insira Senha",
        UserLogin_text4:"Logando",
        UserLogin_text5:"Inscrever-se",
        UserLogin_text6:"Inscrição bem-sucedida",
        online_earning:"Ganhos Online",
        online_earning_center:"Centro de Ganhos",
        online_earning_sigtiaoin:"Desafio de Login",
        online_earning_text1:"Conclua Missões, ganhe<br> Dinheiro",
        online_earning_text2:"Assista ao vídeo uma vez para fazer login <br/> Faça login por 7 dias no total para receber recompensas generosas",
        online_earning_text3:"Recompensas redequiíveis",
        online_earning_text4:"Assista e resgate recomp.",
        online_earning_text5:"Restante(s): /0/",
        online_earning_text6:"Tentativas insuficientes",
        online_earning_text7:"Não resgatado",
        exchange:"Resgatar: /0/",
        exchange_center:"Centro de Resgate",
        exchange_prompt:"Dicas de Resgate",
        exchange_confirm:"Confirmar",
        exchange_consume:"Custo: /0/",
        exchange_balance:"Saldo",
        exchange_detail:"Detalhes",
        exchange_balance_detail:"Detalhes do Saldo",
        exchange_reward:"Resgatar Recomp.",
        exchange_text1:"/0/ Ponto(s)",
        exchange_text2:"$/0/",
        exchange_text3:"Pontos",
        exchange_text4:"Pct. de Dinheiro",
        exchange_text5:"Limite de resgate: /0///1/",
        exchange_text6:"Limite Diário：/0///1/",
        exchange_text7:"Insira o Código de Convite p/ vincular",
        exchange_text8:"Insira sua conta do PayPal",
        exchange_text9:"Vinc. de Convidador",
        exchange_text10:"Confirmar",
        exchange_text11:"Resgatado. Você pode verificar os resultados na Central do Usuário",
        exchange_text12:"Quantidade insuficiente",
        exchange_text13:"Mostrar apenas os últimos 100 registros",
        exchange_rule:"Regras",
        exchange_rule2:"Regras de Resgate",
        exchange_rule2_html:"1. A vinculação de e-mail é necessária para ativar o Resgate de Conta;<br/>2. Assegure-se de inserir informações precisas durante o resgate. Quaisquer perdas devido a erros pessoais no vínculo ou resgate são de sua própria responsabilidade;<br />3. Em caso de falha no resgate, forneça a mensagem de erro. Se o problema não for resolvido, contate o Atendimento ao Cliente;<br />4. Começar o resgate significa que você concorda com as regras. O direito de interpretação final está a cargo de Handzone.",
        invite_Friends:"Convidar Amigos",
        invite_binding:"Vinc. de Convidador",
        new_envelope:"Pct. de Iniciante",
        signReward:"Concluir Login",
        completeTask:"Conclua /0/ Missão(ões)",
        completeExchange:"Resgatar: /0/, Custo",
        language:"linguagem",
        country:"país/região",
        select_pay_mode:"Escolha obter o pagamento local correto",
        exchange_text14:"Ainda não abriu",
        hint_func: "Bu işlev henüz kullanılamıyor, lütfen bizi izlemeye devam edin",
        game_hub:"Oyun merkezi",
        not_login:"Giriş yapmadınız",
        unlock:"Daha heyecan verici içeriğin kilidini açmak için giriş yapın",
        for_the_account:"Bir hesap oluşturuyorsunuz",
        for_the_account_1:"E-postanızı bağlayın. E-postanızı bağlamak, hesabınızın güvenliğini etkili bir şekilde sağlayabilir. E-postanız aracılığıyla şifrenizi alabilirsiniz.",
        account_length:"Hesap uzunluğu 6-18 karakterdir",
        modify_successfully:"Başarıyla değiştirildi",
        binding_successful:"Bağlama başarılı",
        start_game:"başlangıç",
        game_down:"indirmek",
        new_travel:"yeni tur",
        please_acc_code:"Lütfen kullanıcı adını girin",
        please_paw_code:"Lütfen şifre giriniz",
        agree:"kabul etmek",
        install:"kurmak",
        logout:"oturumu Kapat",
        is_logout_account:"Giriş hesabından çıkış yapılıp yapılmayacağı",
        top_free:"Popüler ücretsiz",
        top_jackpot:"Popüler en çok satan",
        peace:"Ve",
        priv_policy:"Gizlilik Politikası",
        tally:"Puanlar",
        give_away:"Hediyeler içerir",
        voucher:"Kuponlar",
        waiting_used:"Kullanılacak olan"
    }
}
