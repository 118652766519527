import { setStore, getStore } from '@/utils/store'

const pay = {
    state: {
        discountList: getStore({ name: 'discount' }) || '',
        mode: getStore({ name: 'mode' }) || '',
        amount: getStore({ name: 'amount' }) || '',
        payCurrency: getStore({ name: 'payCurrency' }) || 'CNY',
        points: getStore({ name: 'points' }) || '',
        showBack: 0,
        productId: getStore({ name: 'productId' }) || '',
        outOrderNo: getStore({ name: 'outOrderNo' }) || '',
    },
    mutations: {
        setDiscountList(state, value) {
            state.discountList = value
            setStore({ name: 'discount', content: state.discountList })
        },
        setMode(state, value) {
            state.mode = value
            setStore({ name: 'mode', content: state.mode })
        },
        setAmount(state, value) {
            state.amount = value
            setStore({ name: 'amount', content: state.amount })
        },
        setPayCurrency(state, value) {
            state.payCurrency = value
            setStore({ name: 'payCurrency', content: state.payCurrency })
        },
        setPoints(state, value) {
            state.points = value || ""
            setStore({ name: 'points', content: state.points })
        },
        setShowBack(state, value) {
            state.showBack = value || ""
        },
        setProductId(state, value) {
            state.productId = value || ""
            setStore({ name: 'productId', content: state.productId })
        },
        setOutOrderNo(state, value) {
            state.outOrderNo = value || ""
            setStore({ name: 'outOrderNo', content: state.outOrderNo })
        }
    },
    actions: {
        initPay (state, info) {
            state.commit('setDiscountList', info.discount)
            state.commit('setMode', decodeURI(info.mode))
            state.commit('setAmount', info.amount)
            state.commit('setPayCurrency', info.currency)
            state.commit('setPoints', info.points)
            state.commit('setShowBack', info.showBack)
            state.commit('setProductId', info.productId)
            state.commit('setOutOrderNo', info.outOrderNo)
        },

        logout(state) {
            state.commit('setUserId', -1)
            state.commit('setToken', "")
            state.commit('setUserName', "")
            state.commit('setPassword', "")
        },
    }
}

export default pay;