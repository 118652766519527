module.exports = {
    LOCAL:{
        zh_CN:"简体中文",
        zh_TW:"繁體中文",
        en_US:"English",
        ko_KR:"한국어",
        ru_RU:"Русский",
        de_DE:"Deutsch",
        ja_JP:"日本",
        pt_BR:"Português",
        tr_TR:"Türkçe",
        vi_VN:"Tiếng Việt",
        es_LA:"Español Americano",
        ar_SA:"عربي",
    },
    SERVER:{
        server_wz_limit_count:"Límite máximo alcanzado",
        server_wz_task_cd:"En enfriamiento",
        server_wz_lack_money:"Saldo insuficiente",
        server_result_success:"Hecho",
        server_result_fail:"Fallido",
    },
    LAN:{
        change_pwd:"Cambiar contraseña",
        change_pwd_tips:"Estás cambiando la contraseña de la cuenta, por favor, guarda tu nueva contraseña a salvo",
        unbound:"(No vinculado)",
        default_language:"Idioma por defecto",
        user_service:"Términos de servicio",
        bind_email:"Vincular con correo",
        loading:"Cargando...",
        helper:"Ayuda",
        gift:"Paquete",
        mine:"Yo",
        confirm:"Confirmar",
        cancel:"Cancelar",
        bind_email_tips:"Estás vinculando tu cuenta a un correo. Mediante este proceso, tu cuenta quedará mejor protegida y podrás recuperar tu contraseña por el correo vinculado.",
        input_old_email:"Por favor, ingresa tu correo antiguo",
        input_old_verify_code:"Por favor, ingresa el código de verificación de tu correo antiguo",
        input_new_verify_code:"Por favor, ingresa el código de verificación de tu correo",
        input_validator_email:"Por favor, ingresa un correo electrónico válido",
        input_bind_email:"Por favor, ingresa el correo al que deseas vincular",
        verify_code:"Código de verificación",
        next:"Siguiente",
        bind_now:"Vincular ahora",
        tips:"*Recordatorio",
        email_tips:"Nuestro mensaje puede ser considerado erróneamente por algunos correos electrónicos gratuitos como spam. Por favor, revisa la [Carpeta de spam] para comprobar el código de verificación. ",
        send_verify_code:"Enviar código",
        sending:"Enviando...",
        send_success:"Enviado",
        resend:"Reenviar",
        old_pwd:"Contraseña antigua",
        input_old_pwd:"Por favor, ingresa la contraseña antigua",
        new_pwd:"Nueva contraseña",
        input_new_pwd:"Por favor, ingresa la nueva contraseña (entre 6 y 15 letras)",
        confirm_pwd:"Confirmar contraseña",
        input_confirm_pwd:"Ingresa la nueva contraseña de nuevo",
        change_now:"Cambiar ahora",
        switch_account:"Cambiar de cuenta",
        playing:"Estás jugando",
        search_placeholder:"Ingresa la palabra clave",
        search:"Buscar",
        empty:"Sin datos",
        self_service:"Servicio de autoayuda",
        submit_feedback:"Enviar comentario",
        online_service:"Atención al cliente en línea",
        official_verification:"Verificación oficial",
        myservice:"Mis comentarios",
        questionTabs_1:"Temas populares",
        questionTabs_2:"Recarga",
        questionTabs_3:"Juego",
        questionTabs_4:"Acceder",
        questionTabs_999:"Más",
        question_detail:"Detalles",
        question_common:"Preguntas frecuentes",
        search_empty:"Sin resultados",
        feedback_detail:"Detalles del comentario",
        contact_email:"Correo de contacto",
        screenshot:"Captura de pantalla",
        detailed_info:"Detalles",
        my_reply:"Mis respuestas",
        kf_reply:"Respuesta de la atención al cliente /0/",
        input_feedback:"Por favor, describe el asunto en detalle",
        service_score:"Por favor, valora este servicio",
        score:"/0/",
        scored:"Valorado, gracias por tu comentario",
        thank_feedback:"Valorado, gracias por tu comentario",
        score_tips:"Por favor, elige una valoración",
        feedback_processing:"Procesando tu comentario, por favor, espera...",
        feedback_tips:"Si el problema sigue sin resolverse, no lo envíe repetidamente. Puedes contestar aquí de nuevo",
        submit:"Enviar",
        processing:"Procesando",
        replied:"Respondido",
        completed:"Completado",
        uploading:"Cargando...",
        upload_failed:"Carga fallida",
        upload_tips:"Espera a que se cargue la imagen",
        robot_name:"Bot",
        robot_name2:"Bot a tu servicio",
        welcom_msg:"Gracias por tu visita. Puedes consultar introduciendo la palabra clave y el Bot te ayudará.",
        change_batch:"Ver más",
        forget_pwd:"Me olvidé la contraseña",
        game_web:"Página web oficial",
        contact_customer:"Servicio de personal",
        ques_placeholder:"Por favor, describe el asunto",
        send:"Enviar",
        connection:"Conectando...",
        enter_question:"Ingresa el asunto primero",
        are_you_asking:"Estás tratando de preguntar por:",
        robot_not_found:"Lo siento, no te entiendo. Para poder resolver mejor tu problema, te sugiero a que lo describas de otra manera. Si necesitas atención de nuestro personal, por favor, pulsa el botón de Servicio de personal.",
        customer_service:"Servicio de personal",
        feedback_0:"Recarga no completada",
        feedback_1:"Errores",
        feedback_2:"Reportar",
        feedback_3:"Sugerencias",
        feedback_4:"Recuperar cuenta",
        feedback_5:"enviar captura de pantalla",
        feedback_server:"Servidor",
        feedback_rolename:"Nombre del personaje",
        feedback_price:"Importe del pedido",
        feedback_order:"Número del pedido",
        feedback_report:"Reportar",
        feedback_username:"Cuenta",
        feedback_registerTime:"Fecha de registro",
        feedback_registerDevice:"Dispositivo",
        feedback_lastLoginTime:"Último acceso",
        feedback_lastPayTime:"Veces de recarga del último mes",
        feedback_lastPayPrice:"Importe de recarga del último mes",
        feedback_lastPayOrder:"Número de pedido de recarga del último mes",
        feedback_server_tips:"Por favor, ingresa el nombre del servidor",
        feedback_rolename_tips:"Por favor, ingresa el nombre del personaje",
        feedback_price_tips:"Por favor, ingresa el importe del pedido",
        feedback_order_tips:"Por favor, ingresa el número del pedido",
        feedback_report_tips:"Por favor, ingresa el personaje al que deseas reportar",
        feedback_username_tips:"Por favor, ingresa la cuenta que quieres recuperar",
        feedback_registerTime_tips:"Por favor, ingresa la fecha de registro",
        feedback_registerDevice_tips:"Por favor, ingresa el dispositivo que usó para el registro",
        feedback_lastLoginTime_tips:"Por favor, ingresa la fecha de tu último acceso",
        feedback_lastPayTime_tips:"Por favor, ingresa la hora de recarga del mes pasado",
        feedback_lastPayPrice_tips:"Por favor, ingresa el importe de recarga del mes pasado",
        feedback_lastPayOrder_tips:"Por favor, ingresa el número de pedido del mes pasado",
        order_helper:"¿Cómo busco mi número del pedido?",
        max_img:"Se pueden subir hasta 3 imágenes",
        contact:"Información de contacto",
        detailed_desc:"Detalles",
        myservice_count:"Procesando tus",
        myservice_count2:"comentarios actuales ",
        nomore:"No hay más",
        feedback_state:"Estado",
        feedback_type:"Tipo",
        create_time:"Hora de creación",
        gift_title:"Paquetes del juego",
        mygift_title:"Mis paquetes",
        recommend:"Juegos recomendados",
        popularity:"Popular",
        play:"Nuevo",
        hot:"Candente",
        gift_empty:"Sin paquetes, permanece atento",
        input_contact_email:"Por favor, ingresa el correo de contacto",
        same_password:"La contraseña ingresada es la misma que la antigua, no hace falta cambiar",
        success:"Hecho",
        input_email:"Ingresa tu correo electrónico",
        email:"Correo",
        forgot:"Recuperar contraseña",
        forgot_from_email:"Por el correo vinculado",
        forgot_from_service:"Por atención al cliente",
        reset_password:"Confirma para restablecer la contraseña",
        unbind_email:"Por favor, vincula tu correo antes de desvincular",
        unbind_fb:"Al desvincular, no podrás acceder a esta cuenta por Facebook. ¿Deseas desvincular de todas formas?",
        unbind_google:"Al desvincular, no podrás acceder a esta cuenta por Google. ¿Deseas desvincular de todas formas?",
        unbind_twitter:"Al desvincular, no podrás acceder a esta cuenta por Twitter. ¿Deseas desvincular de todas formas?",
        tips_title:"Consejos",
        copy_success:"Copiado~",
        copy_faild:"No se pudo copiar. El navegador no permite copiar",
        gift_rate_task:"Escribe tu comentario antes de reclamar este paquete",
        requesting:"Solicitando",
        get_gift:"Reclamar paquete",
        gift_rate:"Ir a comentar",
        gift_rate2:"Ir a valorar",
        gift_share:"Ir a compartir",
        gift_bind:"Ir a vincular",
        gift_fill:"Ir a rellenar",
        gift_ads:"Ver ahora",
        gift_copy:"Copiar código de regalo",
        gift_exchange:"Canje",
        gift_exchange_desc:"Beneficios - Código de activación - Ingresa el código de regalo para activar",
        password_help:"¿Cómo puedo ver mi contraseña?",
        password_help_1:"1. Pulsa en el avatar de la esquina superior izquierda",
        password_help_2:"2. Pulsa el botón rojo del Centro de usuario en el fondo",
        password_help_3:"3. Pulsa Cambiar de cuenta",
        password_help_4:"4. Pulsa el icono de ojo para ver la contraseña de la cuenta actual",
        payhelp_title:"Por favor, selecciona una plataforma de pago",
        payhelp_google:"Buscar en Google Play",
        payhelp_google1:"1. Abre Google Play y pulsa en la barra del menú que hay a la izquierda",
        payhelp_google2:"2. Pulsa en Mi cuenta",
        payhelp_google3:"3. Pulsa en Pedidos",
        payhelp_google4:"4. Pulsa en el pedido correspondiente para ver el recibo",
        payhelp_wechat:"Buscar en WeChat",
        payhelp_wechat1:"1. Pulsa para abrir WeChat",
        payhelp_wechat2:"2. Pulsa en [Yo]",
        payhelp_wechat3:"3. Pulsa en Pagar - Cartera",
        payhelp_wechat4:"4. Pulsa en Factura",
        payhelp_wechat5:"5. Pulsa el pedido correspondiente para ver el recibo del pedido",
        payhelp_paypal:"Buscar en Paypal",
        payhelp_paypal1:"1. Accede a tu cuenta de Paypal",
        payhelp_paypal2:"2. Pulsa en Historial de transacciones",
        payhelp_paypal3:"3. Pulsa en Detalles",
        payhelp_paypal4:"4. Pulsa en el pedido correspondiente para ver los detalles",
        payhelp_alipay:"Buscar en Alipay",
        payhelp_alipay1:"1. Pulsa para abrir Alipay",
        payhelp_alipay2:"2. Pulsa en [Yo]",
        payhelp_alipay3:"3. Pulsa en Factura",
        payhelp_alipay4:"4. Pulsa en el pedido correspondiente para ver el recibo",
        bind_mobile:"Vincular al celular",
        bind_mobile_tips:"Estás vinculado esta cuenta a tu celular",
        input_old_mobile:"Ingresa tu número de celular antiguo",
        input_validator_mobile:"Ingresa el número de celular correcto",
        input_bind_mobile:"Ingresa el número de celular al que deseas vincular",
        mobile_tips:"Puede que nuestros mensajes sean considerados erróneamente por algunos celulares como mensajes basura. Por favor, revisa los [Mensajes basura] para comprobar el código de verificación.",
        contact_mobile:"Número de celular de contacto",
        input_contact_mobile:"Ingresa tu número de celular de contacto.",
        input_mobile:"Ingresa tu número de celular",
        mobile:"Número de celular",
        unbind_mobile:"Por favor, vincula tu correo antes de desvincular",
        input_old_verify_mobile:"Ingresa el código de verificación en tu antiguo celular",
        input_new_verify_mobile:"Ingresa el código de verificación en tu celular",
        pay_title:"Total:",
        pay_change_currency:"Cambiar de divisa",
        pay_tips:"(El importe del pago final puede variar en función de los diferentes métodos de pago y las tasas de intercambio)",
        pay_select_mode:"Selecciona el método de pago",
        wechat:"WeChat Pay",
        alipay:"Alipay",
        pay_paying:"Solicitando pago...",
        pay_fail:"Recarga fallida, inténtalo de nuevo más tarde.",
        pay_return_tips:"Por favor, cierra esta página si tu paga se realizó con éxito, de lo contrario, se iniciará otro pago.",
        pay_discount:"(Descuento /0/%)",
        pay_late_mode:"Último método de pago seleccionado",
        pay_other_mode:"Otros métodos de pago",
        pay_more:"Más métodos ",
        pay_point:"Recarga /0/ puntos para obtener /1/ puntos gratis",
        points:"Recarga puntos del juego",
        current_points:"Puntos actuales:",
        pay:"Recarga",
        pwd:"Contraseña",
        pay_setting:"Establecer contraseña de pago",
        select_amount:"Por favor, selecciona el número de puntos a recargar",
        forget:"Recuperar contraseña de pago",
        forget_pay:"Olvidé la contraseña de pago",
        input_pwd:"Ingresa una contraseña de pago de 6 dígitos",
        different:"Las contraseñas no coinciden",
        setting_tips:"Estás estableciendo una contraseña de pago. Por favor, mantén tu contraseña a salvo.",
        remain:"Saldo",
        add:"Recargar puntos",
        pay_mode:"Métodos de pago",
        change_payPwd_tips:"Estás cambiando tu contraseña de pago. Por favor, mantén tu contraseña a salvo.",
        unsetting:"No hay contraseña de pago",
        email_unbound:"No hay correo vinculado",
        confirm_pay_pwd:"Confirma la contraseña de pago",
        input_confirm_pay_pwd:"Vuelve a ingresar la contraseña de pago",
        payPassword_error:"Contraseña de pago incorrecta",
        balance:"Saldo insuficiente",
        gamePoints:"Puntos",
        pay_complete:"Pago completado",
        pay_new_tips:"No cierres esta página hasta que se complete la recarga",
        pay_new_message:"Por favor, completa la recarga en la nueva página",
        pay_cancel:"Cancelar pago",
        back:"Atrás",
        fee_name:"Programa de marketing de afiliación",
        fee_text1:"Trabajar con HandZone Games",
        fee_text2:"Como socio de HandZone Games, obtendrás un enlace de marketing único, por el cual podrás obtendrás un dividendo por cualquier recarga realizada por dicho enlace.",
        fee_text3:"Debes cumplir los siguientes requisitos para poder participar en el programa de marketing de afiliación de HandZone Games.",
        fee_price:"Importe de recarga equivalente:",
        fee_text4:"El programa de marketing de afiliación estará disponible cuando cumplas los requisitos y obtendrás un 10% - 70% (dependiendo del nivel de afiliación) del importe recargado por los nuevos jugadores quienes instalan el juego a través de tu enlace.",
        fee_text5:"¡Felicitaciones! Te has unido en el programa de marketing de afiliación",
        fee_text6:"Tu enlace de marketing:",
        fee_copy:"Copiar enlace",
        fee_text7:"Niveles de comisiones actuales:",
        fee_text8:"Tres jugadores, A, B y C recargan /0/ respectivamente",
        fee_text9:"Obtendrás:",
        fee_text10:"A puede seguir desarrollándose",
        fee_text11:"Ahora tienes /0/ socios de niv. 1, /1/ socios de niv. 2 y /2/ socios de niv. 3.",
        fee_price2:"Saldo disponible para la retirada:",
        fee_start:"Empezar marketing",
        fee_submit:"Retirar",
        fee_tips1:"No se cumplen los requisitos de retirada",
        contact_kf:"Contactar con atención al cliente",
        fee_tips2:"Contactar con atención al cliente para retiradas",
        account_destroy:"Cancelación de cuenta",
        account_destroy_now:"Solicitar una cancelación de cuenta",
        account_destroy_cancel:"Revocar una solicitud de cancelación de cuenta",
        account_destroy_time:"Tiempo restante para la cancelación de cuenta:",
        account_destroy_time2:"En /0/ d",
        account_destroy_cancel_tips:"Has revocado una solicitud de cancelación de cuenta",
        account_destroy_read_tips:"He leído y estoy de acuerdo con ",
        account_destroy_read_tips2:"Reglas de cancelación de cuenta",
        account_destroy_content1:"●1. Al cancelar, esta cuenta <a>no podrá acceder a la plataforma de cuentas de nuestra compañía ni a cualquier producto</a> y <a>no se podrán recuperar la cuenta ni tus datos</a>.",
        account_destroy_content2:"●2. Necesitamos <a>recolectar tu información sensible personal (incluyendo el código de verificación por correo)</a> para el propósito de verificación de identidad para procesar la solicitud de cancelación de tu cuenta. Una vez que tu cuenta sea cancelada, nuestra compañía borrará <a>la información anterior o lo convertirá en anónimo</a>.",
        account_destroy_content3:"●3. Cuando se cancele tu cuenta, todo el contenido, la información y el registro que hay en tu cuenta <a>serán borrados o convertidos en anónimo</a>. Ya no podrás acceder, obtener, continuar usando o recuperar nada en tu cuenta, ni solicitar a la compañía una recuperación de la misma (a menos que requerido por la ley o las autoridades regulatorias).",
        account_destroy_content4:"●4. Cuando se cancele tu cuenta, ya no podrás acceder en la plataforma de transacción de cuentas correspondiente. <a>Tu solicitud de cancelación de cuenta indica que renuncias voluntariamente todos los intereses que tienes en esta cuenta</a>. <a>Antes de la cancelación, por favor, asegura de que se completaron tus solicitudes de retirada y transacción en la plataforma</a> para evitar cualquier pérdida innecesaria por no poder acceder a la plataforma después de la cancelación.",
        account_destroy_content5:"●5. Cuando se cancele tu cuenta, <a>nuestra compañía tiene derecho a dejar de prestar cualquier servicio relacionado con esta cuenta</a>. El acuerdo de usuario y otros documentos que conlleven derechos y obligaciones que firmaste con nuestra compañía serán rescindidos (a menos que se acuerde lo contrario entre nosotros o lo exija la ley).",
        account_destroy_content6:"●6. Eres el único responsable de cualquier inconveniente o consecuencia negativa causada por la cancelación de tu cuenta. Nuestra empresa no se responsabilizará por ello.",
        account_destroy_content7:"●7. Si hay cualquier disputa por la propiedad durante la cancelación de cuenta, nuestra compañía tiene el derecho de finalizar tu solicitud de cancelación.",
        account_destroy_content8:"●8. Hay un periodo de confirmación de 7 días para la cancelación de la cuenta, durante el cual puedes entrar en esta interfaz para revocar tu solicitud. Una vez transcurrido el periodo de confirmación, ya no se podrá revocar la solicitud.",
        unbind_account_destroy:"Vincula tu correo antes de cancelar la cuenta",
        pay_go:"Recarga",
        either_or:"o",
        my_info_avatar:"Avatar",
        avatar:"Avatar",
        look_avatar:"Ver avatar",
        change_avatar:"Cambiar avatar",
        avatar_reviewing:"En revisión",
        avatar_review_fail:"Revisión fallida",
        zbm0006:"Estimado jugador, el avatar personalizado que cargaste contiene /0/ y no fue aprobado",
        shhz0001:"Contenidos sensibles políticos",
         shhz0002:"Contenidos pornográficos",
          shhz0003:"Contenidos relacionados con apuestas",
        shhz0004:"Contenidos relacionados con drogas",
        shhz0005:"Contenidos sensibles religiosos",
        shhz0006:"Otras páginas o direcciones web",
        shhz0007:"Publicidad",
        shhz0008:"Contenidos vulgares o indecentes",
        account:"Nombre de usuario",
        vip_kf:"Atención al cliente VIP",
        super_vip:"Súper VIP",
        super_vip_kf:"Atención al cliente exclusivo de Súper VIP",
        super_vip_be:"Conviértete en Súper VIP<br/>Disfruta de servicios exclusivos",
        super_vip_info1:"Servicio exclusivo de Súper VIP",
        super_vip_info2:"(SOLO para jugadores adultos)",
        super_vip_info3:"1. Canal exclusivo<br/>Servicio exclusivo individual. ¡Tus consejos llegarán directamente a los desarrolladores!<br/>2. Prioridad exclusiva<br/>Obtén acceso prioritario a la información más fiable del juego<br/>3. Paquetes exclusivos<br/>¡Sé nuestro Súper VIP para conseguir paquetes supergrandes!<br/>4. Eventos exclusivos<br/>¡Disfruta de eventos exclusivos y ventajas cada cierto tiempo!",
        super_vip_gain1:"Cómo convertirse en Súper VIP",
        super_vip_gain2:"Método 1: alcanzar /0/ de recarga total",
        super_vip_get:"Obtener ahora",
        super_vip_gift:"Paquete exclusivo de Súper VIP",
        super_vip_gift_tips:"Puedes reclamar el paquete exclusivo una vez que se complete la verificación. ¡Ve a contactar con atención al cliente!",
        super_vip_gift_1:"Título en el juego",
        super_vip_gift_2:"Decoración rara",
        super_vip_gift_3:"Mascota",
        super_vip_gift_4:"Paquete personalizado",
        super_vip_gift_5:"Objeto raro",
        super_vip_gift_wechat:"WeChat:",
        feedback_reward_1:"Recompensar a la atención al cliente",
        feedback_reward_2:"Recompensa",
        feedback_reward_3:"Esta recompensa es un regalo voluntario tuyo a nuestro servicio de atención al cliente y se abonará en su totalidad al personal responsable de atención al cliente.",
        wechat_native_1:"Importe de pago:",
        wechat_native_2:"Por favor, abre tu WeChat y escanea el código QR para completar el pago",
        wechat_native_3:"Pago completado",
        login_usern:"Acceder",
        register_username:"Registrarse",
        quick_register:"Registro rápido",
        enterNow:"Acceder ahora",
        btn_register:"Registrarse ahora",
        agreed1:"Aceptar",
        btn_have_usern:"¿Tienes una cuenta?",
        to_login:"Ir a acceder",
        login_success:"Acceso exitoso",
        agree_protocol:"Acepta los términos del servicio",
        UserLogin:"Acceder",
        UserLogin_text1:"Ingresa el nombre de usuario",
        UserLogin_text2:"Ingresa la contraseña",
        UserLogin_text4:"Accediendo",
        UserLogin_text5:"Registrándose",
        UserLogin_text6:"Registro exitoso",
        online_earning:"Ganancia en línea",
        online_earning_center:"Centro de ganancias",
        online_earning_sigtiaoin:"Desafío de acceso",
        online_earning_text1:"Realizar tareas<br>ganar dinero",
        online_earning_text2:"Mire el vídeo una vez para iniciar sesión<br/>Reciba generosas recompensas durante 7 días en total",
        online_earning_text3:"Recompensas convertibles",
        online_earning_text4:"Mira y reclama recompensas",
        online_earning_text5:"Restantes: /0/",
        online_earning_text6:"Intentos insuficientes",
        online_earning_text7:"No reclamado",
        exchange:"Canjear: /0/",
        exchange_center:"Centro de canje",
        exchange_prompt:"Consejos para canjear",
        exchange_confirm:"Confirmar",
        exchange_consume:"Costo: /0/",
        exchange_balance:"Saldo",
        exchange_detail:"Detalles",
        exchange_balance_detail:"Detalles del saldo",
        exchange_reward:"Canjear recompensas",
        exchange_text1:"/0/ pts.",
        exchange_text2:"$/0/",
        exchange_text3:"Pts.",
        exchange_text4:"Pack de efectivo",
        exchange_text5:"Límite de canje: /0///1/",
        exchange_text6:"Đổi Giới Hạn Ngày：/0///1/",
        exchange_text7:"Introduce el código de invitación para vincular",
        exchange_text8:"Introduce tu cuenta PayPal",
        exchange_text9:"Vinculación de invitador",
        exchange_text10:"Confirmar",
        exchange_text11:"Canjeado. Puedes verificar los resultados en el centro de usuario",
        exchange_text12:"Cantidad insuficiente",
        exchange_text13:"Solo mostrar los últimos 100 registros",
        exchange_rule:"Reglas",
        exchange_rule2:"Reglas de canje",
        exchange_rule2_html:"1. Es necesario vincular el email para activar el canje de la cuenta;<br/>2. Asegúrate de que la información durante el canje es correcta. Cualquier pérdida debido a errores personales en la vinculación o el canje es tu responsabilidad;<br />3. En caso de fallo en el canje, por favor proporciona el mensaje de error. Si el problema persiste, contacta con el servicio al cliente;<br />4. Iniciar un canje implica tu aceptación de las reglas. los derechos de interpretación finales del canje están reservados por Handzone.",
        invite_Friends:"Invitar amigos",
        invite_binding:"Vinculación de invitador",
        new_envelope:"Pack para principiantes",
        signReward:"Completar acceso",
        completeTask:"Completar /0/ misión(es)",
        completeExchange:"Canjear: /0/, costo",
        language:"idioma",
        country:"país/región",
        select_pay_mode:"Elija obtener el pago local correcto",
        exchange_text14:"Aún no abierto",
        hint_func: "La función aún no está disponible, estad atentos",
        game_hub:"centro de juego",
        not_login:"Sin iniciar sesión",
        unlock:"Inicia sesión para desbloquear contenido más interesante",
        for_the_account:"Estas creando una cuenta",
        for_the_account_1:"Vincular su correo electrónico. Vincular su correo electrónico puede garantizar eficazmente la seguridad de su cuenta. Puede recuperar su contraseña a través de su correo electrónico.",
        account_length:"La longitud de la cuenta es de 6 a 18 caracteres.",
        modify_successfully:"Modificado con éxito",
        binding_successful:"Vinculación exitosa",
        start_game:"comenzar",
        game_down:"descargar",
        new_travel:"nueva gira",
        please_acc_code:"Por favor ingrese el nombre de usuario",
        please_paw_code:"Por favor, ingrese contraseña",
        agree:"aceptar",
        install:"configuración",
        logout:"desconectar",
        is_logout_account:"Si cerrar sesión en la cuenta de inicio de sesión",
        top_free:"populares gratis",
        top_jackpot:"Popular más vendido",
        peace:"y",
        priv_policy:"política de privacidad",
        tally:"Puntos",
        give_away:"Incluye regalos",
        voucher:"Vales",
        waiting_used:"Para ser utilizado"
    }
}
