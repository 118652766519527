module.exports = {
    LOCAL:{
        zh_CN:"简体中文",
        zh_TW:"繁體中文",
        en_US:"English",
        ko_KR:"한국어",
        ru_RU:"Русский",
        de_DE:"Deutsch",
        ja_JP:"日本",
        pt_BR:"Português",
        tr_TR:"Türkiye",
        vi_VN:"Tiếng Việt",
        es_LA:"Español Americano",
        ar_SA:"عربي",
    },
    SERVER:{
        server_wz_limit_count:"Достигнут лимит",
        server_wz_task_cd:"Восстанавливается",
        server_wz_lack_money:"Недостаточный баланс",
        server_result_success:"Успех",
        server_result_fail:"Не получилось",
    },
    LAN:{
        change_pwd:"Изменить пароль",
        change_pwd_tips:"Вы собираетесь изменить пароль учетной записи. Не забудьте его сохранить.",
        unbound:"(Нет привязки)",
        default_language:"Язык по умолчанию",
        user_service:"Условия использования",
        bind_email:"Привязка к почте",
        loading:"Загрузка...",
        helper:"Помощь",
        gift:"Набор",
        mine:"Мой",
        confirm:"Подтвердить",
        cancel:"Отмена",
        bind_email_tips:"Вы собираетесь привязать уч. запись к почте. Это позволит сохранить прогресс и восстановить пароль.",
        input_old_email:"Введите старый почтовый ящик.",
        input_old_verify_code:"Введите проверочный код старой почты.",
        input_new_verify_code:"Введите проверочный код новой почты.",
        input_validator_email:"Введите правильный адрес эл. почты.",
        input_bind_email:"Введите адрес почты для привязки.",
        verify_code:"Проверочный код",
        next:"Далее",
        bind_now:"Привязать",
        tips:"*Напоминание",
        email_tips:"Некоторые почтовые сервисы могут по ошибке принять письмо с проверочным кодом за нежелательное и отправить его в [Спам]. ",
        send_verify_code:"Отправить код",
        sending:"Отправляем...",
        send_success:"Отправили.",
        resend:"Отправить еще раз.",
        old_pwd:"Старый пароль",
        input_old_pwd:"Введите старый пароль.",
        new_pwd:"Новый пароль",
        input_new_pwd:"Введите новый пароль. В нем должно быть от 6 до 15 символов.",
        confirm_pwd:"Подтвердить пароль",
        input_confirm_pwd:"Введите новый пароль заново.",
        change_now:"Изменить сейчас",
        switch_account:"Сменить уч. запись.",
        playing:"Вы играете",
        search_placeholder:"Введите ключевое слово.",
        search:"Поиск",
        empty:"Нет данных",
        self_service:"Служба самопомощи",
        submit_feedback:"Отправить отзыв",
        online_service:"Онлайн поддержка",
        official_verification:"Официальная проверка",
        myservice:"Мой отзыв",
        questionTabs_1:"Горячие вопросы",
        questionTabs_2:"О пополнениях",
        questionTabs_3:"Об игре",
        questionTabs_4:"О входе",
        questionTabs_999:"Еще",
        question_detail:"Подробности",
        question_common:"ЧаВо",
        search_empty:"Нет результатов.",
        feedback_detail:"Об отзыве",
        contact_email:"Почта для связи",
        screenshot:"Снимок экрана",
        detailed_info:"Подробности",
        my_reply:"Мой ответ",
        kf_reply:"Ответ от поддержки /0/",
        input_feedback:"Пожалуйста, опишите проблему.",
        service_score:"Пожалуйста, оцените сервис.",
        score:"/0/",
        scored:"Спасибо за отзыв.",
        thank_feedback:"Спасибо за отзыв.",
        score_tips:"Выберите оценку.",
        feedback_processing:"Обрабатываем запрос. Ожидайте...",
        feedback_tips:"Если проблема осталась, не спешите сообщать о ней несколько раз подряд.",
        submit:"Отправить",
        processing:"Обработка",
        replied:"Ответ получен",
        completed:"Готово",
        uploading:"Загрузка...",
        upload_failed:"Не удалось загрузить",
        upload_tips:"Дождитесь загрузки изображения.",
        robot_name:"Бот",
        robot_name2:"Бот к вашим услугам.",
        welcom_msg:"Спасибо, что заглянули. Попробуйте отправить ключевое слово – и бот вам поможет.",
        change_batch:"Новый набор",
        forget_pwd:"Забыли пароль?",
        game_web:"Официальный сайт",
        contact_customer:"Специалист поддержки",
        ques_placeholder:"Расскажите о проблеме.",
        send:"Отпр.",
        connection:"Подключаемся к чату...",
        enter_question:"Сначала расскажите о проблеме.",
        are_you_asking:"Ваш вопрос: ",
        robot_not_found:"Простите, не понимаю. Попробуйте переформулировать запрос. Если хотите поговорить с живым человеком, коснитесь «Специалист поддержки».",
        customer_service:"Живая служба поддержки",
        feedback_0:"наполнение ",
        feedback_1:"Ошибки",
        feedback_2:"Жалоба",
        feedback_3:"Предложения",
        feedback_4:"номер счета",
        feedback_5:"Скриншот",
        feedback_server:"Сервер",
        feedback_rolename:"Имя персонажа",
        feedback_price:"Сумма заказа",
        feedback_order:"Номер заказа",
        feedback_report:"Жалоба",
        feedback_username:"Уч. запись",
        feedback_registerTime:"Дата регистрации:",
        feedback_registerDevice:"Устройство:",
        feedback_lastLoginTime:"Последний вход:",
        feedback_lastPayTime:"Когда пополняли счет в прошлом месяце?",
        feedback_lastPayPrice:"На сколько пополнили счет в прошлом месяце?",
        feedback_lastPayOrder:"Номер заказа на пополнение в прошлом месяце.",
        feedback_server_tips:"Введите название сервера.",
        feedback_rolename_tips:"Введите имя персонажа.",
        feedback_price_tips:"Введите сумму пополнения.",
        feedback_order_tips:"Введите номер заказа.",
        feedback_report_tips:"Введите имя персонажа, на которого хотите пожаловаться.",
        feedback_username_tips:"Введите название уч. записи, которую хотите восстановить.",
        feedback_registerTime_tips:"Введите дату регистрации уч. записи.",
        feedback_registerDevice_tips:"Введите модель устройства, на котором зарегистрировались.",
        feedback_lastLoginTime_tips:"Когда был последний вход?",
        feedback_lastPayTime_tips:"Когда вы пополняли счет в прошлом месяце?",
        feedback_lastPayPrice_tips:"На сколько вы пополняли счет в прошлом месяце?",
        feedback_lastPayOrder_tips:"Добавьте номер заказа в прошлом месяце.",
        order_helper:"Где найти номер заказа?",
        max_img:"До 3 изображений",
        contact:"Контактная информация",
        detailed_desc:"Подробности",
        myservice_count:"Обрабатываем",
        myservice_count2:"отзыв ",
        nomore:"Больше ничего",
        feedback_state:"Статус",
        feedback_type:"Тип",
        create_time:"Время создания",
        gift_title:"Игровые наборы",
        mygift_title:"Мои наборы",
        recommend:"Рекоменд. игра",
        popularity:"Популярн.",
        play:"Новинка",
        hot:"Хит",
        gift_empty:"Наборов нет. Следите за новостями.",
        input_contact_email:"Введите адрес эл. почты для связи.",
        same_password:"Пароли совпадают. Их не нужно менять.",
        success:"Все получилось",
        input_email:"Введите адрес эл. почты.",
        email:"Почта",
        forgot:"Восстановить пароль",
        forgot_from_email:"По электронной почте",
        forgot_from_service:"Через службу поддержки",
        reset_password:"Подтвердить и сбросить",
        unbind_email:"Чтобы разорвать связь, нужна привязка через эл. почту.",
        unbind_fb:"Отменив привязку, вы не сможете войти в уч. запись через аккаунт Facebook. Вы уверены?",
        unbind_google:"Отменив привязку, вы не сможете войти в уч. запись через аккаунт Google. Вы уверены?",
        unbind_twitter:"Отменив привязку, вы не сможете войти в уч. запись через аккаунт Twitter. Вы уверены?",
        tips_title:"Быстр.",
        copy_success:"Копия создана",
        copy_faild:"Не удалось копировать. Браузер не поддерживает копирование.",
        gift_rate_task:"Прежде чем получить набор, нужно оставить комментарий.",
        requesting:"Запрос отправлен",
        get_gift:"Получить набор",
        gift_rate:"Оставить комментарий",
        gift_rate2:"Оценить",
        gift_share:"Поделиться",
        gift_bind:"Привязать",
        gift_fill:"Заполнить",
        gift_ads:"Смотреть",
        gift_copy:"Скопировать подарочный код",
        gift_exchange:"Получить",
        gift_exchange_desc:"Бонусы – Код активации – Для активации введите подарочный код.",
        password_help:"Как узнать пароль уч. записи?",
        password_help_1:"1. Коснитесь аватара в левом верхнем углу.",
        password_help_2:"2. Коснитесь красной кнопки «Польз. центр» внизу экрана.",
        password_help_3:"3. Коснитесь «Сменить уч. запись».",
        password_help_4:"4. Коснитесь значка глаза, чтобы узнать пароль.",
        payhelp_title:"Выберите платформу платежа",
        payhelp_google:"Query using Google play",
        payhelp_google1:"1. Open google play and click on the left menu bar",
        payhelp_google2:"2. Click on My Account",
        payhelp_google3:"3. Click on the order record",
        payhelp_google4:"4. Click the corresponding order to view the receipt",
        payhelp_wechat:"Query using wechat",
        payhelp_wechat1:"1. Click to open wechat",
        payhelp_wechat2:"2. Click the [Me] option",
        payhelp_wechat3:"3. Click to pay - wallet",
        payhelp_wechat4:"4. Click Bill",
        payhelp_wechat5:"5. Click the corresponding order to view the order receipt",
        payhelp_paypal:"Inquiry using paypal",
        payhelp_paypal1:"1. Log in to paypal account",
        payhelp_paypal2:"2. Click on the transaction record",
        payhelp_paypal3:"3. Click to view the details",
        payhelp_paypal4:"4. Click on the corresponding order to view the details",
        payhelp_alipay:"Query with Alipay",
        payhelp_alipay1:"1. Click to open Alipay",
        payhelp_alipay2:"2. Click the [My] option",
        payhelp_alipay3:"3. Click Bill",
        payhelp_alipay4:"4. Click on the corresponding order to view the receipt",
        bind_mobile:"綁定手機",
        bind_mobile_tips:"您正在為帳號綁定手機號，綁定手機號能有效保障帳號安全，可通過手機號找回密碼。",
        input_old_mobile:"請輸入舊手機號",
        input_validator_mobile:"請正確輸入手機號",
        input_bind_mobile:"請輸入您要綁定的手機號",
        mobile_tips:"部分手機號，資訊有可能被誤判為垃圾資訊，請先至【垃圾信箱】查看獲取驗證碼。",
        contact_mobile:"聯繫手機號",
        input_contact_mobile:"請輸入聯繫手機號",
        input_mobile:"請輸入手機號",
        mobile:"手機號",
        unbind_mobile:"請先綁定郵箱後再進行解綁",
        input_old_verify_mobile:"請輸入舊手機號驗證碼",
        input_new_verify_mobile:"請輸入手機號驗證碼",
        pay_title:"Всего",
        pay_change_currency:"Сменить валюту",
        pay_tips:"(Окончательная сумма платежа будет зависеть от способа оплаты и обменного курса)",
        pay_select_mode:"Выберите способ оплаты",
        wechat:"WeChat Pay",
        alipay:"Alipay",
        pay_paying:"Платеж выполняется...",
        pay_fail:"Сменить валюту",
        pay_return_tips:"если платеж завершен, закройте страницу, а то Перезагрузите её. ",
        pay_discount:"(льгота/0/%)",
        pay_late_mode:"Недавно выбранный способ оплаты",
        pay_other_mode:"Другие",
        pay_more:"Больше методов ",
        pay_point:"/0/ очк., чтобы получить /1/ очк. бесплатно",
        points:"Пополните, чтобы получить очки",
        current_points:"Текущ. очки:",
        pay:"Пополн.",
        pwd:"Пароль",
        pay_setting:"Установить платежный пароль",
        select_amount:"Пожалуйста, выберите кол-во очков для пополнения",
        forget:"Восстановить платежный пароль",
        forget_pay:"Удалить платежный пароль",
        input_pwd:"Пожалуйста, введите платежный пароль из 6 цифр",
        different:"Пароли не совпадают",
        setting_tips:"Вы устанавливаете платежный пароль. Пожалуйста, никому не сообщайте свой пароль",
        remain:"Осталось",
        add:"Пополните, чтобы получить очки",
        pay_mode:"Способ оплаты",
        change_payPwd_tips:"Вы меняете платежный пароль. Пожалуйста, никому не сообщайте свой новый пароль",
        unsetting:"Платежный пароль не установлен",
        email_unbound:"Почта не привязана",
        confirm_pay_pwd:"Подтвердите платежный пароль",
        input_confirm_pay_pwd:"Пожалуйста, введите платежный пароль еще раз",
        payPassword_error:"Неверный платежный пароль",
        balance:"Недостаточно средств",
        gamePoints:"Очки",
        pay_complete:"Платеж проведен",
        pay_new_tips:"Не закрывайте это окно до завершения пополнения",
        pay_new_message:"Пожалуйста, завершите пополнение на новой странице",
        pay_cancel:"Отменить платеж",
        back:"Назад",
        fee_name:"Реферальная программа",
        fee_text1:"Сотрудничество с HandZone Games",
        fee_text2:"Участники реферальной программы HandZone Games получают уникальную ссылку. Вы получите вознаграждение за каждого участника, который воспользуется вашей ссылкой, скачает и установит игру, а также пополнит свой счет.",
        fee_text3:"Чтобы стать участником реферальной программы HandZone Games, вы должны отвечать определенным требованиям.",
        fee_price:"Сумма пополнения равняется:",
        fee_text4:"Когда вы будете отвечать нашим требованиям, вы сможете участвовать в реферальной программе. За каждого игрока, который установит игру по вашей ссылке и пополнит счет в игре, вы получите выплату в размере 10%-70% от зачисленной этим игроком суммы.",
        fee_text5:"Поздравляем! Теперь вы участвуете в реферальной программе",
        fee_text6:"Ваша ссылка для приглашения игроков:",
        fee_copy:"Скопировать ссылку",
        fee_text7:"Текущие выплаты по уровням:",
        fee_text8:"Пополнение для A, B, C соответственно /0/",
        fee_text9:"Вы получите:",
        fee_text10:"Для А нет ограничений",
        fee_text11:"Сейчас у вас: /0/ реф. 1 ур., /1/ реф. 2 ур., /2/ реф. 3 ур.",
        fee_price2:"Доступная сумма:",
        fee_start:"Начать продвижение",
        fee_submit:"Вывести",
        fee_tips1:"Не выполнены требования к выводу средств",
        contact_kf:"Связаться со службой поддержки",
        fee_tips2:"Свяжитесь со службой поддержки для вывода средств",
        account_destroy:"账号注销",
        account_destroy_now:"申请注销账号",
        account_destroy_cancel:"取消注销账号",
        account_destroy_time:"注销账号剩余时间：",
        account_destroy_time2:"/0/ 天后",
        account_destroy_cancel_tips:"您已取消注销账号申请",
        account_destroy_read_tips:"我已经看过并同意",
        account_destroy_read_tips2:"《账号注销须知》",
        account_destroy_content1:"● 1. 注销成功后该账号将<a>无法在我司的账号平台及旗下任一款产品上进行登录</a>且<a>账号以及数据不可恢复</a>；",
        account_destroy_content2:"● 2. 账号注销需要<a>收集您的个人敏感信息（包括邮箱验证码）</a>用于核验身份，账号注销后，我司将会<a>删除上述信息或匿名化处理</a>；",
        account_destroy_content3:"● 3. 账号成功注销后，该账号下的所有内容、信息、记录将会被<a>删除或匿名化处理</a>，您也无法再检索、访问、获取、继续使用和找回，也无权要求我司找回（但法律法规另有规定或监管部门另有要求的除外）；",
        account_destroy_content4:"● 4. 账号注销之后，对应的账号交易平台将无法登录使用，<a>您申请账号注销的行为即表明您自愿放弃该账号下的利益</a>，<a>在注销前，请再三确认平台上的交易和提现申请已完成</a>，避免账号注销后因无法登录而造成不必要损失；",
        account_destroy_content5:"● 5. 账号注销后，<a>我司有权不再为您提供任何该账户有关的服务</a>，您与我司曾签署过的相关用户协议、其他权利义务性文件等相应终止（但我司与您之间已约定继续生效的或法律法规另有规定的除外）；",
        account_destroy_content6:"● 6. 因注销账户所带来的不便或不利后果，由您自行承担，与我司无关；",
        account_destroy_content7:"● 7. 如果账号注销期间存在权属纠纷，我司有权终止注销申请；",
        account_destroy_content8:"● 8. 账号注销有7天的确认期，期间您可以再次来此界面取消注销，确认期过后将无法再取消注销；",
        unbind_account_destroy:"请先绑定邮箱后再进行注销账号",
        pay_go:"Пополн.",
        either_or:" or ",
        my_info_avatar:"аватар",
        avatar:"аватар",
        look_avatar:"Посмотреть аватар",
        change_avatar:"Сменить аватар",
        avatar_reviewing:"на рассмотрении",
        avatar_review_fail:"Ошибка аудита",
        zbm0006:"уважаемые игроки, вы загружаете свои собственные Аватары, связанные с {0}, просмотр не закрыт",
        shhz0001:"политически чувствительные элементы",
         shhz0002:"порнография",
          shhz0003:"Содержание игры",
        shhz0004:"Содержание наркотиков",
        shhz0005:"религиозная чувствительность",
        shhz0006:"адрес сайта",
        shhz0007:"иметь рекламное содержание",
        shhz0008:"неприличный содержание",
        account:"имя пользователя",
        vip_kf:"VIP Service",
        super_vip:"Супер-VIP",
        super_vip_kf:"Эксклюзивные услуги карты супер-VIP",
        super_vip_be:"Станьте супер-VIP<br/>Насладитесь эксклюзивными услугами",
        super_vip_info1:"Эксклюзивные услуги карты супер-VIP",
        super_vip_info2:"(Только 18+)",
        super_vip_info3:"1. Эксклюзивный канал<br/>Прямой эксклюзивный сервис. Ваши предложения попадут напрямую к разработчикам!<br/>2. Эксклюзивный приоритет<br/>Получите приоритетный доступ к самой важной игровой информации<br/>3. Эксклюзивный набор<br/>Станьте супер-VIP и получите супер большой набор!<br/>4. Эксклюзивные события<br/>Эксклюзивные редкие события и бонусы!",
        super_vip_gain1:"Как стать супер-VIP",
        super_vip_gain2:"Способ 1:  Пополнить /0/за все время",
        super_vip_get:"None",
        super_vip_gift:"Эксклюзивный набор супер-VIP",
        super_vip_gift_tips:"Получите набор при подтверждении статуса. Если остались вопросы – напишите нам!",
        super_vip_gift_1:"Игровой титул",
        super_vip_gift_2:"Редкий декор",
        super_vip_gift_3:"Питомец",
        super_vip_gift_4:"Персонал. набор",
        super_vip_gift_5:"Редкий предмет",
        super_vip_gift_wechat:"WeChat:",
        feedback_reward_1:"Награда для службы поддержки",
        feedback_reward_2:"Награда",
        feedback_reward_3:"Эта награда — ваш добровольный подарок нашей службе поддержки. Вся сумма будет перечислена ответственному сотруднику службы поддержки",
        wechat_native_1:"Сумма:",
        wechat_native_2:"Откройте WeChat и отсканируйте QR-код, чтобы завершить операцию",
        wechat_native_3:"Платеж проведен",
        login_usern:"Войти",
        register_username:"Регистрация",
        quick_register:"Быстрая рег.",
        enterNow:"Войти",
        btn_register:"Зарегистрироваться",
        agreed1:"Принять",
        btn_have_usern:"Уже есть уч. запись",
        to_login:"Вернуться ко входу",
        login_success:"Вход выполнен",
        agree_protocol:"Примите Условия использования",
        UserLogin:"Войти",
        UserLogin_text1:"Введите имя пользователя",
        UserLogin_text2:"Введите пароль",
        UserLogin_text4:"Вход в систему",
        UserLogin_text5:"Регистрация",
        UserLogin_text6:"Регистрация успешна",
        online_earning:"Заработок онлайн",
        online_earning_center:"Центр заработка",
        online_earning_sigtiaoin:"Испытание входа",
        online_earning_text1:"Vervollständigen Sie Missionen<br>Für Geld",
        online_earning_text2:"Посмотрите видео один раз, чтобы войти в систему.<br/>Получайте награды в общей сложности на 7 дней.",
        online_earning_text3:"Обменимые награды",
        online_earning_text4:"Смотрите и получайте награды",
        online_earning_text5:"Осталось: /0/",
        online_earning_text6:"Недостаточно попыток",
        online_earning_text7:"Не получено",
        exchange:"Получить: /0/",
        exchange_center:"Центр обмена",
        exchange_prompt:"Советы по обмену",
        exchange_confirm:"Подтвердить",
        exchange_consume:"Цена: /0/",
        exchange_balance:"Баланс",
        exchange_detail:"Подробности",
        exchange_balance_detail:"О балансе",
        exchange_reward:"Обменять на награды",
        exchange_text1:"Очков: /0/",
        exchange_text2:"$/0/",
        exchange_text3:"Очки",
        exchange_text4:"Денежная выплата",
        exchange_text5:"Лимит обмена: /0///1/",
        exchange_text6:"Ежедн. лимит：/0///1/",
        exchange_text7:"Введите код приглашения, чтобы привязать",
        exchange_text8:"Введите учетную запись Paypal",
        exchange_text9:"Привязка пригласившего",
        exchange_text10:"Подтвердить",
        exchange_text11:"Обмен выполнен. Результаты можно увидеть в пользовательском центре",
        exchange_text12:"Недостаточно средств",
        exchange_text13:"Показать только последние 100 записей",
        exchange_rule:"Правила",
        exchange_rule2:"Правила обмена",
        exchange_rule2_html:"1. Для активации обмена на учетной записи требуется привязать почту.<br/>2. Во время обмена проверьте правильность введенной информации. Мы не несем ответственности за потери, возникшие в связи с допущенными вами ошибками при привязке или обмене.<br />3. Если обмен совершить не удалось, пришлите нам код ошибки. Если проблему решить не удалось, свяжитесь со службой поддержки.<br />4. Приступая к обмену, вы выражаете свое согласие с правилами. Handzone оставляет за собой право окончательной интерпретации правил обмена.",
        invite_Friends:"Пригласить друзей",
        invite_binding:"Привязка пригласившего",
        new_envelope:"Набор новичка",
        signReward:"Завершить регистрацию",
        completeTask:"Завершите миссии: /0/",
        completeExchange:"Обмен: /0/, цена",
        language:"язык",
        country:"страна/регион",
        select_pay_mode:"Выберите правильный местный платеж",
        exchange_text14:"Еще не открыт",
        hint_func: "Функция пока недоступна, следите за обновлениями",
        game_hub:"Игровой центр",
        not_login:"Не вошел",
        unlock:"Войдите, чтобы разблокировать более интересный контент",
        for_the_account:"Вы создаете учетную запись",
        for_the_account_1:"Привязка электронной почты может эффективно обеспечить безопасность вашей учетной записи. Вы можете получить свой пароль по электронной почте.",
        account_length:"Длина аккаунта 6-18 символов.",
        modify_successfully:"Успешно изменено",
        binding_successful:"Привязка прошла успешно",
        start_game:"начинать",
        game_down:"скачать",
        new_travel:"новый тур",
        please_acc_code:"Пожалуйста, введите имя пользователя",
        please_paw_code:"Пожалуйста введите пароль",
        agree:"соглашаться",
        install:"настраивать",
        logout:"выход",
        is_logout_account:"Стоит ли выходить из учетной записи",
        top_free:"Популярное бесплатно",
        top_jackpot:" Популярный бестселлер",
        peace:"и",
        priv_policy:"политика конфиденциальности",
        tally:"Точки",
        give_away:"Включает подарки",
        voucher:"Ваучеры",
        waiting_used:"Быть использованным"
    }
}
