module.exports = {
    LOCAL:{
        zh_CN:"简体中文",
        zh_TW:"繁体中文",
        en_US:"English",
        ko_KR:"한국어",
        ru_RU:"Русский",
        de_DE:"Deutsch",
        ja_JP:"日本",
        pt_BR:"Português",
        tr_TR:"Türkiye",
        vi_VN:"Tiếng Việt",
        es_LA:"Español Americano",
        ar_SA:"عربي",
    },
    SERVER:{
        server_wz_limit_count:"回数は上限に達しています",
        server_wz_task_cd:"CD時間中",
        server_wz_lack_money:"残高不足です",
        server_result_success:"操作完了",
        server_result_fail:"実行失敗",
    },
    LAN:{
        change_pwd:"パスワード変更",
        change_pwd_tips:"アカウントのパスワード変更中です。新パスワードを適切に保管してください",
        unbound:"(未連携)",
        default_language:"デフォルト言語",
        user_service:"ご利用規約",
        bind_email:"メールアドレスを連携する",
        loading:"読み込み中...",
        helper:"ヘルプ",
        gift:"パック",
        mine:"私の",
        confirm:"確 定",
        cancel:"取消",
        bind_email_tips:"アカウントとメールアドレスを連携中です。連携すると、アカウントのセキュリティを保証でき、メールでパスワードを取り戻せます。",
        input_old_email:"元のメールアドレスを入力してください",
        input_old_verify_code:"元のメールアドレスの認証コードを入力してください",
        input_new_verify_code:"メールアドレス認証コードを入力してください",
        input_validator_email:"メールアドレスを正しく入力してください",
        input_bind_email:"連携するメールアドレスを入力してください",
        verify_code:"認証コード",
        next:"次",
        bind_now:"即連携する",
        tips:"*ご注意",
        email_tips:"一部の無料メールは迷惑メールボックスに振り分けられる可能性があります。まずは【迷惑メール箱】で認証コードメールがないか確認してみてください。",
        send_verify_code:"認証コードを送信する",
        sending:"送信中…",
        send_success:"送信成功",
        resend:"再送信する",
        old_pwd:"元パスワード",
        input_old_pwd:"元パスワードを入力してください",
        new_pwd:"新パスワード",
        input_new_pwd:"6-15文字の新パスワードを入力してください",
        confirm_pwd:"新パスワードを確認",
        input_confirm_pwd:"新パスワードを再入力してください",
        change_now:"今すぐ変更",
        switch_account:"アカウント変更",
        playing:"中",
        search_placeholder:"キーワードで検索してください",
        search:"検索",
        empty:"データなし",
        self_service:"セルフサービス",
        submit_feedback:"フィードバック提出",
        online_service:"オンラインCS",
        official_verification:"公式認証",
        myservice:"私のフィードバック",
        questionTabs_1:"ホット問題",
        questionTabs_2:"課金について",
        questionTabs_3:"ゲームについて",
        questionTabs_4:"ログインについて",
        questionTabs_999:"もっと",
        question_detail:"問題の詳細",
        question_common:"よくあるご質問",
        search_empty:"検索内容なし",
        feedback_detail:"フィードバック詳細",
        contact_email:"メールアドレス",
        screenshot:"スクリーンショット",
        detailed_info:"情報詳細",
        my_reply:"私の返信",
        kf_reply:"CS/0/の返信",
        input_feedback:"質問詳細を入力してください",
        service_score:"今回のサービスを評価してください",
        score:"/0/点",
        scored:"評価済みです。フィードバックありがとうございます",
        thank_feedback:"評価済みです。フィードバックありがとうございます",
        score_tips:"評価を選んでください",
        feedback_processing:"フィードバック処理中、しばらくお待ちください",
        feedback_tips:"問題がまだ解決されていない場合、フィードバックを繰り返し提出しないで、引き続きこの質問に返信してください",
        submit:"提　出",
        processing:"処理中",
        replied:"返信済み",
        completed:"クリア済み",
        uploading:"アップロード中…",
        upload_failed:"アップロード失敗",
        upload_tips:"写真のアップロード完了をお待ちください",
        robot_name:"ロボットアシスタント",
        robot_name2:"ご利用頂き、ありがとうございます。アシスタントです",
        welcom_msg:"ご来訪ありがとうございます。問題のキーワードを送信することで質問できますよ。アシスタントはできるだけ解決に努めます",
        change_batch:"相手変更",
        forget_pwd:"パスワードを忘れた？",
        game_web:"ゲーム公式サイト",
        contact_customer:"オペレーターに転送",
        ques_placeholder:"質問を入力してください",
        send:"送　信",
        connection:"会話接続中…",
        enter_question:"まずは質問を入力してください",
        are_you_asking:"以下の質問をしていですか？",
        robot_not_found:"すみません。分かりませんでした。問題を解決するために、質問の言い方を変えてみましょう。オペレーターに直接お聞きしたい場合、オペレーターに転送というボタンをタップしてください",
        customer_service:"オペレーター",
        feedback_0:"課金が反映されなかった",
        feedback_1:"バグについて",
        feedback_2:"キャラ通報",
        feedback_3:"フィードバック",
        feedback_4:"アカウントを忘れた",
        feedback_5:"スクリーンショット",
        feedback_server:"所属サバ",
        feedback_rolename:"キャラ名",
        feedback_price:"注文金額",
        feedback_order:"店の注文番号",
        feedback_report:"キャラ通報",
        feedback_username:"アカウント",
        feedback_registerTime:"アカウント登録時間",
        feedback_registerDevice:"登録携帯の型番",
        feedback_lastLoginTime:"最終ログイン日時",
        feedback_lastPayTime:"最近1ヶ月の課金時間",
        feedback_lastPayPrice:"最近1ヶ月の課金額",
        feedback_lastPayOrder:"最近1ヶ月の課金注文番号",
        feedback_server_tips:"所属サバを入力してください",
        feedback_rolename_tips:"キャラクターの名前を入力してください",
        feedback_price_tips:"注文金額を入力してください",
        feedback_order_tips:"店の注文番号の入力してください",
        feedback_report_tips:"通報するキャラを入力してください",
        feedback_username_tips:"取り戻したいアカウントを入力してください",
        feedback_registerTime_tips:"アカウントの登録時間を入力してください",
        feedback_registerDevice_tips:"登録携帯の型番を入力してください",
        feedback_lastLoginTime_tips:"前回のログイン時間を入力してください",
        feedback_lastPayTime_tips:"最近1ヶ月の課金時間を入力してください",
        feedback_lastPayPrice_tips:"最近1ヶ月の課金額を入力してください",
        feedback_lastPayOrder_tips:"最近1ヶ月の課金注文番号を入力してください",
        order_helper:"注文番号の確認方法は？",
        max_img:"写真、3枚までアップロード可能",
        contact:"連絡先",
        detailed_desc:"詳細説明",
        myservice_count:"現在",
        myservice_count2:"件のフィードバック処理中",
        nomore:"これ以上ありません",
        feedback_state:"フィードバック状態",
        feedback_type:"問題の種類",
        create_time:"作成日時",
        gift_title:"ゲームパック",
        mygift_title:"私のパック",
        recommend:"推奨ゲーム",
        popularity:"人　気",
        play:"プレイ開始",
        hot:"大人気",
        gift_empty:"パックなし、乞うご期待",
        input_contact_email:"メールアドレスを入力してください",
        same_password:"新パスワードは元パスワードと同じで、変更する必要がありません",
        success:"操作完了成功",
        input_email:"メールアドレスを入力してください",
        email:"メール",
        forgot:"パスワード再設定",
        forgot_from_email:"連携メールアドレスで取り戻します",
        forgot_from_service:"CSに連絡して取り戻します",
        reset_password:"パスワードをリセットします",
        unbind_email:"メールアドレスと連携してから連携を解除してください",
        unbind_fb:"連携を解除するとFacebookでこのアカウントにログインできなくなります。解除しますか？",
        unbind_google:"連携を解除するとGoogleでこのアカウントにログインできなくなります。解除しますか？",
        unbind_twitter:"連携を解除するとTwitterでこのアカウントにログインできなくなります。解除しますか？",
        tips_title:"提　示",
        copy_success:"コピー成功",
        copy_faild:"コピー失敗、このブラウザーはコピーできません",
        gift_rate_task:"正しくコメントを完了しないと本パックをもらえませんよ",
        requesting:"請求中",
        get_gift:"パック受取",
        gift_rate:"コメントする",
        gift_rate2:"評価",
        gift_share:"共有へ",
        gift_bind:"連携へ",
        gift_fill:"記入へ",
        gift_ads:"すぐ見る",
        gift_copy:"パックコードコピー",
        gift_exchange:"交換方法",
        gift_exchange_desc:"特典-起動コード-パックコードを入力して起動",
        password_help:"自分のアカウントのパスワードを確認する方法は？",
        password_help_1:"1.ゲーム内の左上のアイコンをタップする",
        password_help_2:"2.ボトムの赤いボタン「CSセンタ」をタップする",
        password_help_3:"3.「アカウント切替」をタップする",
        password_help_4:"4.写真の中の眼のボタンをタップしてアカウントのパスワードを確認できる。",
        payhelp_title:"支払い手段を選んでください",
        payhelp_google:"google playで確認する",
        payhelp_google1:"1.google playを開いて左側のメニューをタップする",
        payhelp_google2:"2.個人アカウントをタップする",
        payhelp_google3:"3.注文履歴をタップする",
        payhelp_google4:"4.該当注文をタップしてレシートを確認する",
        payhelp_wechat:"Wechatで確認する",
        payhelp_wechat1:"1.wechatを開く",
        payhelp_wechat2:"2.【私】のオプションをタップする",
        payhelp_wechat3:"3.支払い-ウォレットをタップする",
        payhelp_wechat4:"4.請求書をタップする",
        payhelp_wechat5:"5.該当注文をタップしてレシートを確認する",
        payhelp_paypal:"Paypalで確認する",
        payhelp_paypal1:"1.paypalアカウントにログインする",
        payhelp_paypal2:"2.取引履歴をタップする",
        payhelp_paypal3:"3.詳細確認をタップする",
        payhelp_paypal4:"4.該当注文をタップして詳細を確認する",
        payhelp_alipay:"Alipayで確認する",
        payhelp_alipay1:"1.Alipayを開く",
        payhelp_alipay2:"2.【私の】オプションをタップする",
        payhelp_alipay3:"3.請求書をタップする",
        payhelp_alipay4:"4.この注文をタップしてレシートを確認する",
        bind_mobile:"携帯番号と連携",
        bind_mobile_tips:"アカウントを携帯番号に連携しています。",
        input_old_mobile:"元の携帯番号を入力してください",
        input_validator_mobile:"携帯番号を正しく入力してください",
        input_bind_mobile:"連携する携帯番号を入力してください",
        mobile_tips:"一部の携帯番号は、メッセージが迷惑メッセージに振り分けられる可能性があります。まずは【迷惑メッセージ】に認証コードがないかを確認してみてください。",
        contact_mobile:"携帯番号",
        input_contact_mobile:"携帯番号を入力してください",
        input_mobile:"携帯番号を入力してください",
        mobile:"携帯番号",
        unbind_mobile:"メールアドレスと連携してから連携を解除してください",
        input_old_verify_mobile:"元の携帯番号の認証コードを入力してください",
        input_new_verify_mobile:"携帯番号の認証コードを入力してください",
        pay_title:"今回の消費は約",
        pay_change_currency:"通貨切替",
        pay_tips:"（最終的な支払い金額は、支払い方法や為替レートによって異なります）",
        pay_select_mode:"支払い方法をお選びください",
        wechat:"WeChat Pay",
        alipay:"Alipayで支払う",
        pay_paying:"支払いリクエスト中...",
        pay_fail:"課金失敗、後ほどお試しください",
        pay_return_tips:"支払いが完了した場合、この画面を閉じてください。完了しなかった場合、再支払いをリクエストしてください。",
        pay_discount:"(割引き/0/%)",
        pay_late_mode:"最近選んだ支払い方法",
        pay_other_mode:"その他の支払い方法",
        pay_more:"支払い方法をもっと見る",
        pay_point:"/0/pt贈呈/1/pt",
        points:"ゲームpt課金",
        current_points:"現在ゲームpt：",
        pay:"課金",
        pwd:"パスワード",
        pay_setting:"支払いパスワード設定",
        select_amount:"課金するゲームptを選んでください",
        forget:"支払いパスワードを取り戻す",
        forget_pay:"支払いパスワードを忘れた",
        input_pwd:"6ケタの支払いパスワードを入力してください",
        different:"パスワードが一致しません",
        setting_tips:"アカウントの支払いパスワードを設定しています。支払いパスワードを適切に保管してください",
        remain:"残り",
        add:"ゲームpt課金",
        pay_mode:"支払方法",
        change_payPwd_tips:"アカウントの支払いパスワードを変更しています。新支払いパスワードを適切に保管してください",
        unsetting:"支払いパスワード未設定",
        email_unbound:"メールアドレスは連携していません",
        confirm_pay_pwd:"支払いパスワードを確認してください",
        input_confirm_pay_pwd:"支払いパスワードを再入力してください",
        payPassword_error:"支払いパスワードが間違っています",
        balance:"残高不足",
        gamePoints:"ゲームpt",
        pay_complete:"支払いが完了しました",
        pay_new_tips:"課金が完了する前にこの画面を閉じないでください",
        pay_new_message:"新しく開いた画面で課金を完了してください",
        pay_cancel:"支払い取消",
        back:"戻る",
        fee_name:"プロモーション連携による利益獲得サービス",
        fee_text1:"HandZone Gamesと共に進もう",
        fee_text2:"HandZone Games のパートナーになると、専用プロモーションURLを取得できます。このURLからゲームをダウンロードしてインストールしたユーザーが課金すると、あなたも課金配当がもらえます！",
        fee_text3:"HandZone Gamesのプロモーション連携による利益獲得サービスをご利用したい場合、以下の条件を満たす必要があります。",
        fee_price:"同額課金額：",
        fee_text4:"条件を満たすと、プロモーション連携による利益獲得サービスが自動的に開放されます。開放後、自分のプロモーションを通じて獲得した新規プレイヤーの課金額の10%~70% (プロモーションランクによって、割合も異なる) 分の課金配当がもらえます。",
        fee_text5:"プロモーション連携による利益獲得サービスが解放されました。おめでとうございます！",
        fee_text6:"あなたのプロモーションURL：",
        fee_copy:"リンクコピー",
        fee_text7:"現段階のプロモーションランクの配当イメージ図は以下通りです。",
        fee_text8:"A.B.Cのそれぞれの課金額/0/",
        fee_text9:"獲得賞品：",
        fee_text10:"Aは新規プレイヤーを無限に獲得できます",
        fee_text11:"現段階あなたのクラス1プロモーション人員は/0/人、クラス2は/1/人、クラス3は/2/人です",
        fee_price2:"引き出せる金額:",
        fee_start:"プロモーション開始",
        fee_submit:"引出し",
        fee_tips1:"引出し条件未達成",
        contact_kf:"サポートセンター",
        fee_tips2:"引出しはCSに連絡してください",
        account_destroy:"アカウント削除",
        account_destroy_now:"アカウント削除を申請する",
        account_destroy_cancel:"アカウント削除を取消す",
        account_destroy_time:"アカウント削除の残り時間：",
        account_destroy_time2:"/0/ 日後",
        account_destroy_cancel_tips:"アカウント削除申請を取消しました",
        account_destroy_read_tips:"内容をよく読み、同意します",
        account_destroy_read_tips2:"『アカウント削除の注意事項』",
        account_destroy_content1:"● 1. 削除が完了すると、このアカウントは <a>当社のアカウントプラットフォームおよびその製品にログインできなくなります。</a>また<a>アカウントとデータは復元できなくなります</a>。",
        account_destroy_content2:"● 2. アカウントを削除する際、身分確認のため、<a>ご本人の機密情報 (メール認証コードを含む)</a> を収集します。アカウント削除後、当社は<a>上記の情報を削除または匿名化します</a>。",
        account_destroy_content3:"● 3. アカウントの削除が完了すると、このアカウントのすべてのコンテンツ、情報、履歴は<a>削除または匿名化</a>され、お客様は検索、アクセス、取得、使用、復旧できなくなり、当社に復旧を求める権利もありません（法律に別段の定めがある場合、または監督管理部門が別途要求する場合を除く）。",
        account_destroy_content4:"● 4. アカウント削除後、該当アカウント取引プラットフォームにログインして利用することができなくなります。<a>一旦アカウント削除申請を提出すると。自発的にアカウント下の利益を放棄したと見なされます</a>。ログインできないことによる不必要な損失を避けるために、<a>アカウントを削除する前に、プラットフォームでの取引および引出し申請が完了したことをご確認ください</a>。",
        account_destroy_content5:"● 5. アカウント削除した後、<a>当社はアカウントに関連サービスを提供しない権利を有します</a>。弊社と締結していた関連するご利用規約およびその他の権利と義務に関する文書は終了となります（当社とお客様が持続有効を約束した場合、または法律に別途の定めがある場合を除く）。",
        account_destroy_content6:"● 6. アカウント削除による不都合や不利な結果はお客様の自己責任となり、当社とは関係ありません。",
        account_destroy_content7:"● 7. アカウント削除期間中に所有権紛争が発生した場合、当社は削除申請を終了する権利を有します。",
        account_destroy_content8:"● 8. アカウント削除は7日間の確認期間があり、この期間中はこの画面で削除を取り消すことができます。確認期間を過ぎると、削除を取り消せなくなります。",
        unbind_account_destroy:"メールアドレスと連携してからアカウントを削除してください",
        pay_go:"充電しに行く",
        either_or:"また",
        my_info_avatar:"アバター",
        avatar:"アバター",
        look_avatar:"アバターを見る",
        change_avatar:"アバターを変更",
        avatar_reviewing:"審査中",
        avatar_review_fail:"監査の失敗",
        zbm0006:"アップロードしたカスタムアイコンが{0}の疑いがあるため、審査を通過できませんでした",
        shhz0001:"不適切な政治的内容",
         shhz0002:"ポルノ内容",
          shhz0003:"賭博内容",
        shhz0004:"麻薬内容",
        shhz0005:"不適切な宗教的内容",
        shhz0006:"他のurlやサイトのアドレスが入っている",
        shhz0007:"広告内容が入っている",
        shhz0008:"猥褻、低俗な内容",
        account:"ユーザー名",
        vip_kf:"VIP サービス",
        super_vip:"プレミア会員",
        super_vip_kf:"プレミア会員カード専用サービス",
        super_vip_be:"プレミア会員になると<br/>専用CSを利用可能",
        super_vip_info1:"プレミア会員カード専用サービス",
        super_vip_info2:"（成年プレイヤーのみ限定）",
        super_vip_info3:"1、専用ホットライン<br/>あなただけのサービス、ご意見ご感想をダイレクトに報告可能！<br/>2、優先的に<br/>ゲーム資料をゲット可能<br/>3.限定パック<br/>プレミア会員になると、特大パックをゲット可能！<br/>4、限定イベ<br/>限定の不定期イベと特典！",
        super_vip_gain1:"プレミア会員への昇格資格の取得方法",
        super_vip_gain2:"方法1：履歴累計課金/0/",
        super_vip_get:"None",
        super_vip_gift:"VIP専用パック",
        super_vip_gift_tips:"認証成功後、パックを獲得可能。今すぐCSへ連絡しよう！",
        super_vip_gift_1:"ゲーム称号",
        super_vip_gift_2:"レア装飾",
        super_vip_gift_3:"ペット",
        super_vip_gift_4:"カスタムパック",
        super_vip_gift_5:"レア道具",
        super_vip_gift_wechat:"WeChat：",
        feedback_reward_1:"CSに投げ銭",
        feedback_reward_2:"投げ銭",
        feedback_reward_3:"投げ銭は自らCSへの贈与であり、全額CSに贈られます",
        wechat_native_1:"支払い金額：",
        wechat_native_2:"WeChatを開き、スキャンして支払いを完了させましょう",
        wechat_native_3:"支払い完了",
        login_usern:"ログインアカウント",
        register_username:"新規登録",
        quick_register:"快速登録",
        enterNow:"今すぐログイン",
        btn_register:"すぐに登録",
        agreed1:"同意します",
        btn_have_usern:"既にアカウントをお持ちの方",
        to_login:"ログインへ",
        login_success:"ログインできました",
        agree_protocol:"ゲームご利用規約に同意してください",
        UserLogin:"ログイン",
        UserLogin_text1:"ユーザー名を入力してください",
        UserLogin_text2:"パスワードを入力してください",
        UserLogin_text4:"ログイン中",
        UserLogin_text5:"登録中",
        UserLogin_text6:"新規登録成功",
        online_earning:"ネット稼ぎ",
        online_earning_center:"ネット稼ぎセンター",
        online_earning_sigtiaoin:"ログイン挑戦",
        online_earning_text1:"任務完了で<br>現金稼ぎ",
        online_earning_text2:"ビデオを 1 回視聴してログインしてください<br/> 合計 7 日間ログインして豪華な特典を獲得してください",
        online_earning_text3:"報酬の引き換えに使用できます",
        online_earning_text4:"動画を見るだけで、報酬をゲット",
        online_earning_text5:"残り/0/回",
        online_earning_text6:"回数不足",
        online_earning_text7:"未受領",
        exchange:"交換/0/",
        exchange_center:"交換所",
        exchange_prompt:"交換提示",
        exchange_confirm:"交換の確認",
        exchange_consume:"消費:/0/",
        exchange_balance:"残高",
        exchange_detail:"詳細",
        exchange_balance_detail:"残高詳細",
        exchange_reward:"報酬交換",
        exchange_text1:"/0/Pt",
        exchange_text2:"/0/円",
        exchange_text3:"ゲームpt",
        exchange_text4:"現金褒賞",
        exchange_text5:"永久交換制限：/0///1/",
        exchange_text6:"毎日交換制限：/0///1/",
        exchange_text7:"招待コードを入力し、連携してください",
        exchange_text8:"PAYPALアカウントを入力してください",
        exchange_text9:"招待者連携",
        exchange_text10:"連携確認",
        exchange_text11:"交換成功！CSセンタで結果を確認できます",
        exchange_text12:"数量不足",
        exchange_text13:"最近100件の履歴のみ表示",
        exchange_rule:"ルール",
        exchange_rule2:"交換ルール",
        exchange_rule2_html:"1、交換機能を使用するには、先にアカウントをメールアドレスと連携してください。<br/>2、交換する場合は、正しい情報をご記入ください。個人的な理由により、間違ったアカウントに連携したり、交換が間違った場合、その損失はユーザーご自身の責任となり、弊社は責任を負いません。<br />3、交換が失敗した場合は、失敗メッセージをご確認ください。解決できない場合、CSに連絡し、ご相談ください。<br />4、一旦交換申請を行うと、以上のルールに同意したとみなされます。交換の最終解釈権は我司が有します。",
        invite_Friends:"友達招待",
        invite_binding:"招待者連携",
        new_envelope:"新人褒賞",
        signReward:"ログイン完了",
        completeTask:"任務/0/完了",
        completeExchange:"交換/0/消費",
        language:"言語",
        country:"国・地域",
        select_pay_mode:"適切な現地支払いを選択してください",
        exchange_text14:"まだ開いていない",
        hint_func: "この機能はまだ利用できませんので、しばらくお待ちください",
        game_hub:"ゲームセンター",
        not_login:"ログインしていない",
        unlock:"ログインしてさらにエキサイティングなコンテンツをロック解除しましょう",
        for_the_account:"アカウントを作成しています",
        for_the_account_1:"電子メールをバインドすると、電子メールからパスワードを取得でき、アカウントのセキュリティが効果的に確保されます。",
        account_length:"アカウントの長さは 6 ～ 18 文字です",
        modify_successfully:"正常に変更されました",
        binding_successful:"バインド成功",
        start_game:"始める",
        game_down:"ダウンロード",
        new_travel:"新しいツアー",
        please_acc_code:"ユーザー名を入力してください",
        please_paw_code:"パスワードを入力してください",
        agree:"同意する",
        install:"設定",
        logout:"サインアウト",
        is_logout_account:"ログインアカウントからログアウトするかどうか",
        top_free:"人気の無料",
        top_jackpot:" 人気のベストセラー",
        peace:"そして",
        priv_policy:"プライバシーポリシー",
        tally:"ポイント",
        give_away:"プレゼント付き",
        voucher:"バウチャー",
        waiting_used:"使用する"
    }
}
