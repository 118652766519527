module.exports = {
    LOCAL:{
        zh_CN:"简体中文",
        zh_TW:"繁体中文",
        en_US:"English",
        ko_KR:"한국어",
        ru_RU:"Русский",
        de_DE:"Deutsch",
        ja_JP:"日本",
        pt_BR:"Português",
        tr_TR:"Türkiye",
        vi_VN:"Tiếng Việt",
        es_LA:"Español Americano",
        ar_SA:"عربي",
    },
    SERVER:{
        server_wz_limit_count:"Lượt đã đạt giới hạn",
        server_wz_task_cd:"Đang CD",
        server_wz_lack_money:"Thiếu cân bằng",
        server_result_success:"Thao tác thành công",
        server_result_fail:"Thao tác thất bại",
    },
    LAN:{
        change_pwd:"Đổi mật khẩu",
        change_pwd_tips:"Bạn đang đổi mật khẩu, hãy lưu mật khẩu mới cẩn thận",
        unbound:"(Chưa liên kết)",
        default_language:"Ngôn ngữ mặc định",
        user_service:"Thỏa thuận dịch vụ người dùng",
        bind_email:"Liên kết email",
        loading:"Đang tải…",
        helper:"Trợ giúp",
        gift:"Quà",
        mine:"Tôi",
        confirm:"Xác nhận",
        cancel:"Hủy",
        bind_email_tips:"Bạn đang liên kết email, liên kết email sẽ đảm bảo sự an toàn của tài khoản, có thể tìm lại mật khẩu thông qua email.",
        input_old_email:"Nhập email cũ",
        input_old_verify_code:"Nhập mã xác nhận email cũ",
        input_new_verify_code:"Nhập mã xác nhận email",
        input_validator_email:"Nhập tài khoản email chính xác",
        input_bind_email:"Nhập email bạn muốn liên kết",
        verify_code:"Mã xác nhận",
        next:"Tiếp",
        bind_now:"Liên kết ngay",
        tips:"*Nhắc nhở",
        email_tips:"Một số thư bị đánh dấu là thư rác, vui lòng kiểm tra 'Thư rác' để lấy mã xác nhận",
        send_verify_code:"Gửi mã xác nhận",
        sending:"Đang gửi…",
        send_success:"Gửi thành công",
        resend:"Gửi lại",
        old_pwd:"Mật khẩu cũ",
        input_old_pwd:"Nhập mật khẩu cũ",
        new_pwd:"Mật khẩu mới",
        input_new_pwd:"Nhập mật khẩu mới, độ dài 6-15 ký tự",
        confirm_pwd:"Xác nhận mật khẩu mới",
        input_confirm_pwd:"Nhập lại mật khẩu mới",
        change_now:"Đổi ngay",
        switch_account:"Đổi tài khoản",
        playing:"Bạn đang chơi",
        search_placeholder:"Nhập từ khóa",
        search:"Tìm kiếm",
        empty:"Không có dữ liệu",
        self_service:"Tự phục vụ",
        submit_feedback:"Gửi phản hồi",
        online_service:"CSKH online",
        official_verification:"Xác minh chính thức",
        myservice:"Phản hồi",
        questionTabs_1:"Câu hỏi nổi bật",
        questionTabs_2:"Liên quan nạp tiền",
        questionTabs_3:"Liên quan trò chơi",
        questionTabs_4:"Liên quan đăng nhập",
        questionTabs_999:"Xem thêm",
        question_detail:"Chi tiết câu hỏi",
        question_common:"Câu hỏi thường gặp",
        search_empty:"Không có nội dung tìm kiếm",
        feedback_detail:"Chi tiết phản hồi",
        contact_email:"Email liên lạc",
        screenshot:"Ảnh chụp",
        detailed_info:"Thông tin chi tiết",
        my_reply:"Trả lời",
        kf_reply:"Phản hồi của /0/ CSKH",
        input_feedback:"Hãy viết mô tả chi tiết vấn đề",
        service_score:"Hãy đánh giá",
        score:"/0/ điểm",
        scored:"Đã đánh giá, cảm ơn phản hồi của bạn",
        thank_feedback:"Đã đánh giá, cảm ơn phản hồi của bạn",
        score_tips:"Hãy chọn đánh giá trước",
        feedback_processing:"Phản hồi của bạn đang xử lý, vui lòng chờ đợi",
        feedback_tips:"Nếu vấn đề của bạn chưa được xử lý, vui lòng không phản hồi trùng lặp, bạn có thể trả lời câu hỏi này",
        submit:"Gửi",
        processing:"Đang xử lý",
        replied:"Đã trả lời",
        completed:"Đã hoàn thành",
        uploading:"Đang tải lên…",
        upload_failed:"Tải lên thất bại",
        upload_tips:"Hãy đợi tải ảnh lên hoàn tất",
        robot_name:"Trợ Lý Người Máy",
        robot_name2:"Trợ Lý hỗ trợ bạn",
        welcom_msg:"Cảm ơn bạn ghé thăm~Có thể đặt câu hỏi bằng cách gửi từ khóa~Trợ lý sẽ cố gắng hỗ trợ",
        change_batch:"Đổi",
        forget_pwd:"Quên mật khẩu",
        game_web:"Homepage",
        contact_customer:"Chuyển",
        ques_placeholder:"Mời nhập câu hỏi",
        send:"Gửi",
        connection:"Đang kết nối…",
        enter_question:"Nhập câu hỏi trước",
        are_you_asking:"Bạn muốn hỏi:",
        robot_not_found:"Xin lỗi vì không hiểu câu hỏi của bạn~Để giải quyết vấn đề của bạn tốt hơn hãy đổi cách hỏi khác. Nếu bạn muốn được nhân viên CSKH trợ giúp hãy nhấn nút chuyển",
        customer_service:"Nhân viên CSKH",
        feedback_0:"Nạp tiền không nhận được",
        feedback_1:"Liên quan BUG",
        feedback_2:"Nhân vật báo cáo",
        feedback_3:"Phản hồi ý kiến",
        feedback_4:"Tìm lại tài khoản",
        feedback_5:"ảnh chụp màn hình",
        feedback_server:"Server",
        feedback_rolename:"Tên nhân vật",
        feedback_price:"Mức giao dịch",
        feedback_order:"Mã giao dịch",
        feedback_report:"Nhân vật báo cáo",
        feedback_username:"Tài khoản",
        feedback_registerTime:"Thời gian đăng ký tài khoản",
        feedback_registerDevice:"Loại thiết bị đăng ký",
        feedback_lastLoginTime:"Thời gian đăng nhập cuối cùng",
        feedback_lastPayTime:"Thời gian nạp 1 tháng gần nhất",
        feedback_lastPayPrice:"Mức nạp 1 tháng gần nhất",
        feedback_lastPayOrder:"Mã giao dịch 1 tháng gần nhất",
        feedback_server_tips:"Nhập server",
        feedback_rolename_tips:"Nhập tên nhân vật",
        feedback_price_tips:"Nhập mức giao dịch",
        feedback_order_tips:"Nhập mã giao dịch",
        feedback_report_tips:"Nhập nhân vật báo cáo",
        feedback_username_tips:"Nhập tài khoản tìm lại",
        feedback_registerTime_tips:"Nhập thời gian đăng ký",
        feedback_registerDevice_tips:"Nhập loại thiết bị đăng ký",
        feedback_lastLoginTime_tips:"Nhập thời gian đăng ký",
        feedback_lastPayTime_tips:"Nhập thời gian nạp 1 tháng gần nhất",
        feedback_lastPayPrice_tips:"Nhập mức nạp 1 tháng gần nhất",
        feedback_lastPayOrder_tips:"Nhập mã giao dịch 1 tháng gần nhất",
        order_helper:"Làm sao kiểm tra mã giao dịch?",
        max_img:"Tối đa chỉ được tải lên 3 ảnh",
        contact:"Phương thức liên hệ",
        detailed_desc:"Mô tả chi tiết",
        myservice_count:"Bạn hiện có",
        myservice_count2:" phản hồi đang được xử lý",
        nomore:"Đã hết",
        feedback_state:"Trạng thái phản hồi",
        feedback_type:"Loại câu hỏi",
        create_time:"Thời gian tạo",
        gift_title:"Quà game",
        mygift_title:"Quà của tôi",
        recommend:"Trò chơi đề xuất",
        popularity:"Phổ biến",
        play:"Bắt đầu chơi",
        hot:"Hot",
        gift_empty:"Hiện không có quà, vui lòng chờ đợi",
        input_contact_email:"Vui lòng nhập email liên hệ",
        same_password:"Mật khẩu mới giống mật khẩu cũ, không thể đổi",
        success:"Thao tác thành công",
        input_email:"Nhập email",
        email:"Email",
        forgot:"Tìm lại mật khẩu",
        forgot_from_email:"Tìm lại thông qua email liên kết",
        forgot_from_service:"Liên hệ CSKH tìm lại",
        reset_password:"Xác nhận reset mật khẩu",
        unbind_email:"Hãy liên kết email trước rồi gỡ liên kết",
        unbind_fb:"Gỡ liên kết sẽ không thể dùng Facebook đăng nhập tài khoản này, xác nhận gỡ?",
        unbind_google:"Gỡ liên kết sẽ không thể dùng Google đăng nhập tài khoản này, xác nhận gỡ?",
        unbind_twitter:"Gỡ liên kết sẽ không thể dùng Twitter đăng nhập tài khoản này, xác nhận gỡ?",
        tips_title:"Nhắc nhở",
        copy_success:"Sao chép thành công",
        copy_faild:"Sao chép thất bại, trình duyệt này không hỗ trợ sao chép",
        gift_rate_task:"Hãy hoàn thành bình luận rồi nhận quà này nhé!",
        requesting:"Đang yêu cầu",
        get_gift:"Nhận quà",
        gift_rate:"Đến bình luận",
        gift_rate2:"Đến đánh giá",
        gift_share:"Đến chia sẻ",
        gift_bind:"Đến liên kết",
        gift_fill:"Đến điền",
        gift_ads:"Xem ngay",
        gift_copy:"Sao chép giftcode",
        gift_exchange:"Cách đổi",
        gift_exchange_desc:"Phúc Lợi-Giftcode-Nhập giftcode kích hoạt",
        password_help:"Làm sao để xem tài khoản mật khẩu của bản thân?",
        password_help_1:"1. Trong game nhấn avatar góc trên bên phải",
        password_help_2:"2. Nhấn nút màu đỏ 'Trung tâm người dùng' ở dưới cùng",
        password_help_3:"3. Nhấn 'Đổi tài khoản'",
        password_help_4:"4. Nhấn nút hình con mắt nhỏ trong hình để xem tài khoản mật khẩu hiện tại",
        payhelp_title:"Vui lòng chọn kênh thanh toán",
        payhelp_google:"Dùng Google play để tra cứu",
        payhelp_google1:"1. Mở Google play nhấn thanh công cụ bên trái",
        payhelp_google2:"2. Nhấn tài khoản của tôi",
        payhelp_google3:"3. Nhấn lịch sử giao dịch",
        payhelp_google4:"4. Nhấn vào giao dịch tương ứng để xem hóa đơn",
        payhelp_wechat:"Dùng App Store để tra cứu",
        payhelp_wechat1:"1. Nhấn cài đặt, chọn AppleID",
        payhelp_wechat2:"2. Chọn mục iTunes & App Store",
        payhelp_wechat3:"3. Đăng nhập vào AppleID",
        payhelp_wechat4:"3. Nhấn lịch sử giao dịch",
        payhelp_wechat5:"4. Nhấn giao dịch tương ứng để xem hóa đơn",
        payhelp_paypal:"Dùng Paypal tra cứu",
        payhelp_paypal1:"1. Đăng nhập Paypal",
        payhelp_paypal2:"2. Nhấn lịch sử giao dịch",
        payhelp_paypal3:"3. Nhấn xem chi tiết",
        payhelp_paypal4:"4. Nhấn giao dịch tương ứng để xem hóa đơn",
        payhelp_alipay:"Dùng Momo để tra cứu",
        payhelp_alipay1:"1. Đăng nhập Momo",
        payhelp_alipay2:"2. Nhấn lịch sử giao dịch",
        payhelp_alipay3:"3. Nhấn xem chi tiết",
        payhelp_alipay4:"4. Nhấn giao dịch tương ứng để xem hóa đơn",
        bind_mobile:"Điện thoại liên kết",
        bind_mobile_tips:"Bạn đang liên kết SĐT",
        input_old_mobile:"Nhập SĐT cũ",
        input_validator_mobile:"Nhập SĐT",
        input_bind_mobile:"Nhập SĐT muốn liên kết",
        mobile_tips:"Một số SĐT sẽ bị đánh dấu tin nhắn rác, hãy vào mục 'Tin rác' để xem mã xác nhận",
        contact_mobile:"SĐT liên kết",
        input_contact_mobile:"Nhập SĐT liên kết",
        input_mobile:"Nhập SĐT",
        mobile:"Số điện thoại",
        unbind_mobile:"Hãy liên kết email trước rồi gỡ liên kết",
        input_old_verify_mobile:"Nhập mã xác nhận của SĐT cũ",
        input_new_verify_mobile:"Nhập mã xác nhận của SĐT",
        pay_title:"Tiêu phí lần này khoảng",
        pay_change_currency:"Đổi loại tiền tệ",
        pay_tips:"(Mức thanh toán cuối cùng biến động dựa vào phương thức thanh toán và tỷ giá khác nhau)",
        pay_select_mode:"Hãy chọn phương thức thanh toán",
        wechat:"Thanh toán bằng thẻ ngân hàng",
        alipay:"Thanh toán Momo",
        pay_paying:"Đang yêu cầu thanh toán…",
        pay_fail:"Nạp thất bại, hãy thử lại sau",
        pay_return_tips:"Nếu đã hoàn thành thanh toán, hãy đóng giao diện này, nếu không vui lòng thực hiện lại thanh toán.",
        pay_discount:"(Ưu đãi /0/%)",
        pay_late_mode:"Phương thức thanh toán gần đây",
        pay_other_mode:"Phương thức thanh toán khác",
        pay_more:"Thêm phương thức thanh toán ",
        pay_point:"/0/điểm tặng /1/ điểm",
        points:"Nạp điểm game",
        current_points:"Điểm hiện tại:",
        pay:"Nạp",
        pwd:"Mật khẩu",
        pay_setting:"Cài đặt mật khẩu thanh toán",
        select_amount:"Chọn điểm cần nạp",
        forget:"Tìm lại mật khẩu thanh toán",
        forget_pay:"Quên mật khẩu thanh toán",
        input_pwd:"Nhập mật khẩu thanh toán 6 chữ số",
        different:"Mật khẩu khác mật khẩu xác nhận",
        setting_tips:"Bạn đang cài đặt mật khẩu thanh toán, hãy lưu mật khẩu cẩn thận",
        remain:"Còn lại",
        add:"Nạp điểm game",
        pay_mode:"Phương thức thanh toán",
        change_payPwd_tips:"Bạn đã sửa mật khẩu thanh toán, hãy lưu mật khẩu mới cẩn thận",
        unsetting:"Chưa cài mật khẩu thanh toán",
        email_unbound:"Email chưa liên kết",
        confirm_pay_pwd:"Xác nhận mật khẩu thanh toán",
        input_confirm_pay_pwd:"Nhập lại mật khẩu thanh toán",
        payPassword_error:"Mật khẩu thanh toán sai",
        balance:"Hạn mức không đủ",
        gamePoints:"Điểm game",
        pay_complete:"Hoàn thành thanh toán",
        pay_new_tips:"Trước khi hoàn thành thanh toán không đóng cửa sổ này",
        pay_new_message:"Tại giao diện mới mở hoàn thành nạp",
        pay_cancel:"Hủy thanh toán",
        back:"Trở lại",
        fee_name:"Dịch vụ hợp tác quảng cáo",
        fee_text1:"Hợp tác cùng HandZone Games",
        fee_text2:"Trở thành đối tác của HandZone Games, bạn có thể nhận link quảng cáo riêng. Người dùng sau khi tải app từ link này và tiến hành nạp, bạn sẽ nhận được hoa hồng tương ứng!",
        fee_text3:"Nếu gia nhập dịch vụ hợp tác quảng cáo HandZone Games cần đáp ứng điều kiện sau.",
        fee_price:"Mức nạp:",
        fee_text4:"Sau khi đạt điều kiện sẽ tự động mở dịch vụ hợp tác quảng cáo, sau khi mở bạn nhận được kế hoạch hợp tác, bạn sẽ được chia sẻ 10%-70% (dựa vào cấp quảng cáo phân chia) mức nạp của người chơi mới đến từ bạn.",
        fee_text5:"Chúc mừng bạn đã kích hoạt dịch vụ hợp tác quảng cáo",
        fee_text6:"Link quảng cáo của bạn là:",
        fee_copy:"Sao chép link",
        fee_text7:"Cấp quảng cáo chia hoa hồng như hình sau:",
        fee_text8:"A.B.C lần lượt nạp /0/",
        fee_text9:"Bạn được nhận:",
        fee_text10:"A có thể phát triển vô hạn",
        fee_text11:"Nhân viên quảng cáo cấp 1 là /0/ người, cấp 2 là /1/ người, cấp 3 là /2/ người",
        fee_price2:"Mức được rút:",
        fee_start:"Bắt đầu quảng cáo",
        fee_submit:"Rút tiền",
        fee_tips1:"Chưa đạt điều kiện rút",
        contact_kf:"Liên hệ CSKH",
        fee_tips2:"Hãy liên hệ CSKH để rút tiền",
        account_destroy:"Hủy tài khoản",
        account_destroy_now:"Yêu cầu hủy tài khoản",
        account_destroy_cancel:"Hủy bỏ hủy tài khoản",
        account_destroy_time:"Thời gian hủy tài khoản còn:",
        account_destroy_time2:"/0/ ngày sau",
        account_destroy_cancel_tips:"Bạn đã hủy yêu cầu hủy tài khoản",
        account_destroy_read_tips:"Bạn đã xem và đồng ý",
        account_destroy_read_tips2:"<Điều cần biết khi hủy tài khoản>",
        account_destroy_content1:"● 1. Sau khi hủy thành công, tài khoản này sẽ <a>không thể đăng nhập bất kỳ sản phẩm nào trên nền tảng của chúng tôi</a> và <a>tài khoản cùng dữ liệu sẽ không thể khôi phục</a>;",
        account_destroy_content2:"● 2. Hủy tài khoản cần <a>thu thập thông tin cá nhân của bạn (bao gồm mã xác nhận email)</a> dùng để xác minh thân phận, sau khi hủy tải khoản, chúng tôi sẽ <a>xóa thông tin trên hoặc xử lý ẩn danh</a>;",
        account_destroy_content3:"● 3. Sau khi hủy tài khoản thành công, tất cả nội dung, thông tin, lịch sử của tài khoản này sẽ bị <a>xóa hoặc xử lý ẩn danh</a>, bạn cũng không thể tìm kiếm, truy cập, nhận, tiếp tục sử dụng và tìm lại, cũng không có quyền yêu cầu chúng tôi tìm lại (Trừ khi có quy định khác của pháp luật và yêu cầu khác của cơ quan quản lý);",
        account_destroy_content4:"● 4. Sau khi hủy tài khoản, không thể đăng nhập nền tảng giao dịch tài khoản, <a>hành động yêu cầu hủy tài khoản của bạn biển hiện sự tự nguyện từ bỏ lợi ích của tài khoản này</a>, <a>trước khi hủy hãy suy nghĩ thật kỹ</a>, tránh sau khi hủy vì không thể đăng nhập mà có những tổn hại không cần thiết;",
        account_destroy_content5:"● 5. Sau khi hủy tải khoản, <a>chúng tôi có quyền không cung cấp bất kỳ dịch vụ nào liên quan đến tài khoản</a>, thỏa thuận người dùng, nghĩa vụ quyền lợi trước đó sẽ chấm dứt (ngoại trừ thỏa thuận giữa chúng tôi và bạn hoặc quy định khác của pháp luật);",
        account_destroy_content6:"● 6. Những bất tiện hoặc hậu quả bất lợi sau khi hủy tài khoản sẽ do bạn gánh chịu, không liên quan đến chúng tôi;",
        account_destroy_content7:"● 7. Nếu trong thời gian hủy tải khoản có tranh chấp quyền sở hữu, chúng tôi có quyền dừng yêu cầu hủy tài khoản;",
        account_destroy_content8:"● 8. Hủy tài khoản có thời gian xác nhận 7 ngày, trong thời gian này bạn có thể đến giao diện này hủy bỏ, qua thời gian xác nhận sẽ không thể hủy bỏ nữa;",
        unbind_account_destroy:"Hãy liên kết email trước rồi hủy tài khoản",
        pay_go:"Đến nạp",
        either_or:"Hoặc",
        my_info_avatar:"Avatar",
        avatar:"Avatar",
        look_avatar:"Xem avatar",
        change_avatar:"Đổi avatar",
        avatar_reviewing:"Đang duyệt",
        avatar_review_fail:"Duyệt thất bại",
        zbm0006:"Người chơi thân mến, avatar bạn tải lên do liên quan đến /0/ nên không được duyệt",
        shhz0001:"nội dung chính trị",
         shhz0002:"nội dung sắc dục",
          shhz0003:"nội dung đánh bạc",
        shhz0004:"nội dung độc hại",
        shhz0005:"nội dung tôn giáo",
        shhz0006:"việc chứa trang web hoặc địa chỉ trang web khác",
        shhz0007:"việc chứa nội dung quảng cáo",
        shhz0008:"nội dung khiếm nhã, thô tục",
        account:"Tên người dùng",
        vip_kf:"CSKH VIP",
        super_vip:"Hội viên SVIP",
        super_vip_kf:"CSKH riêng SVIP",
        super_vip_be:"Trở thành hội viên SVIP<br/>được CSKH riêng",
        super_vip_info1:"Dịch vụ riêng Thẻ Hội Viên SVIP",
        super_vip_info2:"(Chỉ mở cho người chơi vị thành niên)",
        super_vip_info3:"1.Kênh độc quyền<br/> CSKH riêng 1:1 tận tình, đưa ý kiến trực tiếp!<br/>2. Ưu tiên độc quyền<br/>Ưu tiên nhận thông tin về game đầu tiên<br/>3. Quà độc quyền<br/>Trở thành hội viên SVIP nhận quà lớn siêu giá trị<br/>4. SK độc quyền<br/>Các phúc lợi và sự kiện không định kỳ độc quyền!",
        super_vip_gain1:"Cách trở thành hội viên SVIP",
        super_vip_gain2:"Cách 1: Tích nạp cao nhất /0/ ",
        super_vip_get:"Nhận ngay",
        super_vip_gift:"Quà độc quyền VIP siêu cấp",
        super_vip_gift_tips:"Sau khi xác minh thành công được nhận quà, mau liên hệ CSKH!",
        super_vip_gift_1:"Tên game",
        super_vip_gift_2:"Trang phục hiếm",
        super_vip_gift_3:"Pet",
        super_vip_gift_4:"Gói chỉ định",
        super_vip_gift_5:"Vật phẩm hiếm",
        super_vip_gift_wechat:"Tài khoản:",
        feedback_reward_1:"Thưởng cho CSKH",
        feedback_reward_2:"Thưởng",
        feedback_reward_3:"Thưởng là bạn tự nguyện tặng cho CSKH, được chuyển hoàn toàn cho nhân viên CSKH",
        wechat_native_1:"Mức thanh toán:",
        wechat_native_2:"Mở ứng dụng quét mã để thanh toán",
        wechat_native_3:"Đã hoàn thành thanh toán",
        login_usern:"Đăng nhập tài khoản",
        register_username:"Đăng ký tài khoản",
        quick_register:"Đăng ký nhanh",
        enterNow:"Đăng nhập ngay",
        btn_register:"Đăng ký ngay",
        agreed1:"Tôi đồng ý",
        btn_have_usern:"Tài khoản đã có",
        to_login:"Đến đăng nhập",
        login_success:"Đăng nhập thành công",
        agree_protocol:"Hãy đồng ý thỏa thuận dịch vụ người dùng trò chơi",
        UserLogin:"Đăng nhập",
        UserLogin_text1:"Hãy điền tên người dùng",
        UserLogin_text2:"Hãy điền mật khẩu",
        UserLogin_text4:"Đang đăng nhập",
        UserLogin_text5:"Đang đăng ký",
        UserLogin_text6:"Đăng ký thành công",
        online_earning:"Đào Vàng Online",
        online_earning_center:"Trung Tâm Đào Vàng Online",
        online_earning_sigtiaoin:"Điểm Danh Khiêu Chiến",
        online_earning_text1:"Làm nhiệm vụ <br>kiếm tiền",
        online_earning_text2:"Xem video một lần để đăng nhập<br/>Nhận phần thưởng hậu hĩnh trong tổng cộng 7 ngày",
        online_earning_text3:"Phần thưởng hoán đổi",
        online_earning_text4:"Xem và nhận thưởng",
        online_earning_text5:"Còn /0/ lần",
        online_earning_text6:"Không đủ lượt",
        online_earning_text7:"Chờ nhận",
        exchange:"Đổi /0/",
        exchange_center:"Trung Tâm Đổi",
        exchange_prompt:"Lời nhắc đổi",
        exchange_confirm:"Xác nhận đổi",
        exchange_consume:"Tiêu hao: /0/",
        exchange_balance:"Số dư",
        exchange_detail:"Chi Tiết",
        exchange_balance_detail:"Chi tiết số dư",
        exchange_reward:"Đổi thưởng",
        exchange_text1:"/0/ điểm",
        exchange_text2:"/0/ RMB",
        exchange_text3:"Điểm trò chơi",
        exchange_text4:"Lì Xì Hiện Kim",
        exchange_text5:"Giới hạn vĩnh viễn: /0///1/",
        exchange_text6:"Límite diario：/0///1/",
        exchange_text7:"Hãy nhập mã mời để liên kết",
        exchange_text8:"Hãy nhập tài khoản PAYPAL",
        exchange_text9:"Mời người liên kết",
        exchange_text10:"Xác nhận liên kết",
        exchange_text11:"Đổi thành công, có thể đến Trung Tâm Người Dùng xem kết quả",
        exchange_text12:"Số lượng không đủ",
        exchange_text13:"Chỉ hiển thị 100 lịch sử gần đây",
        exchange_rule:"Quy Tắc",
        exchange_rule2:"Quy Tắc Đổi",
        exchange_rule2_html:"1. Tài khoản cần liên kết email mới có thể dùng tính năng đổi tài khoản. <br/>2. Khi đổi cần điền thông tin chính xác, nếu do lỗi cá nhân liên kết nhầm tài khoản hoặc đổi sai, bạn sẽ phải tự chịu trách nhiệm.<br />3. Nếu đổi thất bại, hãy xem lời nhắc thất bại, khi không thể giải quyết, hãy liên kệ CSKH. <br />4. Một khi tiến hành đổi, tức đã đồng ý với quy tắc trên.",
        invite_Friends:"Mời bạn",
        invite_binding:"Mời người liên kết",
        new_envelope:"Lì Xì Người Mới",
        signReward:"Hoàn thành điểm danh",
        completeTask:"Hoàn thành /0/ nhiệm vụ",
        completeExchange:"Đổi /0/ tiêu hao",
        language:"ngôn ngữ",
        country:"quốc gia/khu vực",
        select_pay_mode:"Chọn để nhận khoản thanh toán địa phương phù hợp",
        exchange_text14:"Chưa mở",
        hint_func: "Chức năng này chưa khả dụng, vui lòng theo dõi", 
        game_hub:"Trung tâm trò chơi",
        not_login:"Chưa đăng nhập",
        unlock:"Đăng nhập để mở khóa nhiều nội dung thú vị hơn",
        for_the_account:"Bạn đang tạo một tài khoản",
        for_the_account_1:"Ràng buộc email của bạn. Ràng buộc email của bạn có thể đảm bảo tính bảo mật cho tài khoản của bạn một cách hiệu quả. Bạn có thể lấy lại mật khẩu thông qua email của mình.",
        account_length:"Độ dài tài khoản là 6-18 ký tự",
        modify_successfully:"Đã sửa đổi thành công",
        binding_successful:"Ràng buộc thành công",
        start_game:"bắt đầu",
        game_down:"Tải xuống",
        new_travel:"chuyến du lịch mới",
        please_acc_code:"Vui lòng nhập tên người dùng",
        please_paw_code:"Xin vui lòng nhập mật khẩu",
        agree:"đồng ý",
        install:"cài đặt",
        logout:"đăng xuất",
        is_logout_account:"Có đăng xuất khỏi tài khoản đăng nhập hay không",
        top_free:"Phổ biến miễn phí",
        top_jackpot:" Bán chạy nhất được yêu thích",
        peace:"Và",
        priv_policy:"Chính sách bảo mật",
        tally:"Điểm",
        give_away:"Bao gồm quà tặng",
        voucher:"Chứng từ",
        waiting_used:"Được sử dụng"
    }
}
