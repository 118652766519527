import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import VueI18n from './language';
import 'amfe-flexible';
import '@vant/touch-emulator';
import "vant/lib/index.css";   // 全局引入样式
import MetaInfo from 'vue-meta-info'
import '@/assets/font/iconfont.css';
import * as f from "./utils/overall";
import "vue-cropper-h5/dist/style.css";
import jQuery from "jquery";
import 'default-passive-events';
import layer from '@layui/layer-vue';
import '@layui/layer-vue/lib/index.css';
import 'vue3-country-intl/lib/vue3-country-intl.css';

import {
  ActionBar,
  ActionBarIcon,
  ActionBarButton,
  Divider,
  Popup,
  Overlay,
  Loading,
  Dialog,
  ContactCard,
  Form,
  AddressEdit,
  AddressList,
  Field,
  CellGroup,
  Cell,
  SwipeCell,
  Icon,
  Stepper,
  Card,
  Checkbox,
  CheckboxGroup,
  Button,
  Swipe,
  SwipeItem,
  PullRefresh,
  List,
  Tab,
  Tabs,
  SubmitBar,
  Toast,
  Skeleton,
  Picker,
  Grid,
  GridItem,
  NavBar,
  DatetimePicker,
  Uploader,
  Search,
  Collapse,
  CollapseItem,
  Empty,
  Tag,
  Lazyload,
  Sidebar,
  SidebarItem,
  Rate,
  ActionSheet,
  Radio,
  RadioGroup,
  Row,
  Col,
  HeaderGame
} from "vant";

// 全局引入
import H5Cropper from "vue-cropper-h5";

import './registerServiceWorker'
Toast.setDefaultOptions({ duration: 2000 });
Toast.setDefaultOptions('loading', { duration: 2000, forbidClick: true });



const app = createApp(App); // 创建实例

app.config.productionTip = false;

window.$ = window.jQuery = jQuery;
app.config.globalProperties.$I = f.I18n;
app.config.globalProperties.$L = f.lang;

app.config.globalProperties.$V = f.Vip_Normal;
app.config.globalProperties.$SeoUpdate = (SeoTitle, SeoKeywords, SeoDescription) =>{
    let _headDom = '',_title = '',_meta = ''; 
    //获取head节点
    _headDom = document.getElementsByTagName('head')[0];
    //获取head节点下的title节点 
    _title = _headDom.getElementsByTagName("title")[0]; 
    //获取head节点下的meta节点，它一般是一个数组 
    _meta = _headDom.getElementsByTagName("meta"); 
    _title.innerText = SeoTitle;
    for (let index = 0; index < _meta.length; index++) { 
        switch (_meta[index].name) {
            case 'keywords':
                _meta[index].content = SeoKeywords;
                break;
            case 'description':
                _meta[index].content = SeoDescription;
                break;
            case 'searchtitle':
                _meta[index].content = SeoDescription;
                break;

            default:
                break;
        }
    }
   // console.log(SeoTitle, SeoKeywords, SeoDescription)
}


app.use(ActionBarButton)
  .use(ActionBarIcon)
  .use(ActionBar)
  .use(Divider)
  .use(Popup)
  .use(Overlay)
  .use(Loading)
  .use(Dialog)
  .use(Toast)
  .use(ContactCard)
  .use(Form)
  .use(AddressEdit)
  .use(AddressList)
  .use(Field)
  .use(CellGroup)
  .use(Cell)
  .use(SwipeCell)
  .use(Icon)
  .use(Stepper)
  .use(Card)
  .use(Button)
  .use(Swipe)
  .use(SwipeItem)
  .use(Sidebar)
  .use(SidebarItem)
  .use(PullRefresh)
  .use(List)
  .use(Tab)
  .use(Tabs)
  .use(SubmitBar)
  .use(Checkbox)
  .use(CheckboxGroup)
  .use(Skeleton)
  .use(Grid)
  .use(GridItem)
  .use(Picker)
  .use(NavBar)
  .use(Uploader)
  .use(Search)
  .use(DatetimePicker)
  .use(Collapse)
  .use(CollapseItem)
  .use(Lazyload)
  .use(H5Cropper)
  .use(Tag)
  .use(ActionSheet)
  .use(Empty)
  .use(Rate)
  .use(Radio)
  .use(RadioGroup)
  .use(layer)
  .use(Col)
  .use(Row)
  .use(HeaderGame)
  ;

app.use(MetaInfo);
app.use(router);
app.use(store);
app.use(VueI18n);

app.mount("#app");

