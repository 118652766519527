var ishttps = 'https:' == document.location.protocol ? true: false;

// 全局配置
export const GLOBAL = {
    API_URL: ishttps ? process.env.VUE_APP_API_URL.replace("http", "https") : process.env.VUE_APP_API_URL,
    // 隐私政策地址
    PRIVE_URL:"https://sdk.handzone.xyz/privacy/index.html"
}

console.log("ENV:", process.env.NODE_ENV)
console.log("APP_ENV_NAME:", process.env.VUE_APP_ENV_NAME)
console.log("APP_ENV_DESC:", process.env.VUE_APP_ENV_DESC)
console.log("APP_VERSION:", process.env.VUE_APP_VERSION)
console.log("HTTPS:", ishttps)

export const isDebug = process.env.NODE_ENV == 'development'
export const isTest = process.env.VUE_APP_ENV_NAME == 'test'

export var backUrl = '';

export const POINTS_PAY_DATA = {
    gameId: 1040,
}

// 渠道
export const CHANNEL = {
    GOOGLE_PLAY: 1,
    APPLE_STORE: 2,
    H5: 3,
    HUAWEI: 4,
    SAMSUNG: 5,
    ZY_ANDROID: 6,
    ZY_IOS: 7,
    AMAZON: 8,
    FACEBOOK: 9,
    VIVO: 10,
    COPY_IOS: 11, // 过包
    PC: 12,
    COPY_IOS2: 17, // 过包2
    MICROSOFT:19, // 微软商店
    CPS_ANDROID: 100,
    CPS_IOS: 101
}

export const LANG = {
    zh_CN:"zh_CN",
    zh_TW:"zh_TW",
    en_US:"en_US",
    ko_KR:"ko_KR",
    ru_RU:"ru_RU",
    de_DE:"de_DE",
    ja_JP:"ja_JP",
    pt_BR:"pt_BR",
    tr_TR:"tr_TR",
    vi_VN:"vi_VN",
    es_LA:"es_LA",
    ar_SA:"ar_SA",
}

// 支付模式
export const PAY_MODE = {
    WECHAT:'wechat',
    ALIPAY:'alipay',
    AIRWALLEX:'airwallex',
    PAYSSION:"payssion",
    PAYPAL:'paypal',
    XSOLLA:'xsolla',
    STRIPE:'stripe',
    MYCARD:'mycard',
    POINTS: 'points',
    PACYPAY: 'pacypay',
    MIHUA: 'mihua',
    JINGXIU: 'jingxiu',
    ADYEN: 'adyen',
    PAYERMAX: 'payermax',
    WORLDPAY:"worldpay",
    GZONE:"gzone"
}

// 登录账号类型
export const ACCOUNT_TYPE = {
    HANDZONE : 0,   // 掌域账号
    GOOGLE : 1,     // Google
    FACEBOOK : 2,   // Facebook
    TWITTER : 3,    // twitter
    APPLE : 4,      // 苹果
    HUAWEI : 5,     // 华为
    VK : 6          // VK
}