module.exports = {
    LOCAL:{
        zh_CN:"简体中文",
        zh_TW:"繁體中文",
        en_US:"English",
        ko_KR:"한국어",
        ru_RU:"Русский",
        de_DE:"Deutsch",
        ja_JP:"日本",
        pt_BR:"Português",
        tr_TR:"Türkçe",
        vi_VN:"Tiếng Việt",
        es_LA:"Español Americano",
        ar_SA:"عربي",
    },
    SERVER:{
        server_wz_limit_count:"Maksimum sınırına ulaşıldı",
        server_wz_task_cd:"Soğuyor",
        server_wz_lack_money:"Yetersiz bakiye",
        server_result_success:"Başarılı",
        server_result_fail:"Başarısız",
    },
    LAN:{
        change_pwd:"Şifreyi Değiştir",
        change_pwd_tips:"Hesap şifresini değiştiriyorsun, lütfen şifreni gizli tut",
        unbound:"(Bağlanmamış)",
        default_language:"Varsayılan Dil",
        user_service:"Hizmet Şartları",
        bind_email:"Elektronik posta ile bağla",
        loading:"Yükleniyor...",
        helper:"Yardım",
        gift:"Paket",
        mine:"Benim",
        confirm:"Onayla",
        cancel:"İptal",
        bind_email_tips:"Hesabını bir elektronik postaya bağlıyorsun. Bu işlem sonrasında hesabın daha iyi korunacak ve hesap şifren bağlanan elektronik posta adresi kullanılarak alınabilecektir.",
        input_old_email:"Lütfen daha önceki elektronik posta adresini gir",
        input_old_verify_code:"Lütfen daha önceki elektronik posta adresine gönderilen doğrulama kodunu gir",
        input_new_verify_code:"Lütfen elektronik posta adresine gönderilen doğrulama kodunu gir",
        input_validator_email:"Lütfen doğru bir elektronik posta adresi gir",
        input_bind_email:"Lütfen bağlamak istediğin elektronik posta adresini gir",
        verify_code:"Doğrulama Kodu",
        next:"Sonraki",
        bind_now:"Şimdi Bağla",
        tips:"*Hatırlatma",
        email_tips:"Gönderdiğimiz mesaj, bazı ücretsiz elektronik postalar tarafından yanlış bir şekilde istenmeyen posta olarak değerlendirilebilmektedir. Doğrulama kodunu kontrol etmek için lütfen [İstenmeyen Klasörüne] de bakmayı unutma. ",
        send_verify_code:"Doğrulama Kodu Gönder",
        sending:"Gönderiliyor...",
        send_success:"Gönderim işlemi başarılı",
        resend:"Tekrar gönder",
        old_pwd:"Önceki Şifre",
        input_old_pwd:"Lütfen önceki şifreyi gir",
        new_pwd:"Yeni Şifre",
        input_new_pwd:"Lütfen yeni şifreyi gir (6 ila 15 harf arası)",
        confirm_pwd:"Şifreyi Onayla",
        input_confirm_pwd:"Lütfen yeni şifreyi tekrar gir",
        change_now:"Şimdi Değiştir",
        switch_account:"Hesap Değiştir",
        playing:"Oynuyorsun",
        search_placeholder:"Lütfen anahtar kelimeyi gir",
        search:"Ara",
        empty:"Veri yok",
        self_service:"Kendi Kendine Yardım Hizmeti",
        submit_feedback:"Geri Bildirim Gönder",
        online_service:"Çevrim İçi MH",
        official_verification:"Resmî Doğrulama",
        myservice:"Geri Bildirimim",
        questionTabs_1:"Güncel Konular",
        questionTabs_2:"Yükleme",
        questionTabs_3:"Oyun",
        questionTabs_4:"Oturum Aç",
        questionTabs_999:"Daha fazla",
        question_detail:"Ayrıntılar",
        question_common:"SSS",
        search_empty:"Sonuç yok",
        feedback_detail:"Geri Bildirim Ayrıntıları",
        contact_email:"İletişim Elektronik Posta Adresi",
        screenshot:"Ekran Görüntüsü",
        detailed_info:"Ayrıntılar",
        my_reply:"Yanıtım",
        kf_reply:"MH Yanıtı /0/",
        input_feedback:"Lütfen sorunu ayrıntılı olarak tanımla",
        service_score:"Lütfen bu hizmeti puanla",
        score:"/0/",
        scored:"Puan verildi, geri bildirimin için teşekkür ederiz",
        thank_feedback:"Puan verildi, geri bildirimin için teşekkür ederiz",
        score_tips:"Lütfen bir puan seç",
        feedback_processing:"Geri bildirimin işleniyor, lütfen bekle...",
        feedback_tips:"Sorun çözülmezse lütfen tekrar tekrar kayıt oluşturma. Buradan tekrar yanıt gönderebilirsin",
        submit:"Gönder",
        processing:"İşleniyor",
        replied:"Yanıtlandı",
        completed:"Tamamlandı",
        uploading:"Yükleniyor...",
        upload_failed:"Yükleme başarısız oldu",
        upload_tips:"Lütfen resmin yüklenmesini bekle",
        robot_name:"Bot",
        robot_name2:"Bot hizmetinde",
        welcom_msg:"Ziyaretin için teşekkür ederiz. Anahtar kelime girerek arama yapabilirsin. Bot sana yardımcı olacaktır.",
        change_batch:"Kümeyi Değiştir",
        forget_pwd:"Şifremi Unuttum",
        game_web:"Resmî İnternet Sitesi",
        contact_customer:"Personel Hizmeti",
        ques_placeholder:"Lütfen sorunu tanımla",
        send:"Gönder",
        connection:"Bağlanıyor...",
        enter_question:"Lütfen öncelikle sorunu gir",
        are_you_asking:"Bunu mu sormak istedin:",
        robot_not_found:"Üzgünüm, tam olarak anlayamadım. Sorununun daha iyi bir şekilde çözülebilmesi için sorununu farklı bir şekilde ifade etmeni öneririm. Personelimizin yardımına ihtiyacın varsa lütfen Personel Hizmeti butonuna dokun.",
        customer_service:"Personel Hizmeti",
        feedback_0:"Tamamlanmayan yükleme",
        feedback_1:"Hatalar",
        feedback_2:"Bildirim",
        feedback_3:"Öneriler",
        feedback_4:"Hesabı Geri Alma",
        feedback_5:"ekran görüntüsü",
        feedback_server:"Sunucu",
        feedback_rolename:"Karakter Adı",
        feedback_price:"Sipariş Miktarı",
        feedback_order:"Sipariş Numarası",
        feedback_report:"Bildirim",
        feedback_username:"Hesap",
        feedback_registerTime:"Kayıt Zamanı",
        feedback_registerDevice:"Cihaz",
        feedback_lastLoginTime:"Son oturum açma",
        feedback_lastPayTime:"Geçen ayın yükleme zamanı",
        feedback_lastPayPrice:"Geçen ayın yükleme miktarı",
        feedback_lastPayOrder:"Geçen ayın yükleme sipariş numarası",
        feedback_server_tips:"Lütfen sunucu adını gir",
        feedback_rolename_tips:"Lütfen karakter adını gir",
        feedback_price_tips:"Lütfen sipariş miktarını gir",
        feedback_order_tips:"Lütfen sipariş numarasını gir",
        feedback_report_tips:"Lütfen bildirmek istediğin karakteri gir",
        feedback_username_tips:"Lütfen geri almak istediğin hesabı gir",
        feedback_registerTime_tips:"Lütfen hesap kayıt zamanını gir",
        feedback_registerDevice_tips:"Lütfen kaydolurken kullandığın cihazı gir",
        feedback_lastLoginTime_tips:"Lütfen son oturum açılan zamanını gir",
        feedback_lastPayTime_tips:"Lütfen geçen ayın yükleme zamanını gir",
        feedback_lastPayPrice_tips:"Lütfen geçen ayın yükleme miktarını gir",
        feedback_lastPayOrder_tips:"Lütfen geçen ayın sipariş numarasını gir",
        order_helper:"Sipariş numaramı nasıl öğrenebilirim?",
        max_img:"Resimler, en fazla 3 resim yüklenebilir",
        contact:"İletişim Bilgileri",
        detailed_desc:"Ayrıntılar",
        myservice_count:"Geri bildirimlerin",
        myservice_count2:"işleniyor",
        nomore:"Başka bir şey yok",
        feedback_state:"Durum",
        feedback_type:"Tür",
        create_time:"Oluşturulduğu Zaman",
        gift_title:"Oyun Paketleri",
        mygift_title:"Paketlerim",
        recommend:"Önerilen Oyunlar",
        popularity:"Popüler",
        play:"Yeni",
        hot:"Güncel",
        gift_empty:"Paket yok, lütfen beklemeye devam et",
        input_contact_email:"Lütfen iletişim elektronik posta adresini gir",
        same_password:"Girdiğin şifre önceki şifrenle aynı. Değiştirmeye gerek yok",
        success:"Başarılı",
        input_email:"Lütfen elektronik posta adresini gir",
        email:"Elektronik Posta",
        forgot:"Şifreyi Al",
        forgot_from_email:"Bağlı elektronik posta adresiyle",
        forgot_from_service:"Müşteri hizmetleri yoluyla",
        reset_password:"Şifreyi sıfırlama işlemini onayla",
        unbind_email:"Bağlantıyı kesmeden önce lütfen elektronik posta adresini bağla",
        unbind_fb:"Bağlantı kesildiğinde Facebook hesabını kullanarak bu hesaba giriş yapamayacaksın. Yine de bağlantı kesilsin mi?",
        unbind_google:"Bağlantı kesildiğinde Google hesabını kullanarak bu hesaba giriş yapamayacaksın. Yine de bağlantı kesilsin mi?",
        unbind_twitter:"Bağlantı kesildiğinde Twitter hesabını kullanarak bu hesaba giriş yapamayacaksın. Yine de bağlantı kesilsin mi?",
        tips_title:"İpuçları",
        copy_success:"Kopyalandı~",
        copy_faild:"Kopyalanamadı. Tarayıcı kopyalama işlemini desteklemiyor",
        gift_rate_task:"Bu paketi almadan önce lütfen yorumunu yaz",
        requesting:"İsteniyor",
        get_gift:"Paketi Al",
        gift_rate:"Git ve Yorum Yap",
        gift_rate2:"Git ve Puanla",
        gift_share:"Git ve Paylaş",
        gift_bind:"Git ve Bağla",
        gift_fill:"Git ve Doldur",
        gift_ads:"Şimdi İzle",
        gift_copy:"Hediye Kodunu Kopyala",
        gift_exchange:"Değişim",
        gift_exchange_desc:"Etkinleştirmek için Avantajlar - Etkinleştirme Kodu - Hediye Kodunu Gir kısayolunu izle",
        password_help:"Hesap şifremi nasıl görüntüleyebilirim?",
        password_help_1:"1. Sol üst köşedeki avatara dokun",
        password_help_2:"2. Alt kısımda bulunan kırmızı renkli “Kullanıcı Merkezi” butonuna dokun",
        password_help_3:"3. “Hesap Değiştir” butonuna dokun",
        password_help_4:"4. Mevcut hesabın şifresini görüntülemek için göz ikonuna dokun",
        payhelp_title:"Lütfen bir ödeme platformu seç",
        payhelp_google:"Google Play üzerinde arama",
        payhelp_google1:"1. Google Play uygulamasını aç ve soldaki menü çubuğuna dokun",
        payhelp_google2:"2. Hesabıma dokun",
        payhelp_google3:"3. Siparişlere dokun",
        payhelp_google4:"4. Makbuzu görüntülemek için ilgili siparişe dokun",
        payhelp_wechat:"Wechat üzerinde arama",
        payhelp_wechat1:"1. Wechat açmak için dokun",
        payhelp_wechat2:"2. [Benim] kısmına dokun",
        payhelp_wechat3:"3. Ödeme - Cüzdan kısmına dokun",
        payhelp_wechat4:"4. Fatura kısmına dokun",
        payhelp_wechat5:"5. Sipariş makbuzunu görüntülemek için ilgili siparişe dokun",
        payhelp_paypal:"Paypal üzerinde arama",
        payhelp_paypal1:"1. Paypal hesabına giriş yap",
        payhelp_paypal2:"2. İşlem Kaydına dokun",
        payhelp_paypal3:"3. Ayrıntılara dokun",
        payhelp_paypal4:"4. Ayrıntıları görüntülemek için ilgili siparişe dokun",
        payhelp_alipay:"Alipay üzerinde arama",
        payhelp_alipay1:"1. Alipay açmak için dokun",
        payhelp_alipay2:"2. [Benim] kısmına dokun",
        payhelp_alipay3:"3. Fatura kısmına dokun",
        payhelp_alipay4:"4. Makbuzu görüntülemek için ilgili siparişe dokun",
        bind_mobile:"Cep telefonuna bağla",
        bind_mobile_tips:"Bu hesabı cep telefonuna bağlıyorsun",
        input_old_mobile:"Lütfen eski cep telefonu numaranı gir",
        input_validator_mobile:"Lütfen doğru cep telefonu numarasını gir",
        input_bind_mobile:"Lütfen bağlamak istediğin cep telefonu numarasını gir",
        mobile_tips:"Gönderdiğimiz mesajlar bazı cep telefonları tarafından gereksiz mesaj olarak değerlendirilebilmektedir. Doğrulama kodunu kontrol etmek için lütfen [İstenmeyen Mesajlar] kısmına bakmayı unutma.",
        contact_mobile:"İletişim Numarası",
        input_contact_mobile:"Lütfen iletişim numaranı gir",
        input_mobile:"Lütfen cep telefonu numaranı gir",
        mobile:"Cep Telefonu Numarası",
        unbind_mobile:"Bağlantıyı kesmeden önce lütfen elektronik posta adresini bağla",
        input_old_verify_mobile:"Lütfen eski telefonuna gönderilen doğrulama kodunu gir",
        input_new_verify_mobile:"Lütfen telefonuna gönderilen doğrulama kodunu gir",
        pay_title:"Toplam:",
        pay_change_currency:"Para Birimini Değiştir",
        pay_tips:"(Nihai ödeme miktarı ödeme yöntemine ve döviz kurlarına göre değişiklik gösterebilir)",
        pay_select_mode:"Ödeme yöntemini seç",
        wechat:"WeChat Pay",
        alipay:"Alipay",
        pay_paying:"Ödeme talep ediliyor...",
        pay_fail:"Yükleme başarısız oldu. Lütfen daha sonra tekrar dene.",
        pay_return_tips:"Ödeme tamamlandıysa lütfen bu sayfayı kapat, aksi halde başka bir ödeme başlatılacaktır.",
        pay_discount:"(İndirim %/0/)",
        pay_late_mode:"Seçilen son ödeme yöntemi",
        pay_other_mode:"Diğer ödeme yöntemleri",
        pay_more:"Daha fazla yöntem ",
        pay_point:"Ücretsiz /1/ puan elde etmek için /0/ puan yükle",
        points:"Oyun Puanları Yükle",
        current_points:"Mevcut Puan:",
        pay:"Yükleme",
        pwd:"Şifre",
        pay_setting:"Ödeme şifresi belirle",
        select_amount:"Lütfen yüklemek istediğin puan miktarını seç",
        forget:"Ödeme şifresi al",
        forget_pay:"Ödeme şifresini unuttum",
        input_pwd:"Lütfen 6 haneli bir ödeme şifresi gir",
        different:"Şifreler eşleşmiyor",
        setting_tips:"Bir ödeme şifresi belirliyorsun. Lütfen ödeme şifresini gizli tut",
        remain:"Denge",
        add:"Yükleme Puanları",
        pay_mode:"Ödeme Yöntemleri",
        change_payPwd_tips:"Ödeme şifreni değiştiriyorsun. Lütfen yeni ödeme şifreni gizli tut",
        unsetting:"Ödeme şifresi yok",
        email_unbound:"Bağlı elektronik posta adresi yok",
        confirm_pay_pwd:"Ödeme şifresini onayla",
        input_confirm_pay_pwd:"Lütfen ödeme şifresini tekrar gir",
        payPassword_error:"Yanlış ödeme şifresi",
        balance:"Yetersiz Bakiye",
        gamePoints:"Puan",
        pay_complete:"Ödeme tamamlandı",
        pay_new_tips:"Yükleme tamamlanana kadar bu sayfayı kapatma",
        pay_new_message:"Lütfen yüklemeyi yeni sayfada tamamla",
        pay_cancel:"Ödemeyi iptal et",
        back:"Arka",
        fee_name:"Satış Ortaklığı Programı",
        fee_text1:"HandZone Games ile çalış",
        fee_text2:"HandZone Games ortağı olarak benzersiz bir pazarlama bağlantısı alacaksın. Bu bağlantı aracılığıyla yapılan her yükleme sana pay getirecektir.",
        fee_text3:"HandZone Games Satış Ortaklığı Programına katılmak için aşağıdaki koşulları sağlamalısın.",
        fee_price:"Eşit yükleme miktarı:",
        fee_text4:"Koşullar sağlandığında Satış Ortaklığı Programına katılabilir ve bağlantını kullanarak oyunu indiren yeni oyuncuların yüklediği miktarın %10 - %70 oranında (ortaklık seviyesine göre) pay elde edebilirsin.",
        fee_text5:"Tebrikler! Satış Ortaklığı Programına katıldın",
        fee_text6:"Satış bağlantın:",
        fee_copy:"Bağlantıyı kopyala",
        fee_text7:"Mevcut komisyon seviyesi:",
        fee_text8:"Üç oyuncu, A, B ve C, sırasıyla /0/ yükle",
        fee_text9:"Elde edeceklerin:",
        fee_text10:"A, daha fazla geliştirilebilir",
        fee_text11:"Artık /0/ Sv.1 ortağa, /1/ Sv.2 ortağa ve /2/ Sv.3 ortağa sahipsin",
        fee_price2:"Çekilebilecek miktar:",
        fee_start:"Ortaklığa başla",
        fee_submit:"Çekme",
        fee_tips1:"Çekme koşulları sağlanmamış",
        contact_kf:"Müşteri hizmetleri ile iletişime geç",
        fee_tips2:"Çekme işlemi için müşteri hizmetleri ile iletişime geç",
        account_destroy:"Hesap Kapatma",
        account_destroy_now:"Bir hesap kapatma talebi oluştur",
        account_destroy_cancel:"Hesap kapatma talebini geri al",
        account_destroy_time:"Hesap kapatma için kalan süre:",
        account_destroy_time2:"/0/ gün sonra",
        account_destroy_cancel_tips:"Hesap kapatma talebini geri aldın",
        account_destroy_read_tips:"Okudum ve kabul ediyorum ",
        account_destroy_read_tips2:"Hesap Kapatma Kuralları",
        account_destroy_content1:"●1. Kapatma işlemi sonrasında bu hesapla <a>şirketimizin hesap platformuna ya da herhangi bir ürüne giriş yapılamaz</a> & <a>hesap ve veriler kurtarılamaz</a>;",
        account_destroy_content2:"●2. Hesap kapatma talebini işlemek için kimlik doğrulama amacıyla <a>kişisel hassas bilgilerini (elektronik posta doğrulama kodu dâhil) toplamamız</a> gerekmektedir. Şirketimiz, hesabın kapatıldığında <a>yukarıda belirtilen bilgileri silecek ya da gizleyecektir</a>;",
        account_destroy_content3:"●3. Hesabın kapatıldığında hesabındaki tüm içerikler, bilgiler ve kayıtlar <a>silinecek ya da gizlenecektir</a>. Artık hesabındaki hiçbir bilgiyi arayamaz, hiçbir bilgiye erişemez, hiçbir bilgiyi elde edemez, kullanmaya devam edemez ya da kurtaramazsın veya şirketimizin kurtarmasını talep edemezsin (yasa ya da düzenleyici makamlar tarafından aksi talep edilmediği sürece);",
        account_destroy_content4:"●4. Hesabın kapatıldığında ilgili hesabın işlem platformuna giriş yapamazsın. <a>Hesap kapatma talebin, bu hesapta sahip olduğun tüm menfaatlerden gönüllü olarak vazgeçtiğin anlamına gelmektedir.</a>. Hesap kapatma işlemi sonrasında platforma erişememekten kaynaklanan gereksiz kayıpları önlemek için <a>kapatma işleminden önce lütfen platformdaki işlem ve para çekme taleplerinin tamamlandığından emin ol</a>;",
        account_destroy_content5:"●5. Hesabın kapatıldığında <a>şirketimiz bu hesapla ilişkili hizmet sunmayı durdurma hakkına sahiptir</a>. Şirketimiz nezdinde imzaladığınız kullanıcı sözleşmesi ile hak ve yükümlülükleri içeren diğer belgeler feshedilecektir (aramızda aksi kararlaştırılmadıkça ya da kanunen gerek duyulmadıkça);",
        account_destroy_content6:"●6. Hesabının kapatılmasından kaynaklanan uygunsuz durumlardan ya da olumsuz sonuçlardan yalnızca sen sorumlusun. Şirketimiz bu tür durumlarda sorumlu tutulamaz.",
        account_destroy_content7:"●7. Hesabın kapatılması sırasında herhangi bir mülkiyet anlaşmazlığı olması durumunda şirketimiz iptal talebini sonlandırma hakkına sahiptir;",
        account_destroy_content8:"●8. Hesap kapatma işlemi için 7 günlük bir onay süresi bulunmaktadır ve bu süre içinde talebini geri almak için bu arayüze girebilirsin. Onay sürecinden sonra hesap kapatma talebini geri alamazsın.",
        unbind_account_destroy:"Hesabını kapatmadan önce lütfen elektronik posta adresini bağla",
        pay_go:"Yükleme",
        either_or:"ya da",
        my_info_avatar:"Avatar",
        avatar:"Avatar",
        look_avatar:"Avatarı görüntüle",
        change_avatar:"Avatarı değiştir",
        avatar_reviewing:"İnceleniyor",
        avatar_review_fail:"İnceleme başarısız",
        zbm0006:"Sevgili oyuncu, yüklediğin özelleştirilmiş avatar /0/ içerdiği için onaylanmadı",
        shhz0001:"Siyasi açıdan hassas içerikler",
         shhz0002:"Pornografik içerikler",
          shhz0003:"Kumar içerikleri",
        shhz0004:"Uyuşturucuyla ilgili içerikler",
        shhz0005:"Dini açıdan hassas içerikler",
        shhz0006:"Diğer internet siteleri ya da internet sitesi adresleri",
        shhz0007:"Reklamlar",
        shhz0008:"Uygunsuz ve kaba içerikler",
        account:"Kullanıcı Adı",
        vip_kf:"VIP Müşteri Hizmetleri",
        super_vip:"Süper VIP",
        super_vip_kf:"Süper VIP Özel Müşteri Hizmetleri",
        super_vip_be:"Süper VIP oldun<br/>Özel Hizmetin tadını çıkar",
        super_vip_info1:"Süper VIP Özel Hizmet",
        super_vip_info2:"(YALNIZCA yetişkin oyuncular)",
        super_vip_info3:"1. Özel Kanal<br/>Bire Bir Özel Hizmet. Önerin doğrudan geliştiricilere gönderilecektir!<br/>2. Özel Öncelik<br/>En güvenilir oyun bilgilerine öncelikli erişim elde et<br/>3. Özel Paketler<br/>Süper büyük paketleri almak için Süper VIP ol!<br/>4. Özel Etkinlikler<br/>Zaman zaman özel etkinliklerin ve ayrıcalıkların tadını çıkar!",
        super_vip_gain1:"Nasıl Süper VIP olurum?",
        super_vip_gain2:"Yöntem 1: Toplam /0/ Altın yükleme miktarına ulaş",
        super_vip_get:"Şimdi Al",
        super_vip_gift:"Süper VIP Özel Paketi",
        super_vip_gift_tips:"Doğrulama işlemi başarılı olduktan sonra özel paketi alabilirsin. Git ve müşteri hizmetlerimizle iletişime geç!",
        super_vip_gift_1:"Oyun İçi Unvan",
        super_vip_gift_2:"Nadir Dekorasyon",
        super_vip_gift_3:"Evcil Hayvan",
        super_vip_gift_4:"Özelleştirilmiş Paket",
        super_vip_gift_5:"Nadir Öge",
        super_vip_gift_wechat:"WeChat:",
        feedback_reward_1:"Müşteri Hizmetlerini Ödüllendir",
        feedback_reward_2:"Ödül",
        feedback_reward_3:"Ödül, müşteri hizmetlerimize verdiğin gönüllü hediyedir ve eksiksiz bir şekilde sorumlu müşteri hizmetleri personeline ödenecektir.",
        wechat_native_1:"Ödeme miktarı:",
        wechat_native_2:"Ödemeyi tamamlamak için lütfen WeChat uygulamasını aç ve QR kodunu tara",
        wechat_native_3:"Ödeme tamamlandı",
        login_usern:"Oturum Aç",
        register_username:"Kaydol",
        quick_register:"Hızlı Kayıt",
        enterNow:"Şimdi Oturum Aç",
        btn_register:"Şimdi Kaydol",
        agreed1:"Kabul Et",
        btn_have_usern:"Zaten hesabım var",
        to_login:"Git ve Oturum Aç",
        login_success:"Oturum açma işlemi başarılı",
        agree_protocol:"Lütfen Hizmet Şartlarını kabul et",
        UserLogin:"Oturum Aç",
        UserLogin_text1:"Kullanıcı Adını Gir",
        UserLogin_text2:"Şifreyi Gir",
        UserLogin_text4:"Oturum açılıyor",
        UserLogin_text5:"Kaydolunuyor",
        UserLogin_text6:"Kaydolma işlemi başarılı",
        online_earning:"Çevrim İçi Kazanç",
        online_earning_center:"Kazanç Merkezi",
        online_earning_sigtiaoin:"Giriş Meydan Okuması",
        online_earning_text1:"Nakit İçin<br>Görevleri Tamamla",
        online_earning_text2:"Oturum açmak için videoyu bir kez izleyin <br/> Cömert ödüller kazanmak için toplamda 7 gün oturum açın",
        online_earning_text3:"Kıyamet edilebilir ödüller",
        online_earning_text4:"İzle ve ödülleri al",
        online_earning_text5:"Kalan: /0/",
        online_earning_text6:"Yetersiz hak",
        online_earning_text7:"Alınmamış",
        exchange:"Alım: /0/",
        exchange_center:"Alım Merkezi",
        exchange_prompt:"Alım İpuçları",
        exchange_confirm:"Onayla",
        exchange_consume:"Maliyet: /0/",
        exchange_balance:"Denge",
        exchange_detail:"Ayrıntılar",
        exchange_balance_detail:"Bakiye Ayrıntıları",
        exchange_reward:"Alım Ödülleri",
        exchange_text1:"/0/ Puan",
        exchange_text2:"/0/$",
        exchange_text3:"Puan",
        exchange_text4:"Nakit Paketi",
        exchange_text5:"Alım sınırı: /0///1/",
        exchange_text6:"Günlük Sınır：/0///1/",
        exchange_text7:"Bağlamak için Davet Kodunu gir",
        exchange_text8:"PayPal hesabını gir",
        exchange_text9:"Davet Eden Bağlama",
        exchange_text10:"Onayla",
        exchange_text11:"Alındı. Sonuçları Kullanıcı Merkezinden kontrol edebilirsiniz",
        exchange_text12:"Yetersiz miktar",
        exchange_text13:"Yalnızca son 100 kaydı görüntüle",
        exchange_rule:"Kurallar",
        exchange_rule2:"Alım Kuralları",
        exchange_rule2_html:"1. Hesap Alımı işlemini etkinleştirmek için elektronik posta adresinin bağlanması gerekmektedir;<br/>2. Lütfen alım süresince bilgilerin doğru olduğundan emin ol. Hesap bağlama ya da alım sırasında yapılan kişisel hatalar nedeniyle meydana gelecek tüm kayıplar senin sorumluluğundadır;<br />3. Alım başarısız olduğunda lütfen hata mesajını gönder. Sorun çözülmezse lütfen müşteri hizmetleriyle iletişime geç;<br />4. Alım işleminin başlatılması kuralları kabul ettiğin anlamına gelir. Kullanıma ilişkin nihai yorumlama hakları Handzone firmasına aittir.",
        invite_Friends:"Arkadaşları Davet Et",
        invite_binding:"Davet Eden Bağlama",
        new_envelope:"Başlangıç Paketi",
        signReward:"Girişi Tamamla",
        completeTask:"/0/ Görev tamamla",
        completeExchange:"Alım: /0/, Maliyet",
        language:"dil",
        country:"ülke / bölge",
        select_pay_mode:"Doğru yerel ödemeyi almayı seçin",
        exchange_text14:"Henüz açık değil",
        hint_func: "A função ainda não está disponível, fique atento",
        game_hub:"Centro de jogos",
        not_login:"Não logado",
        unlock:"Faça login para desbloquear conteúdo mais interessante",
        for_the_account:"Você está criando uma conta",
        for_the_account_1:"Vincular seu e-mail pode garantir efetivamente a segurança da sua conta. Você pode recuperar sua senha por meio de seu e-mail.",
        account_length:"O comprimento da conta é de 6 a 18 caracteres",
        modify_successfully:"Modificado com sucesso",
        binding_successful:"Vinculação bem-sucedida",
        start_game:"começar",
        game_down:"download",
        new_travel:"nova turnê",
        please_acc_code:"Por favor insira o nome de usuário",
        please_paw_code:"Por favor insira a senha",
        agree:"concordar",
        install:"configurar",
        logout:"sair",
        is_logout_account:"Se deve sair da conta de login",
        top_free:"Popular grátis",
        top_jackpot:"Mais vendidos populares",
        peace:"e",
        priv_policy:"política de Privacidade",
        tally:"Pontos",
        give_away:"Inclui presentes",
        voucher:"Vouchers",
        waiting_used:"Ser usado"
    }
}
