module.exports = {
    LOCAL:{
        zh_CN:"简体中文",
        zh_TW:"繁體中文",
        en_US:"English",
        ko_KR:"한국어",
        ru_RU:"Русский",
        de_DE:"Deutsch",
        ja_JP:"日本",
        pt_BR:"Português",
        tr_TR:"Türkiye",
        vi_VN:"Tiếng Việt",
        es_LA:"Español Americano",
        ar_SA:"عربي",
    },
    SERVER:{
        server_wz_limit_count:"Max limit reached",
        server_wz_task_cd:"On Cooldown",
        server_wz_lack_money:"Insufficient balance",
        server_result_success:"Successful",
        server_result_fail:"Failed",
    },
    LAN:{
        change_pwd:"Change Password",
        change_pwd_tips:"You are changing the account password, please keep your new password safe",
        unbound:"(Not linked)",
        default_language:"Default Language",
        user_service:"Terms of Service",
        bind_email:"Link with Mailbox",
        loading:"Loading...",
        helper:"Help",
        gift:"Pack",
        mine:"My",
        confirm:"Confirm",
        cancel:"Cancel",
        bind_email_tips:"You are linking with a mailbox which can keep your account safe and retrieve your password.",
        input_old_email:"Please enter the former mailbox",
        input_old_verify_code:"Please enter the former mailbox verification code",
        input_new_verify_code:"Please enter the new mailbox verification code",
        input_validator_email:"Please enter the correct mailbox",
        input_bind_email:"Please enter the mailbox you wish to link with",
        verify_code:"Verification Code",
        next:"Next",
        bind_now:"Link Now",
        tips:"*Reminder",
        email_tips:"The mail might be mis-deemed as a junk mail by some free E-mail servers, please go to [Junk Mails] to view the verification code.",
        send_verify_code:"Send Code",
        sending:"Sending...",
        send_success:"Sent successfully",
        resend:"Re-send",
        old_pwd:"Former Password",
        input_old_pwd:"Please enter the former password",
        new_pwd:"New Password",
        input_new_pwd:"Please enter the new password with between 6 and 15 characters",
        confirm_pwd:"Confirm Password",
        input_confirm_pwd:"Please enter the new password again",
        change_now:"Change Now",
        switch_account:"Switch Account",
        playing:"You are playing",
        search_placeholder:"Please enter the keyword",
        search:"Search",
        empty:"No Data",
        self_service:"Self-help Service",
        submit_feedback:"Submit Feedback",
        online_service:"Online CS",
        official_verification:"Official Verification",
        myservice:"My Feedback",
        questionTabs_1:"Hot Issues",
        questionTabs_2:"Top-up Related",
        questionTabs_3:"Game Related",
        questionTabs_4:"Login Related",
        questionTabs_999:"More",
        question_detail:"Details",
        question_common:"FAQ",
        search_empty:"No results",
        feedback_detail:"Feedback Details",
        contact_email:"Contact Mailbox",
        screenshot:"Screenshot",
        detailed_info:"Details",
        my_reply:"My Reply",
        kf_reply:"Reply from CS /0/",
        input_feedback:"Please describe the issue in detail",
        service_score:"Please rate this service",
        score:"/0/",
        scored:"Rated, thanks for your feedback",
        thank_feedback:"Rated, thanks for your feedback",
        score_tips:"Please choose a rating",
        feedback_processing:"Processing your feedback, please wait...",
        feedback_tips:"If the issue remains unsolved, please do not submit it repeatedly",
        submit:"Submit",
        processing:"Processing",
        replied:"Replied",
        completed:"Completed",
        uploading:"Uploading...",
        upload_failed:"Uploading failed",
        upload_tips:"Please wait for the image to be uploaded",
        robot_name:"Bot",
        robot_name2:"Bot at your service",
        welcom_msg:"Thanks for your visit. You can inquire by texting the keyword and Bot will be helping you.",
        change_batch:"New Batch",
        forget_pwd:"Forgot Password",
        game_web:"Official Website",
        contact_customer:"Real Personnel",
        ques_placeholder:"Please enter the issue",
        send:"Send",
        connection:"Connecting to a chat...",
        enter_question:"Please enter the issue first",
        are_you_asking:"Are you asking about",
        robot_not_found:"Sorry, I don't quite follow. In order the issue is properly addressed, I suggest you put it another way. If you need real customer service, please tap 'Real Personnel'.",
        customer_service:"Real Customer Service",
        feedback_0:"Top-up uncompleted",
        feedback_1:"Bugs",
        feedback_2:"Report",
        feedback_3:"Suggestions",
        feedback_4:"Retrieve Account",
        feedback_5:"Submit screenshot",
        feedback_server:"Server",
        feedback_rolename:"Character Name",
        feedback_price:"Order Amount",
        feedback_order:"Order Number",
        feedback_report:"Report",
        feedback_username:"Account",
        feedback_registerTime:"Register date:",
        feedback_registerDevice:"Device:",
        feedback_lastLoginTime:"Last login",
        feedback_lastPayTime:"Last month top-up time",
        feedback_lastPayPrice:"Last month top-up amount",
        feedback_lastPayOrder:"Last month top-up order number",
        feedback_server_tips:"Please enter the server name",
        feedback_rolename_tips:"Please enter the character name",
        feedback_price_tips:"Please enter the order amount",
        feedback_order_tips:"Please enter the order number",
        feedback_report_tips:"Please enter the character you wish to report",
        feedback_username_tips:"Please enter the account you wish to retrieve",
        feedback_registerTime_tips:"Please enter the data the account was registered",
        feedback_registerDevice_tips:"Please enter the model of the registration device",
        feedback_lastLoginTime_tips:"Please enter the last login time",
        feedback_lastPayTime_tips:"Please enter the data of your last month top-up",
        feedback_lastPayPrice_tips:"Please enter the amount of your last month top-up",
        feedback_lastPayOrder_tips:"Please enter the order number of your last month top-up",
        order_helper:"How do I query the order number?",
        max_img:"Max 3 images",
        contact:"Contact Info",
        detailed_desc:"Details",
        myservice_count:"Processing your",
        myservice_count2:"feedbacks currently ",
        nomore:"Nothing more",
        feedback_state:"Status",
        feedback_type:"Type",
        create_time:"Creation Time",
        gift_title:"Game Packs",
        mygift_title:"My Packs",
        recommend:"Recommended Game",
        popularity:"Popular",
        play:"New",
        hot:"Hot",
        gift_empty:"No packs, please stay tuned",
        input_contact_email:"Please enter the contact mailbox",
        same_password:"Consistent passwords. No need to change",
        success:"Operation successful",
        input_email:"Please enter the mailbox",
        email:"Mailbox",
        forgot:"Retrieve Password",
        forgot_from_email:"Through E-mail",
        forgot_from_service:"Through customer service",
        reset_password:"Confirm and Reset",
        unbind_email:"Must link with a mailbox to unlink",
        unbind_fb:"By unlinking, you may not log in to this account with Facebook Account. Unlink anyway?",
        unbind_google:"By unlinking, you may not log in to this account with Google Account. Unlink anyway?",
        unbind_twitter:"By unlinking, you may not log in to this account with Twitter Account. Unlink anyway?",
        tips_title:"Prompt",
        copy_success:"Copied successfully",
        copy_faild:"Failed to copy. The browser doesn't support copying",
        gift_rate_task:"Please complete your comment before claiming this pack",
        requesting:"Requesting",
        get_gift:"Claim Pack",
        gift_rate:"Go Comment",
        gift_rate2:"Go Rate",
        gift_share:"Go Share",
        gift_bind:"Go Link",
        gift_fill:"Go Fill In",
        gift_ads:"Watch Now",
        gift_copy:"Copy Gift Code",
        gift_exchange:"Redemption",
        gift_exchange_desc:"Perks - Activation Code - Enter the Gift Code to activate",
        password_help:"How do I view my account password?",
        password_help_1:"1. Tap the avatar on the upper left corner",
        password_help_2:"2. Tap the red button 'User Center' at the bottom",
        password_help_3:"3. Tap 'Switch Account'",
        password_help_4:"4. Tap the Eye Icon to view the current account's password",
        payhelp_title:"Please select a payment platform",
        payhelp_google:"Query using Google play",
        payhelp_google1:"1. Open google play and click on the left menu bar",
        payhelp_google2:"2. Click on My Account",
        payhelp_google3:"3. Click on the order record",
        payhelp_google4:"4. Click the corresponding order to view the receipt",
        payhelp_wechat:"Query using wechat",
        payhelp_wechat1:"1. Click to open wechat",
        payhelp_wechat2:"2. Click the [Me] option",
        payhelp_wechat3:"3. Click to pay - wallet",
        payhelp_wechat4:"4. Click Bill",
        payhelp_wechat5:"5. Click the corresponding order to view the order receipt",
        payhelp_paypal:"Inquiry using paypal",
        payhelp_paypal1:"1. Log in to paypal account",
        payhelp_paypal2:"2. Click on the transaction record",
        payhelp_paypal3:"3. Click to view the details",
        payhelp_paypal4:"4. Click on the corresponding order to view the details",
        payhelp_alipay:"Query with Alipay",
        payhelp_alipay1:"1. Click to open Alipay",
        payhelp_alipay2:"2. Click the [My] option",
        payhelp_alipay3:"3. Click Bill",
        payhelp_alipay4:"4. Click on the corresponding order to view the receipt",
        bind_mobile:"bind mobile phone",
        bind_mobile_tips:"You are binding a mobile phone number to your account. Binding a mobile phone number can effectively protect the security of your account. You can retrieve your password through your mobile phone number。",
        input_old_mobile:"Please enter your old phone number",
        input_validator_mobile:"Please enter your phone number correctly",
        input_bind_mobile:"Please enter the phone number you want to bind",
        mobile_tips:"Some mobile phone numbers, the information may be misjudged as spam information, please go to [spam mailbox] to check the verification code。",
        contact_mobile:"Contact phone number",
        input_contact_mobile:"Please enter the contact phone number",
        input_mobile:"Please enter your phone number",
        mobile:"Mobile",
        unbind_mobile:"Bind the mailbox first and then unbind it",
        input_old_verify_mobile:"Please enter the verification code of your old phone number",
        input_new_verify_mobile:"Please enter the phone number verification code",
        pay_title:"Total about",
        pay_change_currency:"Switch currency",
        pay_tips:"(The final payment amount will vary according to different payment methods and exchange rates)",
        pay_select_mode:"Select payment method",
        wechat:"WeChat Pay",
        alipay:"Alipay",
        pay_paying:"Submitting payment...",
        pay_fail:"Failed to top-up. Please try again later.",
        pay_return_tips:"Please close this page if payment has been completed, or another payment will be initiated.",
        pay_discount:"(Discounts /0/%)",
        pay_late_mode:"Recently selected payment method",
        pay_other_mode:"Other Payment",
        pay_more:"More methods",
        pay_point:"/0/ pts to get /1/ pts free",
        points:"Top-up for points",
        tally:"Points",
        give_away:"Including gifts",
        voucher:"Vouchers",
        waiting_used:"To be used",
        current_points:"Current Points:",
        pay:"Top Up",
        pwd:"Password",
        pay_setting:"Set payment password",
        select_amount:"Please select the points to top up",
        forget:"Retrieve payment password",
        forget_pay:"Forget payment password",
        input_pwd:"Please enter a 6-digit payment password",
        different:"Password don't match",
        setting_tips:"You are setting a payment password. Please keep your payment password secure",
        remain:"Remain",
        add:"Top-up for points",
        pay_mode:"Payment Method",
        change_payPwd_tips:"You are changing your payment password. Please keep your new payment password secure",
        unsetting:"No payment password",
        email_unbound:"No linked email",
        confirm_pay_pwd:"Confirm payment password",
        input_confirm_pay_pwd:"Please re-enter payment password",
        payPassword_error:"Wrong payment password",
        balance:"Insufficient Balance",
        gamePoints:"Points",
        pay_complete:"Payment complete",
        pay_new_tips:"Don't close this window until top-up is completed",
        pay_new_message:"Please complete top-up in the new page",
        pay_cancel:"Cancel payment",
        back:"Back",
        fee_name:"Affiliate Marketing Program",
        fee_text1:"Work with HandZone Games",
        fee_text2:"As an affiliate of HandZone Games, you will get a unique affiliate link. You will get a certain commission on the amount topped up by users who download and install the game through your link.",
        fee_text3:"You need to meet the following requirements before you can join the Affiliate Marketing Program of HandZone Games.",
        fee_price:"Equal to top-up amount:",
        fee_text4:"The Affiliate Marketing Program will be available to you when the requirements are met, and you will get 10%-70% (varies based on the affiliate levels) of the amount topped up by new players who install the game through your promotion.",
        fee_text5:"Congratulations! You've joined the Affiliate Marketing Program",
        fee_text6:"Your affiliate link:",
        fee_copy:"Copy link",
        fee_text7:"The current commissions of affiliate levels:",
        fee_text8:"A.B.C top up respectively /0/",
        fee_text9:"You will get:",
        fee_text10:"A has no limit",
        fee_text11:"Currently you have /0/ Lv.1 affiliates, /1/ Lv.2 affiliates, /2/ Lv.3 affiliates",
        fee_price2:"Amount available for withdrawal:",
        fee_start:"Start to promote",
        fee_submit:"Withdrawal",
        fee_tips1:"Withdrawal requirements not met",
        contact_kf:"Contract the customer service",
        fee_tips2:"Contract the customer service for withdrawals",
        account_z:"account",
        account_destroy:"log out of an account",
        account_destroy_now:"Apply for cancellation of account",
        account_destroy_cancel:"Cancel account",
        account_destroy_time:"Remaining time of account cancellation：",
        account_destroy_time2:"/0/ the queen of heaven",
        account_destroy_cancel_tips:"You have cancelled your account cancellation request",
        account_destroy_read_tips:"I have seen and agree",
        account_destroy_read_tips2:"《Account cancellation notice》",
        account_destroy_content1:"● 1. After successful logout, the account will <a>You cannot log in on our account platform or any of our products</a> also <a>The account and data cannot be recovered</a>；",
        account_destroy_content2:"● 2. Account cancellation requirement <a>Collect your personal and sensitive information（Include the email verification code）</a>For verification of identity, account cancellation, our company will <a> Delete the above information or anonymize it </a>；",
        account_destroy_content3:"● 3. After the account is successfully logged out，All content, information and records under this account will be stored <a> Delete or anonymize the process </a>，You can no longer retrieve, access, obtain, continue to use and retrieve, and do not have the right to ask us to retrieve（ Except as otherwise provided by laws and regulations or required by regulatory authorities）；",
        account_destroy_content4:"● 4. After account cancellation，The corresponding account trading platform will not be able to log in，<a>Your application for account cancellation indicates that you voluntarily give up the benefits under the account </a>，<a> Before cancellation，Please double check that the transaction and withdrawal application on the platform has been completed</a>，Avoid unnecessary losses caused by failure to log in after account cancellation；",
        account_destroy_content5:"● 5. After account cancellation，<a> We reserve the right to no longer provide you with any services related to this account </a>，The relevant user agreements and other obligatory rights documents signed between you and our company shall be terminated accordingly（ However, we have agreed with you to continue to take effect or laws and regulations otherwise provided for the exception）；",
        account_destroy_content6:"● 6. The inconvenience or adverse consequences caused by the cancellation of the account，At your own risk，Have nothing to do with our company；",
        account_destroy_content7:"● 7. If there is an ownership dispute during account cancellation，We reserve the right to terminate the cancellation application；",
        account_destroy_content8:"● 8. There is a 7-day confirmation period for account cancellation，During this period you can come to this screen again to cancel the logout，No cancellation will be possible after the confirmation period；",
        unbind_account_destroy:"Bind the mailbox first and then cancel the account",
        pay_go:"Go to recharge",
        either_or:" or ",
        my_info_avatar:"Avatar",
        avatar:"Avatar   ",
        look_avatar:"View Avatar",
        change_avatar:"Change avatar",
        avatar_reviewing:"under review",
        avatar_review_fail:"Audit failure",
        zbm0006:"Dear player, the custom avatar you uploaded failed to pass the examination because it involved /0/",
        shhz0001:"Politically sensitive content",
         shhz0002:"Pornographic content",
          shhz0003:"Gambling content",
        shhz0004:"Drug content",
        shhz0005:"Religious sensitive content",
        shhz0006:"With other websites or website addresses",
        shhz0007:"With advertising content",
        shhz0008:"Indecent and vulgar content",
        account:"Username",
        vip_kf:"VIP Service",
        super_vip:"Super VIP",
        super_vip_kf:"Super VIP Card Exclusive Service",
        super_vip_be:"Became a Super VIP<br/>Enjoy Exclusive Service",
        super_vip_info1:"Super VIP Card Exclusive Service",
        super_vip_info2:"(Adult players ONLY)",
        super_vip_info3:"1. Exclusive Channel<br/>One-to-one Exclusive Service. Your advice will be directly sent to the developers!<br/>2. Exclusive Priority<br/>Get priority access to the most reliable game info<br/>3. Exclusive Pack<br/>Be our Super VIP to claim super grand pack!<br/>4. Exclusive Event<br/>Exclusive irregular events and perks!",
        super_vip_gain1:"Requirements for upgrading to Super VIP",
        super_vip_gain2:"Method 1: Total Top-up amount reaches/0/",
        super_vip_get:"None",
        super_vip_gift:"Super VIP Exclusive Pack",
        super_vip_gift_tips:"Claim gift pack upon successful verification. Contact us for more info!",
        super_vip_gift_1:"In-Game Title",
        super_vip_gift_2:"Rare Decoration",
        super_vip_gift_3:"Pet",
        super_vip_gift_4:"Customized Pack",
        super_vip_gift_5:"Rare Item",
        super_vip_gift_wechat:"WeChat:",
        feedback_reward_1:"Reward Customer Service",
        feedback_reward_2:"Reward",
        feedback_reward_3:"The reward is your voluntary gift to our customer service and the full amount of it will be paid to the responsible customer service staff",
        wechat_native_1:"Payment amount:",
        wechat_native_2:"Please open your WeChat and scan the QR code to complete the payment",
        wechat_native_3:"Payment completed",
        login_usern:"Log in",
        register_username:"Sign up",
        quick_register:"Quick Signup",
        enterNow:"Log in Now",
        btn_register:"Sign up Now",
        agreed1:"Agree",
        btn_have_usern:"Have an account",
        to_login:"Go to Login",
        login_success:"Login Successful",
        agree_protocol:"Please agree to the Terms of Service",
        UserLogin:"Log in",
        UserLogin_text1:"Enter Username",
        UserLogin_text2:"Enter Password",
        UserLogin_text4:"Logging in",
        UserLogin_text5:"Signing up",
        UserLogin_text6:"Sign-up successful",
        online_earning:"Online Earning",
        online_earning_center:"Earning Center",
        online_earning_sigtiaoin:"Sign-in Challenge",
        online_earning_text1:"Complete Missions<br>For Cash",
        online_earning_text2:"Watch the video once to sign in <br/> Sign in for 7 days in total to get generous rewards",
        online_earning_text3:"Redeemable rewards",
        online_earning_text4:"Watch and claim rewards",
        online_earning_text5:"Remaining: /0/",
        online_earning_text6:"Insufficient attempts",
        online_earning_text7:"Unclaimed",
        exchange:"Redeem: /0/",
        exchange_center:"Redemption Center",
        exchange_prompt:"Redemption Tips",
        exchange_confirm:"Confirm",
        exchange_consume:"Cost: /0/",
        exchange_balance:"Balance",
        exchange_detail:"Details",
        exchange_balance_detail:"Balance Details",
        exchange_reward:"Redeem Rewards",
        exchange_text1:"/0/ Point(s)",
        exchange_text2:"$/0/",
        exchange_text3:"Points",
        exchange_text4:"Cash Packet",
        exchange_text5:"Redemption limit: /0///1/",
        exchange_text6:"Daily Limit：/0///1/",
        exchange_text7:"Enter Invitation Code to bind",
        exchange_text8:"Enter your PayPal account",
        exchange_text9:"Inviter Binding",
        exchange_text10:"Confirm",
        exchange_text11:"Redeemed. You can check the results in the User Center",
        exchange_text12:"Insufficient amount",
        exchange_text13:"Only show the latest 100 records",
        exchange_rule:"Rules",
        exchange_rule2:"Redemption Rules",
        exchange_rule2_html:"1. Email binding is required to activate Account Redemption;<br/>2. Please ensure accurate information during redemption. Any losses due to personal errors in binding or redemption are your own responsibility;<br />3. In case of redemption failure, please provide the error message. If the issue is unresolved, please contact customer service;<br />4. Initiation of a redemption implies your agreement to the rules. The final interpretation rights of redemption is held by Handzone.",
        invite_Friends:"Invite Friends",
        invite_binding:"Inviter Binding",
        new_envelope:"Beginner Packet",
        signReward:"Complete Sign-In",
        completeTask:"Complete /0/ Mission(s)",
        completeExchange:"Redeem: /0/, Cost",
        language:"language",
        country:"country / region",
        select_pay_mode:"Choose to get the right local payment",
        exchange_text14:"Not yet open",
        hyuk_jang:"piece",
        not_login:"Not logged in",
        unlock:"Log in to unlock more exciting content",
        game_hub:"Game Center",
        for_the_account:"You are binding your account",
        for_the_account_1:"An email address can effectively protect the security of your account. You can retrieve your password through the email。",
        account_length: "The account length is 6-18 characters",
        modify_successfully: "Modification successful",
        binding_successful :"Binding successful",
        start_game: "Start",
        game_down : "Download",
        new_travel: "New game",
        hint_func: "The function is not yet open, please stay tuned",
        please_acc_code:"Please enter your account",
        please_paw_code:"Please enter your password",
        agree:"Agree",
        peace:"and",
        priv_policy:"Privacy Policy",
        please_acc_pass:"please enter the account password",
        install:"Settings",
        logout:"Log out",
        is_logout_account:"Whether to log out of the account",
        top_free:"Popular Free",
        top_jackpot:"Popular Best Seller",

      
    }
}
