module.exports = {
    LOCAL:{
        zh_CN:"简体中文",
        zh_TW:"繁體中文",
        en_US:"English",
        ko_KR:"한국어",
        ru_RU:"Русский",
        de_DE:"Deutsch",
        ja_JP:"日本",
        pt_BR:"Português",
        tr_TR:"Türkiye",
        vi_VN:"Tiếng Việt",
        es_LA:"Español Americano",
        ar_SA:"عربي",
    },
    SERVER:{
        server_wz_limit_count:"횟수 한도 도달",
        server_wz_task_cd:"쿨타임 중",
        server_wz_lack_money:"잔액 불충분",
        server_result_success:"작업 완료",
        server_result_fail:"작업 실패",
    },
    LAN:{
        change_pwd:"비밀번호 변경",
        change_pwd_tips:"변경된 새 비밀번호를 안전하게 보관하십시오.",
        unbound:"(미바인딩)",
        default_language:"기본 언어",
        user_service:"사용자 서비스 계약",
        bind_email:"이메일 바인딩",
        loading:"로딩 중…",
        helper:"도움말",
        gift:"패키지",
        mine:"나의",
        confirm:"확인",
        cancel:"취소",
        bind_email_tips:"이메일 주소를 계정에 바인딩하는 중입니다. 비밀 번호 되찾기 시 이메일 이용하실 수 있습니다.",
        input_old_email:"기존 이메일 주소를 입력하십시오.",
        input_old_verify_code:"기존 이메일 인증코드를 입력하십시오.",
        input_new_verify_code:"이메일 인증코드를 입력하십시오.",
        input_validator_email:"정확한 이메일 계정을 입력하십시오.",
        input_bind_email:"바인딩하실 이메일 주소를 입력하십시오.",
        verify_code:"인증코드",
        next:"다음",
        bind_now:"즉시 바인딩",
        tips:"*알림",
        email_tips:"일부 무료 이메일 주소의 경우 문자가 스팸으로 오인될 수 있으므로 [스팸]으로 이동하여 인증 코드를 확인하십시오.",
        send_verify_code:"인증코드 발송",
        sending:"발송 중",
        send_success:"발송 완료",
        resend:"재차 발송",
        old_pwd:"기존 비밀번호",
        input_old_pwd:"기존 비밀번호를 입력하십시오.",
        new_pwd:"새 비밀번호",
        input_new_pwd:"새 비밀번호를 입력하십시오.(6-15자 이내로)",
        confirm_pwd:"새 비밀번호를 확인하십시오.",
        input_confirm_pwd:"새 비밀번호를 다시 입력하십시오.",
        change_now:"수정하기",
        switch_account:"계정 교체",
        playing:"현재 플레이 중",
        search_placeholder:"검색어를 입력해주세요",
        search:"검색",
        empty:"데이터 없음",
        self_service:"셀프 서비스",
        submit_feedback:"피드백 제출",
        online_service:"온라인 서비스",
        official_verification:"공식 검증",
        myservice:"나의 피드백",
        questionTabs_1:"주요 질문",
        questionTabs_2:"충전 관련",
        questionTabs_3:"게임 관련",
        questionTabs_4:"접속 관련",
        questionTabs_999:"추가 질문",
        question_detail:"질문 상세",
        question_common:"일반적인 문제",
        search_empty:"검색 내용 없음",
        feedback_detail:"피드백 상세",
        contact_email:"이메일 연락처",
        screenshot:"캡쳐",
        detailed_info:"상세 정보",
        my_reply:"나의 회신",
        kf_reply:"고객 서비스/0/의 회신",
        input_feedback:"문제에 대한 자세한 설명을 입력하십시오.",
        service_score:"이번 서비스에 평가해 주십시오.",
        score:"/0/점",
        scored:"피드백 감사합니다~",
        thank_feedback:"피드백 감사합니다~",
        score_tips:"평점을 선택하십시오.",
        feedback_processing:"피드백 진행 중, 잠시만 기다려 주십시오.",
        feedback_tips:"문제가 여전히 해결되지 않을 시 계속하여 문제에 대한 회신을 권장합니다.",
        submit:"제출",
        processing:"진행 중",
        replied:"완료",
        completed:"완료",
        uploading:"업로드 중…",
        upload_failed:"업로드 실패",
        upload_tips:"이미지 업로드가 완료될 때까지 기다려 주십시오.",
        robot_name:"로봇 어시스턴트",
        robot_name2:"무엇을 도와드릴까요?",
        welcom_msg:"방문해 주셔서 감사합니다.~ 질문 키워드로 질문하시면 됩니다~ 최선을 다해 도와드리겠습니다.",
        change_batch:"변경",
        forget_pwd:"비밀번호 찾기",
        game_web:"공식 홈페이지",
        contact_customer:"상담원 연결",
        ques_placeholder:"묻고 싶은 질문을 입력하십시오.",
        send:"발송",
        connection:"세션 연결 중…",
        enter_question:"먼저 질문을 입력하십시오.",
        are_you_asking:"무엇을 묻고 싶습니까?",
        robot_not_found:"내용의 의미를 이해하지 못해 죄송합니다~ 고객 서비스의 도움이 필요할 시 상담원 연결 버튼을 클릭하십시오.",
        customer_service:"상담원 연결",
        feedback_0:"충전되지 않음",
        feedback_1:"BUG 관련",
        feedback_2:"캐릭터 신고",
        feedback_3:"피드백",
        feedback_4:"계정 되찾기",
        feedback_5:"스크린샷",
        feedback_server:"소속 서버",
        feedback_rolename:"캐릭터명",
        feedback_price:"주문 금액",
        feedback_order:"판매자 주문번호",
        feedback_report:"캐릭터 신고",
        feedback_username:"계정",
        feedback_registerTime:"계정 신청 시간",
        feedback_registerDevice:"휴대폰 모델 등록",
        feedback_lastLoginTime:"최근 접속 시간",
        feedback_lastPayTime:"최근 1달 내 충전 시간",
        feedback_lastPayPrice:"최근 1달 내 충전 금액",
        feedback_lastPayOrder:"최근 1달 내 충전 주문번호",
        feedback_server_tips:"소속 서버 입력하기",
        feedback_rolename_tips:"캐릭터명 입력하기",
        feedback_price_tips:"주문 금액 입력하기",
        feedback_order_tips:"판매자 주문번호 입력하기",
        feedback_report_tips:"신고하실 캐릭터명 입력하기",
        feedback_username_tips:"되찾으실 계정 입력하기",
        feedback_registerTime_tips:"계정 신청 시간 입력하기",
        feedback_registerDevice_tips:"휴대폰 모델 입력하기",
        feedback_lastLoginTime_tips:"최근 접속 시간 입력하기",
        feedback_lastPayTime_tips:"최근 1달 내 충전 시간 입력하기",
        feedback_lastPayPrice_tips:"최근 1달 내 충전 금액 입력하기",
        feedback_lastPayOrder_tips:"최근 1달 내 충전 주문번호 입력하기",
        order_helper:"주문번호 검색 방법은?",
        max_img:"이미지, 최대 3장만 업로드 가능",
        contact:"연락처",
        detailed_desc:"상세 설명",
        myservice_count:"현재 보유 중",
        myservice_count2:"피드백 처리 중 입니다",
        nomore:"더 없음",
        feedback_state:"피드백 상태",
        feedback_type:"문제 유형",
        create_time:"생성 시간",
        gift_title:"게임 패키지",
        mygift_title:"나의 패키지",
        recommend:"추천 게임",
        popularity:"인기",
        play:"플레이",
        hot:"핫",
        gift_empty:"패키지 없음, 기다해 주십시오.",
        input_contact_email:"관련 이메일 주소를 입력하십시오.",
        same_password:"기존 비밀번호와 새 비밀번호가 일치합니다!",
        success:"작업 완료",
        input_email:"이메일 주소 입력하기",
        email:"이메일",
        forgot:"비밀번호 되찾기",
        forgot_from_email:"바인딩한 이메일을 이용해 되찾기",
        forgot_from_service:"고객 서비스를 통해 되찾기",
        reset_password:"새 비밀번호 확인하기",
        unbind_email:"먼저 이메일을 바인딩하신 후 다시 해제하십시오.",
        unbind_fb:"해제 시 Facebook 으로 이 계정에 로그인할 수 없습니다. 해제하시겠습니까?",
        unbind_google:"해제 시 Google 로 이 계정에 로그인할 수 없습니다. 해제하시겠습니까?",
        unbind_twitter:"해제 시 Twitter 로 이 계정에 로그인할 수 없습니다. 해제하시겠습니까?",
        tips_title:"알림",
        copy_success:"복사 성공~",
        copy_faild:"복사 실패, 해당 브라우저의 지원 영역이 아닙니다.",
        gift_rate_task:"댓글 작성을 완료 후 패키지를 수령하실 수 있습니다.",
        requesting:"요청 중",
        get_gift:"패키지 받기",
        gift_rate:"댓글달기",
        gift_rate2:"평가하기",
        gift_share:"공유하기",
        gift_bind:"바인딩하기",
        gift_fill:"작성하기",
        gift_ads:"지금보기",
        gift_copy:"쿠폰코드 복사",
        gift_exchange:"교환 방법",
        gift_exchange_desc:"혜택-화성화코드-쿠폰코드 입력 후 활성화",
        password_help:"계정 비밀번호 확인 방법은?",
        password_help_1:"1.게임 죄측상단 아바타 클릭",
        password_help_2:"2.하단의 '고객센터' 빨강 버튼 클릭",
        password_help_3:"3.'계정 교체' 클릭",
        password_help_4:"4.화면 중 작은 눈 모양의 버튼을 클릭 시 현재 비밀번호 조회 가능",
        payhelp_title:"결제 플랫폼을 선택하십시오.",
        payhelp_google:"Query using Google play",
        payhelp_google1:"1. Open google play and click on the left menu bar",
        payhelp_google2:"2. Click on My Account",
        payhelp_google3:"3. Click on the order record",
        payhelp_google4:"4. Click the corresponding order to view the receipt",
        payhelp_wechat:"Query using wechat",
        payhelp_wechat1:"1. Click to open wechat",
        payhelp_wechat2:"2. Click the [Me] option",
        payhelp_wechat3:"3. Click to pay - wallet",
        payhelp_wechat4:"4. Click Bill",
        payhelp_wechat5:"5. Click the corresponding order to view the order receipt",
        payhelp_paypal:"Inquiry using paypal",
        payhelp_paypal1:"1. Log in to paypal account",
        payhelp_paypal2:"2. Click on the transaction record",
        payhelp_paypal3:"3. Click to view the details",
        payhelp_paypal4:"4. Click on the corresponding order to view the details",
        payhelp_alipay:"Query with Alipay",
        payhelp_alipay1:"1. Click to open Alipay",
        payhelp_alipay2:"2. Click the [My] option",
        payhelp_alipay3:"3. Click Bill",
        payhelp_alipay4:"4. Click on the corresponding order to view the receipt",
        bind_mobile:"綁定手機",
        bind_mobile_tips:"您正在為帳號綁定手機號，綁定手機號能有效保障帳號安全，可通過手機號找回密碼。",
        input_old_mobile:"請輸入舊手機號",
        input_validator_mobile:"請正確輸入手機號",
        input_bind_mobile:"請輸入您要綁定的手機號",
        mobile_tips:"部分手機號，資訊有可能被誤判為垃圾資訊，請先至【垃圾信箱】查看獲取驗證碼。",
        contact_mobile:"聯繫手機號",
        input_contact_mobile:"請輸入聯繫手機號",
        input_mobile:"請輸入手機號",
        mobile:"手機號",
        unbind_mobile:"請先綁定郵箱後再進行解綁",
        input_old_verify_mobile:"請輸入舊手機號驗證碼",
        input_new_verify_mobile:"請輸入手機號驗證碼",
        pay_title:"이 소비량은 대략",
        pay_change_currency:"통화 전환",
        pay_tips:"(최종 결제 금액은 결제 수단 및 환율에 따라 달라집니다)",
        pay_select_mode:"지불 방법을 선택하십시오",
        wechat:"WeChat Pay",
        alipay:"Alipay",
        pay_paying:"지불",
        pay_fail:"충전 실패, 나중에 다시 시도하세요",
        pay_return_tips:"결제가 완료되면 이 페이지를 닫고, 그렇지 않으면 결제를 다시 시작하십시오.",
        pay_discount:"(할인/0/%)",
        pay_late_mode:"최근 선택한 결제 수단",
        pay_other_mode:"기타 지불 방식",
        pay_more:"더 많은 지불 방법",
        pay_point:"/0/포인트 증정/1/포인트",
        points:"게임 포인트 충전",
        current_points:"현제 포인트：",
        pay:"충전",
        pwd:"비밀번호",
        pay_setting:"결제 비밀번호 설정",
        select_amount:"충전하실 게임 포인트를 선택하세요",
        forget:"결제 비밀번호 되찾기",
        forget_pay:"결제 비밀전호 찾기",
        input_pwd:"6자리 결제 비밀번호를 입력하세요",
        different:"비밀번호가 일치하지 않습니다",
        setting_tips:"결제 비밀번호를 설정하고 있습니다. 잘 보관하시길 바랍니다",
        remain:"잔여",
        add:"게임 포인트 충전",
        pay_mode:"지불 방식",
        change_payPwd_tips:"결제 비밀번호를 변경하고 있습니다. 잘 보관하시길 바랍니다.",
        unsetting:"결제 비밀번호가 설정되지 않았습니다",
        email_unbound:"이메일을 바인딩하십시오",
        confirm_pay_pwd:"결제 비밀번호 확인",
        input_confirm_pay_pwd:"결제 비밀번호를 다시 입력해주세요",
        payPassword_error:"비밀번호가 일치하지 않습니다",
        balance:"금액 부족",
        gamePoints:"게임 포인트",
        pay_complete:"결제 완료",
        pay_new_tips:"충전이 완료될 때까지 이 창을 닫지 마십시오",
        pay_new_message:"새로 오픈된 페이지에서 충전을 완료해주세요",
        pay_cancel:"취소",
        back:"뒤로",
        fee_name:"프로모션 협동 서비스",
        fee_text1:"HandZone Games와 함께",
        fee_text2:"HandZone Games의 파트너가 되면 전용 프로모션 링크를 얻을 수 있습니다. 해당 링크를 통해 다운로드 및 설치 시 해당 충전 보너스가 주어집니다.",
        fee_text3:"HandZone Games의 프로모션 협동 서비스에 가입하려면 아래 조건을 충족해야 합니다.",
        fee_price:"동일 충전액:",
        fee_text4:"조건 충족 시 피로모션 협동 서비스가 자동 활성화되며 프로모션을 통해 귀하님의 노력으로 새로 추가된 유저의 충전의 최소 10%~70%에 해당하는 포이트(프로모션 등급에 따라 적용됨)를 적립해 드립니다.",
        fee_text5:"축하드립니다! 포로모션 협동 서비스가 활성화되었습니다.",
        fee_text6:"귀하의 프로모션 링크:",
        fee_copy:"링크 복사",
        fee_text7:"현재 등급하의 보너스 상세:",
        fee_text8:"A.B.C 별도로 충전/0/",
        fee_text9:"획득 가능：",
        fee_text10:"A 무한 발전형",
        fee_text11:"현재 프로모션 인원 1급 /0/인, 2급 /1/인, 3급 /2/인",
        fee_price2:"인출 가능한 금액:",
        fee_start:"프로모션 시작",
        fee_submit:"현금 인출",
        fee_tips1:"조건이 충족되지 않음",
        contact_kf:"고객센터에 문의",
        fee_tips2:"고객센터로 문의하십시오",
        account_destroy:"账号注销",
        account_destroy_now:"申请注销账号",
        account_destroy_cancel:"取消注销账号",
        account_destroy_time:"注销账号剩余时间：",
        account_destroy_time2:"/0/ 天后",
        account_destroy_cancel_tips:"您已取消注销账号申请",
        account_destroy_read_tips:"我已经看过并同意",
        account_destroy_read_tips2:"《账号注销须知》",
        account_destroy_content1:"● 1. 注销成功后该账号将<a>无法在我司的账号平台及旗下任一款产品上进行登录</a>且<a>账号以及数据不可恢复</a>；",
        account_destroy_content2:"● 2. 账号注销需要<a>收集您的个人敏感信息（包括邮箱验证码）</a>用于核验身份，账号注销后，我司将会<a>删除上述信息或匿名化处理</a>；",
        account_destroy_content3:"● 3. 账号成功注销后，该账号下的所有内容、信息、记录将会被<a>删除或匿名化处理</a>，您也无法再检索、访问、获取、继续使用和找回，也无权要求我司找回（但法律法规另有规定或监管部门另有要求的除外）；",
        account_destroy_content4:"● 4. 账号注销之后，对应的账号交易平台将无法登录使用，<a>您申请账号注销的行为即表明您自愿放弃该账号下的利益</a>，<a>在注销前，请再三确认平台上的交易和提现申请已完成</a>，避免账号注销后因无法登录而造成不必要损失；",
        account_destroy_content5:"● 5. 账号注销后，<a>我司有权不再为您提供任何该账户有关的服务</a>，您与我司曾签署过的相关用户协议、其他权利义务性文件等相应终止（但我司与您之间已约定继续生效的或法律法规另有规定的除外）；",
        account_destroy_content6:"● 6. 因注销账户所带来的不便或不利后果，由您自行承担，与我司无关；",
        account_destroy_content7:"● 7. 如果账号注销期间存在权属纠纷，我司有权终止注销申请；",
        account_destroy_content8:"● 8. 账号注销有7天的确认期，期间您可以再次来此界面取消注销，确认期过后将无法再取消注销；",
        unbind_account_destroy:"请先绑定邮箱后再进行注销账号",
        pay_go:"충전 바로가기",
        either_or:" or ",
        my_info_avatar:"화신",
        avatar:"화신",
        look_avatar:"아바타 보기",
        change_avatar:"아바타 변경",
        avatar_reviewing:"검토 중",
        avatar_review_fail:"감사 실패",
        zbm0006:"존경하는 유저님, 당신이 올린 사용자 정의 프로필 사진은 {0}와 관련되어 심사가 통관되지 않았습니다.",
        shhz0001:"정치적으로 민감한 내용",
         shhz0002:"에로 콘텐츠",
          shhz0003:"도박 내용",
        shhz0004:"마약 내용",
        shhz0005:"종교적 민감한 내용",
        shhz0006:"다른 사이트 또는 사이트 주소 포함",
        shhz0007:"광고 내용 포함",
        shhz0008:"고상하지 않다",
        account:"사용자 이름",
        vip_kf:"VIP 고객 서비스",
        super_vip:"슈퍼 회원",
        super_vip_kf:"슈퍼 회원카드 전용 서비스",
        super_vip_be:"슈퍼 회원 달성 시<br/>전용 고객 서비스 획득",
        super_vip_info1:"슈퍼 회원카드 전용 서비스",
        super_vip_info2:"(성인 유저에게만 개방)",
        super_vip_info3:"1.전용 채널<br/>전용 고객 서비스 1:1 케어 서비스<br/>2.전용 우선권<br/>게임 정보 우선 확인 특권<br/>3.전용 특권 패키지<br/>슈퍼 회원 달성 시 푸짐한 보상 수령 가능!<br/>4.전용 이벤트<br/>전용 특권을 위한 비정기 개방 이벤트 및 혜택!",
        super_vip_gain1:"슈퍼 회원 승급 자격 획득 방법",
        super_vip_gain2:"방법1:역대 누적 충전액 /0/달성",
        super_vip_get:"None",
        super_vip_gift:"슈퍼 VIP 전용 패키지",
        super_vip_gift_tips:"인증 성공 후 패키지 수령 가능, 고객 서비스에서 문의하십시오!",
        super_vip_gift_1:"게임 칭호",
        super_vip_gift_2:"희귀 장식",
        super_vip_gift_3:"펫",
        super_vip_gift_4:"맞춤형 패키지",
        super_vip_gift_5:"희귀 아이템",
        super_vip_gift_wechat:"WeChat:",
        feedback_reward_1:"고객센터 후원하기",
        feedback_reward_2:"후원하기",
        feedback_reward_3:"후원은 고객센터에 대한 귀하의 자발적인 증정이며 전체 금액이 고객센터로 전송됩니다",
        wechat_native_1:"결제 금액:",
        wechat_native_2:"휴대폰 WeChat을 열고 스캔하여 결제를 완료하세요",
        wechat_native_3:"결제 완료",
        login_usern:"계정 가입",
        register_username:"빠른 가입",
        quick_register:"즉시 가입",
        enterNow:"즉시 로그인",
        btn_register:"즉시 등록",
        agreed1:"동의합니다",
        btn_have_usern:"보유 계정",
        to_login:"로그인",
        login_success:"로그인 성공",
        agree_protocol:"먼저 게임 서비스 이용약관에 동의해 주세요",
        UserLogin:"로그인",
        UserLogin_text1:"사용자 이름을 입력해 주세요",
        UserLogin_text2:"비밀번호를 입력해 주세요",
        UserLogin_text4:"로그인 중",
        UserLogin_text5:"등록 중",
        UserLogin_text6:"등록 성공",
        online_earning:"수익",
        online_earning_center:"수익 센터",
        online_earning_sigtiaoin:"출석 도전",
        online_earning_text1:"임무를 완료하여<br>현찰 벌기",
        online_earning_text2:"매일 동영상 1회 시청 시 출석 완료<br/>누적 7일간 출석 시 점수 보상 획득",
        online_earning_text3:"교환 가능한 보상",
        online_earning_text4:"손쉽게 보상받기",
        online_earning_text5:"/0/회 남음",
        online_earning_text6:"횟수 부족",
        online_earning_text7:"수령 가능",
        exchange:"/0/ 교환",
        exchange_center:"교환 센터",
        exchange_prompt:"교환 알림",
        exchange_confirm:"교환 확인",
        exchange_consume:"소모: /0/",
        exchange_balance:"잔액",
        exchange_detail:"상세",
        exchange_balance_detail:"잔액 상세",
        exchange_reward:"보상 교환",
        exchange_text1:"/0/점",
        exchange_text2:"/0/원",
        exchange_text3:"게임 점수",
        exchange_text4:"현찰 레드패킷",
        exchange_text5:"영구 한정 교환: /0///1/",
        exchange_text6:"일일 한정 교환：/0///1/",
        exchange_text7:"초대 코드를 입력하여 바인딩하세요",
        exchange_text8:"PAYPAL 계정을 입력해 주세요",
        exchange_text9:"초대자 바인딩",
        exchange_text10:"바인딩 확인",
        exchange_text11:"교환 성공, 고객센터에서 결과를 확인해 주세요",
        exchange_text12:"수량 부족",
        exchange_text13:"최근 100개 기록만 표시됨",
        exchange_rule:"규칙",
        exchange_rule2:"교환 규칙",
        exchange_rule2_html:"1. 계정은 우편함을 연동해야만 계정 교환 기능을 사용할 수 있습니다.<br/>2. 교환 시 정확한 정보를 입력해 주세요. 개인적인 사유로 잘못된 계정에 연동하였거나 교환이 잘못될 경우, 이로 인해 발생한 손실은 귀하가 직접 책임지셔야 합니다.<br />3. 교환 실패 시 실패 알림 내용을 확인해 주세요. 해결이 불가할 경우, 고객센터로 문의해 주세요.<br />4. 교환을 시작하면 위의 약관을 동의하는 것으로 간주되며, 교환에 대한 최종 해석권은 Zhangyu에 있습니다.",
        invite_Friends:"친구 초대",
        invite_binding:"초대자 바인딩",
        new_envelope:"초보자 레드패킷",
        signReward:"출석 완료",
        completeTask:"임무/0/완료",
        completeExchange:"교환/0/소모",
        language:"언어",
        country:"국가/지역",
        select_pay_mode:"올바른 현지 지불을 선택하세요",
        exchange_text14:"아직 열리지 않음",
        hint_func: "해당 기능은 아직 사용할 수 없습니다. 계속 지켜봐 주시기 바랍니다.",
        game_hub:"게임 센터",
        not_login:"로그인하지 않았습니다",
        unlock:"더 흥미로운 콘텐츠를 잠금 해제하려면 로그인하세요.",
        for_the_account:"계정을 만드는 중입니다.",
        for_the_account_1:"이메일을 바인딩하면 이메일을 통해 비밀번호를 검색할 수 있습니다.",
        account_length:"계정 길이는 6~18자입니다.",
        modify_successfully:"수정되었습니다.",
        binding_successful:"바인딩 성공",
        start_game:"시작",
        game_down:"다운로드",
        new_travel:"새로운 투어",
        please_acc_code:"사용자 이름을 입력해주세요",
        please_paw_code:"비밀번호를 입력 해주세요",
        agree:"동의하다",
        install:"설정",
        logout:"로그아웃",
        is_logout_account:"로그인 계정에서 로그아웃할지 여부",
        top_free:"인기 무료",
        top_jackpot:"인기 베스트셀러",
        peace:"그리고",
        priv_policy:"개인 정보 정책",
        tally:"포인트들",
        give_away:"선물 포함",
        voucher:"바우처",
        waiting_used:"사용될"
    }
}
