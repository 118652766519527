module.exports = {
    LOCAL:{
        zh_CN:"简体中文",
        zh_TW:"繁体中文",
        en_US:"English",
        ko_KR:"한국어",
        ru_RU:"Русский",
        de_DE:"Deutsch",
        ja_JP:"日本",
        pt_BR:"Português",
        tr_TR:"Türkiye",
        vi_VN:"Tiếng Việt",
        es_LA:"Español Americano",
        ar_SA:"عربي",
    },
    SERVER:{
        server_wz_limit_count:"次数已达上限",
        server_wz_task_cd:"CD冷却中",
        server_wz_lack_money:"余额不足",
        server_result_success:"操作成功",
        server_result_fail:"操作失败",
    },
    LAN:{
        change_pwd:"修改密码",
        change_pwd_tips:"您正在为账号修改密码，请妥善保管您的新密码",
        unbound:"(未绑定)",
        default_language:"默认语言",
        user_service:"用户服务协议",
        bind_email:"绑定邮箱",
        loading:"加载中...",
        helper:"帮助",
        gift:"礼包",
        mine:"我的",
        confirm:"确定",
        cancel:"取消",
        bind_email_tips:"您正在为账号绑定邮箱，绑定邮箱能有效保障账号安全，可通过邮箱找回密码。",
        input_old_email:"请输入旧邮箱",
        input_old_verify_code:"请输入旧邮箱验证码",
        input_new_verify_code:"请输入邮箱验证码",
        input_validator_email:"请正确输入邮箱账号",
        input_bind_email:"请输入您要绑定的邮箱",
        verify_code:"验证码",
        next:"下一步",
        bind_now:"立即绑定",
        tips:"*温馨提示",
        email_tips:"部分免费email邮箱，信件有可能被误判为垃圾信，请先至【垃圾信箱】查看获取验证码。",
        send_verify_code:"发送验证码",
        sending:"发送中...",
        send_success:"发送成功",
        resend:"重新发送",
        old_pwd:"原密码",
        input_old_pwd:"请输入原密码",
        new_pwd:"新密码",
        input_new_pwd:"请输入新密码,长度6-15个字符",
        confirm_pwd:"确认新密码",
        input_confirm_pwd:"请重复输入新密码",
        change_now:"立即修改",
        switch_account:"切换账号",
        playing:"您正在玩",
        search_placeholder:"请输入搜索关键字",
        search:"搜索",
        empty:"暂无数据",
        self_service:"自助服务",
        submit_feedback:"提交反馈",
        online_service:"在线客服",
        official_verification:"官方验证",
        myservice:"我的反馈",
        questionTabs_1:"热门问题",
        questionTabs_2:"充值相关",
        questionTabs_3:"游戏相关",
        questionTabs_4:"登录相关",
        questionTabs_999:"更多问题",
        question_detail:"问题详情",
        question_common:"常见问题",
        search_empty:"无搜索内容",
        feedback_detail:"反馈详情",
        contact_email:"联系邮箱",
        screenshot:"截图",
        detailed_info:"详细信息",
        my_reply:"我的回复",
        kf_reply:"客服/0/的回复",
        input_feedback:"请您输入详细描述问题",
        service_score:"请为本次服务打分",
        score:"/0/分",
        scored:"已评分，感谢您的反馈",
        thank_feedback:"已评分，感谢您的反馈",
        score_tips:"请先选择评分",
        feedback_processing:"您的反馈正在处理中，请耐心等待哦",
        feedback_tips:"如果您的问题仍未解决，请不要提交重复反馈，您可以继续回复此问题",
        submit:"提交",
        processing:"正在处理中",
        replied:"已回复",
        completed:"已完成",
        uploading:"上传中...",
        upload_failed:"上传失败",
        upload_tips:"请等待图片上传完成",
        robot_name:"机器人小助手",
        robot_name2:"小助手为您服务",
        welcom_msg:"感谢来访~可通过发送问题关键字进行询问哦~小助手会尽量提供帮助的",
        change_batch:"换一批",
        forget_pwd:"忘记密码",
        game_web:"游戏官网",
        contact_customer:"转人工",
        ques_placeholder:"请输入您想提问的问题",
        send:"发送",
        connection:"会话连接中...",
        enter_question:"请先输入问题",
        are_you_asking:"您是想问:",
        robot_not_found:"抱歉没有明白所说的意思~为了更好的解决您的问题，建议您可以换一种问法。如您需要人工客服的帮助，请点击转人工按钮",
        customer_service:"人工客服",
        feedback_0:"充值未到账",
        feedback_1:"BUG相关",
        feedback_2:"举报角色",
        feedback_3:"建议反馈",
        feedback_4:"账号找回",
        feedback_5:"提交截图",
        feedback_server:"所在服",
        feedback_rolename:"角色名",
        feedback_price:"订单金额",
        feedback_order:"商户订单号",
        feedback_report:"举报角色",
        feedback_username:"账号",
        feedback_registerTime:"账号注册时间",
        feedback_registerDevice:"注册手机型号",
        feedback_lastLoginTime:"最后登录时间",
        feedback_lastPayTime:"最近1个月充值时间",
        feedback_lastPayPrice:"最近1个月充值金额",
        feedback_lastPayOrder:"最近1个月充值订单号",
        feedback_server_tips:"请输入所在服",
        feedback_rolename_tips:"请输入角色名",
        feedback_price_tips:"请输入订单金额",
        feedback_order_tips:"请输入商户订单号",
        feedback_report_tips:"请输入举报角色",
        feedback_username_tips:"请输入找回账号",
        feedback_registerTime_tips:"请输入账号注册时间",
        feedback_registerDevice_tips:"请输入注册手机型号",
        feedback_lastLoginTime_tips:"请输入最后登录时间",
        feedback_lastPayTime_tips:"请输入最近1个月充值时间",
        feedback_lastPayPrice_tips:"请输入最近1个月充值金额",
        feedback_lastPayOrder_tips:"请输入最近1个月充值订单号",
        order_helper:"如何查询订单号?",
        max_img:"图片，最多只能上传3个",
        contact:"联系方式",
        detailed_desc:"详细描述",
        myservice_count:"您当前有",
        myservice_count2:"个反馈正在处理中",
        nomore:"没有更多了",
        feedback_state:"反馈状态",
        feedback_type:"问题类型",
        create_time:"创建时间",
        gift_title:"游戏礼包",
        mygift_title:"我的礼包",
        recommend:"推荐游戏",
        popularity:"人气",
        play:"开始玩",
        hot:"火爆",
        gift_empty:"暂无礼包,敬请期待",
        input_contact_email:"请输入联系邮箱",
        same_password:"原始密码与新密码一致，无需修改",
        success:"操作成功",
        input_email:"请输入邮箱",
        email:"邮箱",
        forgot:"找回密码",
        forgot_from_email:"通过绑定邮箱找回",
        forgot_from_service:"联系客服找回",
        reset_password:"确认重置密码",
        unbind_email:"请先绑定邮箱后再进行解绑",
        unbind_fb:"解绑后将不能用Facebook继续登录此账号，您确认解绑吗？",
        unbind_google:"解绑后将不能用Google继续登录此账号，您确认解绑吗？",
        unbind_twitter:"解绑后将不能用Twitter继续登录此账号，您确认解绑吗？",
        tips_title:"提示",
        copy_success:"复制成功~",
        copy_faild:"复制失败,该浏览器不支持复制",
        gift_rate_task:"请正确完成评论后才可领取本礼包喔",
        requesting:"请求中",
        get_gift:"领取礼包",
        gift_rate:"前往评论",
        gift_rate2:"前往评价",
        gift_share:"前往分享",
        gift_bind:"前往绑定",
        gift_fill:"前往填写",
        gift_ads:"立即观看",
        gift_copy:"复制礼包码",
        gift_exchange:"兑换方法",
        gift_exchange_desc:"福利-启动码-输入礼包码启动",
        password_help:"如何查看自己的账号密码?",
        password_help_1:"1.在游戏内点击左上角头像",
        password_help_2:"2.点击底部的红色按钮“用户中心”",
        password_help_3:"3.点击“切换账号”",
        password_help_4:"4.点击图中的小眼睛按钮，即可查看到当前账号密码?",
        payhelp_title:"请选择支付平台",
        payhelp_google:"使用Google play进行查询",
        payhelp_google1:"1.打开google play点击左侧菜单栏",
        payhelp_google2:"2.点击我的账户",
        payhelp_google3:"3.点击订单记录",
        payhelp_google4:"4.点击对应的订单查看收据",
        payhelp_wechat:"使用Wechat进行查询",
        payhelp_wechat1:"1.点击打开wechat",
        payhelp_wechat2:"2.点击【我】的选项",
        payhelp_wechat3:"3.点击支付-钱包",
        payhelp_wechat4:"4.点击账单",
        payhelp_wechat5:"5.点击对应订单，查看订单收据",
        payhelp_paypal:"使用Paypal进行查询",
        payhelp_paypal1:"1.登录paypal账号",
        payhelp_paypal2:"2.点击交易记录",
        payhelp_paypal3:"3.点击详情查看",
        payhelp_paypal4:"4.点击对应订单查看详情",
        payhelp_alipay:"使用Alipay进行查询",
        payhelp_alipay1:"1.点击打开Alipay",
        payhelp_alipay2:"2.点击【我的】选项",
        payhelp_alipay3:"3.点击账单",
        payhelp_alipay4:"4.点击对应订单，查看收据",
        bind_mobile:"绑定手机",
        bind_mobile_tips:"您正在为账号绑定手机号。",
        input_old_mobile:"请输入旧手机号",
        input_validator_mobile:"请正确输入手机号",
        input_bind_mobile:"请输入您要绑定的手机号",
        mobile_tips:"部分手机号，信息有可能被误判为垃圾信息，请先至【垃圾短信】查看获取验证码。",
        contact_mobile:"联系手机号",
        input_contact_mobile:"请输入联系手机号",
        input_mobile:"请输入手机号",
        mobile:"手机号",
        unbind_mobile:"请先绑定邮箱后再进行解绑",
        input_old_verify_mobile:"请输入旧手机号验证码",
        input_new_verify_mobile:"请输入手机号验证码",
        pay_title:"本次消费约为",
        pay_change_currency:"切换币种",
        pay_tips:"（最终支付金额会根据不同的支付方式和汇率有所变动）",
        pay_select_mode:"请选择支付方式",
        wechat:"微信支付",
        alipay:"支付宝支付",
        pay_paying:"请求支付中...",
        pay_fail:"充值失败,请稍后再试",
        pay_return_tips:"如支付已完成，请关闭此页面，否则请重新发起支付。",
        pay_discount:"(优惠/0/%)",
        pay_late_mode:"最近选择支付方式",
        pay_other_mode:"其他支付方式",
        pay_more:"更多支付方式",
        pay_point:"/0/点送/1/点",
        points:"游戏点数充值",
        tally:"点数",
        give_away:"含赠送",
        current_points:"当前游戏点：",
        pay:"充值",
        pwd:"密码",
        pay_setting:"设置支付密码",
        select_amount:"请选择要充值的游戏点",
        forget:"找回支付密码",
        forget_pay:"忘记支付密码",
        input_pwd:"请输入6位数字支付密码",
        different:"密码和确认密码不一致",
        setting_tips:"您正在为账号设置支付密码，请妥善保管您的支付密码",
        remain:"剩余",
        add:"游戏点储值",
        pay_mode:"支付方式",
        change_payPwd_tips:"您正在为账号修改支付密码，请妥善保管您新的支付密码",
        unsetting:"未设置支付密码",
        email_unbound:"邮箱未绑定",
        confirm_pay_pwd:"确认支付密码",
        input_confirm_pay_pwd:"请重复输入支付密码",
        payPassword_error:"支付密码错误",
        balance:"余额不足",
        gamePoints:"游戏点",
        pay_complete:"支付已完成",
        pay_new_tips:"充值完成前请不要关闭此窗口",
        pay_new_message:"请在新开的页面完成充值",
        pay_cancel:"取消支付",
        back:"返回",
        fee_name:"推广合作创收服务",
        fee_text1:"与HandZone Games携手共进",
        fee_text2:"成为HandZone Games的合伙伙伴后，您就能够获得自己的推广链接，通过次链接下载安装的用户充值后，您可以获得对应的充值分红！",
        fee_text3:"如需加入HandZone Games推广合作创收服务，您需要满足下列条件。",
        fee_price:"等额充值金额:",
        fee_text4:"满足条件后将自动开启推广合作创收服务，开通后您将可获得开通合作计划后，通过您推广新增加的玩家的充值金额最低10%~70%(根据推广级别不同分成不同)的充值分成。",
        fee_text5:"恭喜您开通推广合作创收服务",
        fee_text6:"您的推广链接为：",
        fee_copy:"复制链接",
        fee_text7:"当前推广级别分红示意图如下：",
        fee_text8:"A.B.C分别充值/0/",
        fee_text9:"您可获得：",
        fee_text10:"A可以无限发展",
        fee_text11:"当前您得一级推广人员为/0/人，二级为/1/人，三级为/2/人",
        fee_price2:"可提现金额:",
        fee_start:"开始推广",
        fee_submit:"提现",
        fee_tips1:"未满足提现条件",
        contact_kf:"联系客服",
        fee_tips2:"请联系客服进行提现",
        account_z:"账号",
        account_destroy:"账号注销",
        account_destroy_now:"申请注销账号",
        account_destroy_cancel:"取消注销账号",
        account_destroy_time:"注销账号剩余时间：",
        account_destroy_time2:"/0/ 天后",
        account_destroy_cancel_tips:"您已取消注销账号申请",
        account_destroy_read_tips:"我已经看过并同意",
        account_destroy_read_tips2:"《账号注销须知》",
        account_destroy_content1:"● 1. 注销成功后该账号将<a>无法在我司的账号平台及旗下任一款产品上进行登录</a>且<a>账号以及数据不可恢复</a>；",
        account_destroy_content2:"● 2. 账号注销需要<a>收集您的个人敏感信息（包括邮箱验证码）</a>用于核验身份，账号注销后，我司将会<a>删除上述信息或匿名化处理</a>；",
        account_destroy_content3:"● 3. 账号成功注销后，该账号下的所有内容、信息、记录将会被<a>删除或匿名化处理</a>，您也无法再检索、访问、获取、继续使用和找回，也无权要求我司找回（但法律法规另有规定或监管部门另有要求的除外）；",
        account_destroy_content4:"● 4. 账号注销之后，对应的账号交易平台将无法登录使用，<a>您申请账号注销的行为即表明您自愿放弃该账号下的利益</a>，<a>在注销前，请再三确认平台上的交易和提现申请已完成</a>，避免账号注销后因无法登录而造成不必要损失；",
        account_destroy_content5:"● 5. 账号注销后，<a>我司有权不再为您提供任何该账户有关的服务</a>，您与我司曾签署过的相关用户协议、其他权利义务性文件等相应终止（但我司与您之间已约定继续生效的或法律法规另有规定的除外）；",
        account_destroy_content6:"● 6. 因注销账户所带来的不便或不利后果，由您自行承担，与我司无关；",
        account_destroy_content7:"● 7. 如果账号注销期间存在权属纠纷，我司有权终止注销申请；",
        account_destroy_content8:"● 8. 账号注销有7天的确认期，期间您可以再次来此界面取消注销，确认期过后将无法再取消注销；",
        unbind_account_destroy:"请先绑定邮箱后再进行注销账号",
        pay_go:"前往充值",
        either_or:" 或 ",
        my_info_avatar:"头像",
        avatar:"头像   ",
        look_avatar:"查看头像",
        change_avatar:"更换头像",
        avatar_reviewing:"审核中",
        avatar_review_fail:"审核失败",
        zbm0006:"尊敬的玩家，你上传的自定义头像因涉及/0/，审核未通过",
        shhz0001:"政治敏感内容",
         shhz0002:"色情内容",
          shhz0003:"赌博内容",
        shhz0004:"毒品内容",
        shhz0005:"宗教敏感内容",
        shhz0006:"带有其他网站或者网站地址",
        shhz0007:"带有广告内容",
        shhz0008:"不雅、低俗内容",
        account:"用户名",
        vip_kf:"VIP客服",
        super_vip:"超级会员",
        super_vip_kf:"超级会员专属客服",
        super_vip_be:"成为尊贵的超级会员<br/>获得专属客服",
        super_vip_info1:"超级会员卡专属服务",
        super_vip_info2:"（仅对成年玩家开放）",
        super_vip_info3:"1、专属通道<br/>专属客服一对一贴心服务，意见建议直通策划！<br/>2、专属优先<br/>游戏第一手资料，优先获取<br/>3.专属礼包<br/>成为超级会员，领取超级大礼包！<br/>4、专属活动<br/>专属不定时活动和福利！",
        super_vip_gain1:"如何获取超级会员",
        super_vip_gain2:"方法一：历史累计充值/0/ ",
        super_vip_get:"立即获取",
        super_vip_gift:"超级VIP专属礼包",
        super_vip_gift_tips:"验证成功后，可领取礼包，快去联系客服吧！",
        super_vip_gift_1:"游戏称号",
        super_vip_gift_2:"稀有装扮",
        super_vip_gift_3:"宠物",
        super_vip_gift_4:"定制礼包",
        super_vip_gift_5:"稀有道具",
        super_vip_gift_wechat:"微信号：",
        feedback_reward_1:"打赏客服",
        feedback_reward_2:"打赏",
        feedback_reward_3:"打赏是您自愿对客服的赠与，将全额打给客服",
        wechat_native_1:"支付金额：",
        wechat_native_2:"请打开手机微信，扫一扫完成支付",
        wechat_native_3:"已完成支付",
        login_usern:"登录账号",
        register_username:"注册账号",
        quick_register:"快速注册",
        enterNow:"立即登入",
        btn_register:"立即注册",
        agreed1:"我同意",
        btn_have_usern:"已有账号",
        to_login:"前往登录",
        login_success:"登录成功",
        agree_protocol:"请先同意游戏用户服务协议",
        UserLogin:"登录",
        UserLogin_text1:"请填写用户名",
        UserLogin_text2:"请填写密码",
        UserLogin_text4:"登录中",
        UserLogin_text5:"注册中",
        UserLogin_text6:"注册成功",
        online_earning:"网赚",
        online_earning_center:"网赚中心",
        online_earning_sigtiaoin:"签到挑战",
        online_earning_text1:"做任务<br>赚现金",
        online_earning_text2:"每天观看1次视频可进行签到<br/>累计签到7天可获得丰厚奖励",
        online_earning_text3:"可在兑换中心兑换奖励",
        online_earning_text4:"看一看，领取奖励",
        online_earning_text5:"剩/0/次",
        online_earning_text6:"次数不足",
        online_earning_text7:"待领取",
        exchange:"兑换/0/",
        exchange_center:"兑换中心",
        exchange_prompt:"兑换提示",
        exchange_confirm:"确认兑换",
        exchange_consume:"消耗:/0/",
        exchange_balance:"余额",
        exchange_detail:"明细",
        exchange_balance_detail:"余额明细",
        exchange_reward:"兑换奖励",
        exchange_text1:"/0/点",
        exchange_text2:"/0/美分",
        exchange_text3:"游戏点数",
        exchange_text4:"现金红包",
        exchange_text5:"永久限兑：/0///1/",
        exchange_text6:"每日限兑：/0///1/",
        exchange_text7:"请输入邀请码进行绑定",
        exchange_text8:"请输入PAYPAL账号",
        exchange_text9:"邀请人绑定",
        exchange_text10:"确认绑定",
        exchange_text11:"兑换成功，可前往用户中心查看结果",
        exchange_text12:"数量不足",
        exchange_text13:"仅展示最近100条记录",
        exchange_rule:"规则",
        exchange_rule2:"兑换规则",
        exchange_rule2_html:"1、账号需要绑定邮箱才能使用账号兑换功能；<br/>2、兑换时请填写准确信息，如因个人原因绑定到错误账号或者兑换错误，损失由您个人承担；<br />3、如兑换失败，请提供查看失败提示，无法解决时，请联 系客服处理；<br />4、您一旦发起兑换，视为同意以上规则，兑换最终解释权归我司所有",
        invite_Friends:"邀请好友",
        invite_binding:"邀请人绑定",
        new_envelope:"新人红包",
        signReward:"完成签到",
        completeTask:"完成/0/任务",
        completeExchange:"兑换/0/消耗",
        language:"语言",
        country:"国家/地区",
        select_pay_mode:"选择以获得合适的本地付款",
        exchange_text14:"暂未开启",
        game_hub:"游戏中心",
        hyuk_jang:"张",
        not_login:"未登录",
        unlock:"登录解锁更多精彩内容",
        for_the_account:"你正在为账号",
        for_the_account_1:"绑定邮箱，绑定邮箱能有效保障账号安全，可通过邮箱找回密码。",
        account_length:"账号长度为6-18位字符",
        modify_successfully: "修改成功",
        binding_successful :"綁定成功",
        start_game: "开始",
        game_down : "下载",
        new_travel: "新游",
        hint_func: "功能暂未开放，敬请期待",
        please_acc_code:"请输入账号",
        please_paw_code:"请输入密码",
        agree: "同意",
        install:"设置",
        logout:"退出登录",
        is_logout_account:"是否退出登录账号",
        top_free:"热门免费",
        top_jackpot:"热门畅销",
        peace:"和",
        priv_policy:"隐私政策",
        voucher:"代金卷",
        waiting_used:"待使用",
        pay_zx:"支付中心"
    }
}
