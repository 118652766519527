<template >
     <div class="bg" make ="1" @click="closeLogin($event)">
        <div id="login"   v-if="showRegisWin">
            <!-- 登录弹窗 -->
            <div class="login-box">
                <div class="title">
                    <img class="logo_1"  src="/img/login/logo.png" />
                    <span>{{ $t('LAN.login_usern')}}</span>
                     <!--加一个关闭图标-->
                     <img  make ="1" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAFQAAABUCAMAAAArteDzAAAAhFBMVEUAAABnZ2dmZmZmZmZmZmZlZWVnZ2dmZmZlZWVkZGRmZmZmZmZmZmZmZmZmZmZhYWFlZWVmZmZmZmZkZGRmZmZkZGRmZmZmZmZmZmZmZmZmZmZmZmZmZmZmZmZlZWVoaGhnZ2dmZmZlZWVmZmZmZmZnZ2dmZmZmZmZnZ2dlZWVmZmZqamoZuBpNAAAAKnRSTlMAE1a5+AZfQfQhyX3hGfwLm9BpRaWlD+nnw/Fje803Kx/cbz6IsKORkHQvr1VTAAACMElEQVRYw+2Y2XLiMBBFifFCFm8QbEteWbJJ//9/44ork6lpTd3ShXlIFf3Cg0oXzhHQba1udasfVlFeVdG1d6bBsR1zJnQKPuLRmZq8r3v1Gtx5R+anN1WqIq4kQfpu7Fxr79QoKbJ5o1GDfLtgbc1c4YNnarR73pi5bNOmf69Vx95+roXr2Cc1T56bZaPu7kVoq6xZajbgw75Z9tn6OInV8XVZFAYw+xJ6SCKX1NAaaQCzL1V26UrWXfAQGqcBzG6yp8clU6aujdMAZjdP2/mU3KnxPwxg9keRCQwQ7NgAz44NYHbeAGbHBhh2bIBhxwYYdmyAYccGMDtjALFzBgA7aQCwkwYAO2kAsLMGomQP2AkDJ8Dub2BTnl+0YL/QgMkONWCnDBjAzhgQ7JcZ2PyZ2Qt27rOW2Td7I9jJ1PPhK9PqYrhKZnR6qc1XhaqtrpGZFNrOcaAbes60++9zIqZi0N/nF2IqBv1dy15wMXtTKGGAY9/X9vd3fmjhRETMtJXsBTz78nuXvYBjF/91ohtexg7mAYodzAMUO5oHeHZggGIHBih2YIBiBwYodmCAYkcGGHZsgGHHBlh2bIBnxwYwO2sAs/MGwEyLDVSSfXrLBLuPgUwNkYAPFGBHBmrHDdpHaQW7jwGru1GExsoKdh8DVm8dN2iFsXOVHfFs+Hkr2e9yeVKxanR96FLmuaDWuj+7dlZD2x0TnOneud2lkXMxvZ/IO+l0nPLVrW71v+oXIxG7QEcCKioAAAAASUVORK5CYII=" alt="" class="closeBtn">
                </div>
                <div class="input-box">
                    <ul>
                      <li>
                          <span class="user"></span>
                          <input class="input-box-i" v-model="username" type="text" :placeholder="$t('LAN.please_acc_code')">
                      </li>
                      <!-- <div class="line"></div>-->
                      <li>
                            <span class="pwd"></span>
                            <input class="input-box-i" v-model="password" type="password" :placeholder="$t('LAN.please_paw_code')" maxlength="22">
                            <!-- <div id="pwdeye">
                              <i class="eye"></i>
                            </div> -->
                      </li>
                    </ul>
                </div>
                <div class="login-bottom">
                    <a class="text1" @click="showRegis">{{ $t('LAN.register_username')}}</a>
                    <a class="text2" @click="forgetShow=true">{{ $t('LAN.forgot') }}</a>
                </div>
                <div class="btn-box">
                    <a class="btn-login btn" @click="handlerLogin">{{ $t('LAN.login_usern')}}</a>
                </div>
                <!-- 其他登录方式 -->
                <div class="other_login" v-if="wayTologin">
                    <fieldset id="myDiv">
                        <legend>其他登录方式</legend>
                    </fieldset>
                    <div class="other_login_mode">


                        
                            <div style="display: flex;margin-left: 4%;" >
                                <div class="verif_login verif_1 "  v-if="gameLoginType.includes('2')">
                                    <span   @click="mailboxLogin">
                                        <div class="mail_1">
                                            <div class="mail_2" style="display: flex;">
                                                <div >
                                                    <img  class="mail_img" src="../../public/img/user/mailbox.png"/>
                                                </div>
                                                <div class="mail_span">
                                                    <span>邮箱验证码登录</span>
                                                </div>
                                    
                                            </div>
                                        </div>
                                    </span>
                                </div>

                                <div class="verif_login verif_1" v-if="gameLoginType.includes('3')">    
                                    <span  @click="mobileLogin" >
                                        <div class="mail_1">
                                            <div class="mail_2" style="display: flex;">

                                                <div style="">
                                                    <img  class="mail_img" src="../../public/img/user/mobile.png"/>
                                                </div>
                                                <div class="mail_span">
                                                    <span>手机验证码登录</span>
                                                </div>
                                            </div>
                                        </div>
                                    </span>
                                </div>

                                <div class="verif_login verif_1" v-if="gameLoginType.includes('5')">    
                                    <span  @click="fbLogin" >
                                        <div class="mail_1">
                                            <div class="mail_2"   style="display: flex;" >

                                                <div style="">
                                                    <img  class="mail_img" src="../../public/img/user/fb.png"/>
                                                </div>
                                                <div  class="mail_span" >
                                                    <span>facebook登录</span>
                                                </div>
                                            </div>
                                        </div>
                                    </span>
                                </div>
                        </div> 
                        <div id ="gooleLogin" v-if="gameLoginType.includes('4')" >
                        </div>

                    </div>
                </div>
            </div>
        </div>

        <div id="register" class="bg"  make ="1" v-if="regisShow" @click="closeLogin($event)">
            <!-- 注册弹窗 -->
            <div class="register-box">
            
                <div class="title">
                    <img class="logo_1" src="/img/login/logo.png" />
                    <span>{{ $t('LAN.register_username') }}</span></div>
                       <!--加一个关闭图标-->
                       <img  make ="1" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAFQAAABUCAMAAAArteDzAAAAhFBMVEUAAABnZ2dmZmZmZmZmZmZlZWVnZ2dmZmZlZWVkZGRmZmZmZmZmZmZmZmZmZmZhYWFlZWVmZmZmZmZkZGRmZmZkZGRmZmZmZmZmZmZmZmZmZmZmZmZmZmZmZmZlZWVoaGhnZ2dmZmZlZWVmZmZmZmZnZ2dmZmZmZmZnZ2dlZWVmZmZqamoZuBpNAAAAKnRSTlMAE1a5+AZfQfQhyX3hGfwLm9BpRaWlD+nnw/Fje803Kx/cbz6IsKORkHQvr1VTAAACMElEQVRYw+2Y2XLiMBBFifFCFm8QbEteWbJJ//9/44ork6lpTd3ShXlIFf3Cg0oXzhHQba1udasfVlFeVdG1d6bBsR1zJnQKPuLRmZq8r3v1Gtx5R+anN1WqIq4kQfpu7Fxr79QoKbJ5o1GDfLtgbc1c4YNnarR73pi5bNOmf69Vx95+roXr2Cc1T56bZaPu7kVoq6xZajbgw75Z9tn6OInV8XVZFAYw+xJ6SCKX1NAaaQCzL1V26UrWXfAQGqcBzG6yp8clU6aujdMAZjdP2/mU3KnxPwxg9keRCQwQ7NgAz44NYHbeAGbHBhh2bIBhxwYYdmyAYccGMDtjALFzBgA7aQCwkwYAO2kAsLMGomQP2AkDJ8Dub2BTnl+0YL/QgMkONWCnDBjAzhgQ7JcZ2PyZ2Qt27rOW2Td7I9jJ1PPhK9PqYrhKZnR6qc1XhaqtrpGZFNrOcaAbes60++9zIqZi0N/nF2IqBv1dy15wMXtTKGGAY9/X9vd3fmjhRETMtJXsBTz78nuXvYBjF/91ohtexg7mAYodzAMUO5oHeHZggGIHBih2YIBiBwYodmCAYkcGGHZsgGHHBlh2bIBnxwYwO2sAs/MGwEyLDVSSfXrLBLuPgUwNkYAPFGBHBmrHDdpHaQW7jwGru1GExsoKdh8DVm8dN2iFsXOVHfFs+Hkr2e9yeVKxanR96FLmuaDWuj+7dlZD2x0TnOneud2lkXMxvZ/IO+l0nPLVrW71v+oXIxG7QEcCKioAAAAASUVORK5CYII=" alt="" class="closeBtn">
                <div class="input-box">
                    <ul>
                    <li >
                        <span class="user"></span>
                        <input v-model="regusername" type="text" :placeholder="$t('LAN.please_acc_code')" class="form-input input-box-i">
                    </li>
                       <!-- <div class="line"></div>-->
                    <li>
                        <span class="pwd"></span>
                        <input v-model="regpassword" type="text" :placeholder="$t('LAN.please_paw_code')" maxlength="15" class="form-input input-box-i">
                    </li>
                    </ul>
                </div>

                <div class="agree">
                    <van-checkbox v-model="checked" checked-color="#6387ea">{{$t('LAN.agree')}}<a class="abottom" @click="handlerPrivate">《{{$t('LAN.user_service')}}》</a>
                    <span>{{$t('LAN.peace')}}</span>
                    <a class="abottom" @click="handlerPrivate">《{{$t('LAN.priv_policy')}}》</a></van-checkbox>
                </div>

                <div class="btn-box btn-box2">
                  <a href="javascript:;" class="btn-regis2 btn" @click="handlerRegister">{{$t('LAN.btn_register')}}</a>
                  <a href="javascript:;" class="yiyoutext1" @click="showLogin">  
                    <span style="color: #8c8c8c;">
                    {{$t('LAN.btn_have_usern')}}{{"?&nbsp;"}}
                     </span>{{$t('LAN.to_login')}}</a>
                </div>
       
                <!-- 其他登录方式 -->
                <div class="other_login" v-if="wayTologin">
                    <fieldset>
                            <legend>其他登录方式</legend>
                    </fieldset>
                    <div class="other_login_mode">

            
                        <div style="display: flex;margin-left: 4%;margin-top: 0.4rem;">
                            <div class="verif_login verif_1"  v-if="gameLoginType.includes('2')">
                                <span   @click="mailboxLogin">
                                    <div class="mail_1">
                                        <div class="mail_2" style="display: flex;"  >
                                            <div >
                                                <img  class="mail_img" src="../../public/img/user/mailbox.png"/>
                                            </div>
                                            <div class="mail_span">
                                                <span>  邮箱验证码登录  </span>
                                            </div>
                                
                                        </div>
                                    </div>
                                </span>
                            </div>

                            <div class="verif_login verif_1"  v-if="gameLoginType.includes('3')">    
                                <span  @click="mobileLogin" >
                                    <div class="mail_1">
                                        <div class="mail_2"  style="display: flex;">

                                            <div style="">
                                                <img  class="mail_img" src="../../public/img/user/mobile.png"/>
                                            </div>
                                            <div class="mail_span">
                                                <span>  手机验证码登录  </span>
                                            </div>
                                        </div>
                                    </div>
                                </span>
                            </div>

                            <div class="verif_login verif_1" v-if="gameLoginType.includes('5')">    
                                <span  @click="fbLogin" >
                                    <div class="mail_1">
                                        <div class="mail_2"   style="display: flex;" >

                                            <div style="">
                                                <img  class="mail_img" src="../../public/img/user/fb.png"/>
                                            </div>
                                            <div  class="mail_span" >
                                                <span>facebook登录</span>
                                            </div>
                                        </div>
                                    </div>
                                </span>
                            </div>

                        </div>


                        <div style="display: flex;">
                            <div id ="gooleLogin" class="goole_n" v-if="gameLoginType.includes('4')" >
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
        
        <!-- 隐私政策弹窗 -->
        <van-popup v-model:show="forgetShow" round :style="{ height: '90%', width: '90%', maxWidth: '440px' }">
            <iframe :src="forgetUrl" frameborder="0"/>
        </van-popup>
        <!-- 找回密碼弹窗 -->
        <van-popup v-model:show="priveShow" round :style="{ height: '90%', width: '90%', maxWidth: '440px' }">
            <iframe :src="privateUrl" frameborder="0"/>
        </van-popup>
        
        <!--  邮箱登录 -->
        <div v-if="mailboxShow">
            <div id="login" >  
                <div class="login-box">
                    <div class="title">
                        <img class="logo_1"  src="/img/login/logo.png" />
                        <span>邮箱登录</span>
                        <!--加一个关闭图标-->
                        <img  make ="1" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAFQAAABUCAMAAAArteDzAAAAhFBMVEUAAABnZ2dmZmZmZmZmZmZlZWVnZ2dmZmZlZWVkZGRmZmZmZmZmZmZmZmZmZmZhYWFlZWVmZmZmZmZkZGRmZmZkZGRmZmZmZmZmZmZmZmZmZmZmZmZmZmZmZmZlZWVoaGhnZ2dmZmZlZWVmZmZmZmZnZ2dmZmZmZmZnZ2dlZWVmZmZqamoZuBpNAAAAKnRSTlMAE1a5+AZfQfQhyX3hGfwLm9BpRaWlD+nnw/Fje803Kx/cbz6IsKORkHQvr1VTAAACMElEQVRYw+2Y2XLiMBBFifFCFm8QbEteWbJJ//9/44ork6lpTd3ShXlIFf3Cg0oXzhHQba1udasfVlFeVdG1d6bBsR1zJnQKPuLRmZq8r3v1Gtx5R+anN1WqIq4kQfpu7Fxr79QoKbJ5o1GDfLtgbc1c4YNnarR73pi5bNOmf69Vx95+roXr2Cc1T56bZaPu7kVoq6xZajbgw75Z9tn6OInV8XVZFAYw+xJ6SCKX1NAaaQCzL1V26UrWXfAQGqcBzG6yp8clU6aujdMAZjdP2/mU3KnxPwxg9keRCQwQ7NgAz44NYHbeAGbHBhh2bIBhxwYYdmyAYccGMDtjALFzBgA7aQCwkwYAO2kAsLMGomQP2AkDJ8Dub2BTnl+0YL/QgMkONWCnDBjAzhgQ7JcZ2PyZ2Qt27rOW2Td7I9jJ1PPhK9PqYrhKZnR6qc1XhaqtrpGZFNrOcaAbes60++9zIqZi0N/nF2IqBv1dy15wMXtTKGGAY9/X9vd3fmjhRETMtJXsBTz78nuXvYBjF/91ohtexg7mAYodzAMUO5oHeHZggGIHBih2YIBiBwYodmCAYkcGGHZsgGHHBlh2bIBnxwYwO2sAs/MGwEyLDVSSfXrLBLuPgUwNkYAPFGBHBmrHDdpHaQW7jwGru1GExsoKdh8DVm8dN2iFsXOVHfFs+Hkr2e9yeVKxanR96FLmuaDWuj+7dlZD2x0TnOneud2lkXMxvZ/IO+l0nPLVrW71v+oXIxG7QEcCKioAAAAASUVORK5CYII=" alt="" class="closeBtn">
                    </div>

                    <div class="input-box">
                        <ul>
                            <li >
                                <span class="spand user"></span>
                                <input v-model="email" class="input-box-i" type="text" placeholder="请输入邮箱">
                            </li>
                          
                            <li class="authCode">
                                <span class="spand pwd"></span>
                                <input v-model="code" class="input-box-i" type="text" placeholder="邮箱验证码" maxlength="4">
                            </li>
                            <li class="getCode" @click="mailboxCode">
                                <span class="code">{{getCode}}</span>
                            </li>
                        </ul>
                    </div>
                    <div class="btn-box">
                        <a class="btn-login btn" @click="emailLogin">{{ $t('LAN.login_usern')}}</a>
                    </div>

                    <!-- 其他登录方式 -->
                    <div class="other_login" v-if="wayTologin">
                        <fieldset>
                            <legend>其他登录方式</legend>
                        </fieldset>
                        <div class="other_login_mode">



                 
                        <div style="display: flex;margin-left: 4%; margin-top: 0.4rem;">


                            <div class="verif_login verif_1 ">
                                <span   @click="showLogin">
                                    <div class="mail_1">
                                        <div class="mail_2" style="display: flex;">
                                            <div >
                                                <img  class="mail_img" src="../../public/img/user/zhm.png"/>
                                            </div>
                                            <div class="mail_span">
                                                <span>  账号密码登录  </span>
                                            </div>
                                
                                        </div>
                                    </div>
                                </span>
                            </div>

                            <div class="verif_login verif_1" v-if="gameLoginType.includes('3')">    
                                <span  @click="mobileLogin">
                                    <div class="mail_1">
                                        <div class="mail_2" style="display: flex;">

                                            <div style="">
                                                <img  class="mail_img" src="../../public/img/user/mobile.png"/>
                                            </div>
                                            <div class="mail_span">
                                                <span>  手机验证码登录  </span>
                                            </div>
                                        </div>
                                    </div>
                                </span>
                            </div>

                            <div class="verif_login verif_1" v-if="gameLoginType.includes('5')">    
                                    <span  @click="fbLogin" >
                                        <div class="mail_1">
                                            <div class="mail_2"   style="display: flex;" >

                                                <div style="">
                                                    <img  class="mail_img" src="../../public/img/user/fb.png"/>
                                                </div>
                                                <div  class="mail_span" >
                                                    <span>facebook登录</span>
                                                </div>
                                            </div>
                                        </div>
                                    </span>
                                </div>

                            </div>
                        </div>

                        
                            
                        <div style="display: flex; ">
                                <div id ="gooleLogin" class="goole_n"  v-if="gameLoginType.includes('4')" >
                                </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>

        <!-- 手机号登录 -->
         <div v-if="mobileShow">
            <div id="login" >
                <div class="login-box">
                    <div class="title">
                        <img class="logo_1"  src="/img/login/logo.png" />
                        <span>手机号登录</span>
                        <!--加一个关闭图标-->
                        <img  make ="1" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAFQAAABUCAMAAAArteDzAAAAhFBMVEUAAABnZ2dmZmZmZmZmZmZlZWVnZ2dmZmZlZWVkZGRmZmZmZmZmZmZmZmZmZmZhYWFlZWVmZmZmZmZkZGRmZmZkZGRmZmZmZmZmZmZmZmZmZmZmZmZmZmZmZmZlZWVoaGhnZ2dmZmZlZWVmZmZmZmZnZ2dmZmZmZmZnZ2dlZWVmZmZqamoZuBpNAAAAKnRSTlMAE1a5+AZfQfQhyX3hGfwLm9BpRaWlD+nnw/Fje803Kx/cbz6IsKORkHQvr1VTAAACMElEQVRYw+2Y2XLiMBBFifFCFm8QbEteWbJJ//9/44ork6lpTd3ShXlIFf3Cg0oXzhHQba1udasfVlFeVdG1d6bBsR1zJnQKPuLRmZq8r3v1Gtx5R+anN1WqIq4kQfpu7Fxr79QoKbJ5o1GDfLtgbc1c4YNnarR73pi5bNOmf69Vx95+roXr2Cc1T56bZaPu7kVoq6xZajbgw75Z9tn6OInV8XVZFAYw+xJ6SCKX1NAaaQCzL1V26UrWXfAQGqcBzG6yp8clU6aujdMAZjdP2/mU3KnxPwxg9keRCQwQ7NgAz44NYHbeAGbHBhh2bIBhxwYYdmyAYccGMDtjALFzBgA7aQCwkwYAO2kAsLMGomQP2AkDJ8Dub2BTnl+0YL/QgMkONWCnDBjAzhgQ7JcZ2PyZ2Qt27rOW2Td7I9jJ1PPhK9PqYrhKZnR6qc1XhaqtrpGZFNrOcaAbes60++9zIqZi0N/nF2IqBv1dy15wMXtTKGGAY9/X9vd3fmjhRETMtJXsBTz78nuXvYBjF/91ohtexg7mAYodzAMUO5oHeHZggGIHBih2YIBiBwYodmCAYkcGGHZsgGHHBlh2bIBnxwYwO2sAs/MGwEyLDVSSfXrLBLuPgUwNkYAPFGBHBmrHDdpHaQW7jwGru1GExsoKdh8DVm8dN2iFsXOVHfFs+Hkr2e9yeVKxanR96FLmuaDWuj+7dlZD2x0TnOneud2lkXMxvZ/IO+l0nPLVrW71v+oXIxG7QEcCKioAAAAASUVORK5CYII=" alt="" class="closeBtn">
                    </div>

                    <div class="input-box">
                        <ul>
                            <li >
                                <span class="spand user"></span>
                                <span class="area" @click="nationRegion"> +(86) </span>
                                <input class="mobile" v-model="mobile" type="text" placeholder="请输入手机号">
                            </li>
                          
                            <li class="authCode">
                                <span class="spand pwd"></span>
                                <input v-model="code" class="input-box-i" type="text" placeholder="手机号验证码"   maxlength="4">
                            </li>
                            <li class="getCode">
                                <span class="code" @click="mobileCode">{{getCode}}</span>
                            </li>
                        </ul>
                    </div>
                    <div class="btn-box">
                        <a class="btn-login btn" @click="mobLogin">{{ $t('LAN.login_usern')}}</a>
                    </div>

                         <!-- 其他登录方式 -->
                         <div class="other_login" v-if="wayTologin">
                            <fieldset>
                                <legend>其他登录方式</legend>
                            </fieldset>
                            <div class="other_login_mode">
                

              
                 
                            <div style="display: flex; margin-left: 4%;margin-top: 0.4rem;">
                                <div  class="verif_login verif_1" >
                                    <span   @click="showLogin">
                                        <div class="mail_1">
                                            <div class="mail_2" style="display: flex;">
                                                <div >
                                                    <img  class="mail_img" src="../../public/img/user/zhm.png"/>
                                                </div>
                                                <div class="mail_span">
                                                    <span>  账号密码登录  </span>
                                                </div>
                                    
                                            </div>
                                        </div>
                                    </span>
                                </div>
                                
                                <div class="verif_login verif_1"  @click="mailboxLogin" v-if="gameLoginType.includes('2')">
                                    <span>

                        
                                        <div class="mail_1">
                                            <div class="mail_2" style="display: flex;">
                                                <div  >
                                                    <img  class="mail_img" src="../../public/img/user/mailbox.png"/>
                                                </div>
                                                <div class="mail_span">
                                                    <span>  邮箱验证码登录  </span>
                                                </div>
                                    
                                            </div>
                                        </div>
                                    </span>
                                </div>

                 
                                <div class="verif_login verif_1" v-if="gameLoginType.includes('5')">    
                                        <span  @click="fbLogin" >
                                            <div class="mail_1">
                                                <div class="mail_2"   style="display: flex;" >

                                                    <div style="">
                                                        <img  class="mail_img" src="../../public/img/user/fb.png"/>
                                                    </div>
                                                    <div  class="mail_span" >
                                                        <span>facebook登录</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </span>
                                    </div>
                            </div>
                            <div style="display: flex; ">
                                    <div id ="gooleLogin" v-if="gameLoginType.includes('4')" >
                                    </div>

                   
                            </div>
                        </div>
                    </div>

                </div>
            </div>  
         </div>
     </div>
</template>

<script>
import { reactive, onMounted, toRefs, nextTick } from 'vue'
import { useStore } from 'vuex'
import { useI18n } from "vue-i18n";
import { requestLogin,requestRegister,reqestDevice,requestEmailVerifycode,newLogin,requestMobileVerifycode } from "@/service/user"; // requestRegister
import { Toast ,Dialog } from "vant";
import * as system from '@/utils/system'
import { validatenull } from '@/utils/validate';
import { useRouter,useRoute } from "vue-router";
import Fingerprint2 from 'fingerprintjs2'
import md5 from 'js-md5';

export default {
    setup() {
        const store = useStore()
        const router = useRouter();
        const route = useRoute(); //获取url上的参数
        const { t } = useI18n();
        const state = reactive({
            priveShow:false,
            forgetShow:false,
            username:"",
            password:"",
            regusername:"",
            regpassword:"",
            checked:true,
            showRegisWin:true,
            forgetUrl:"",
            privateUrl:"",
            lang: t("LOCAL.en_US"),
            langPicker: false,
            langColumns: [
                { name: t("LOCAL.zh_CN"), lang: "zh_CN" },
                { name: t("LOCAL.zh_TW"), lang: "zh_TW" },
                { name: t("LOCAL.en_US"), lang: "en_US" },
                { name: t("LOCAL.ko_KR"), lang: "ko_KR" },
                { name: t("LOCAL.ru_RU"), lang: "ru_RU" },
                { name: t("LOCAL.de_DE"), lang: "de_DE" },
                { name: t("LOCAL.ja_JP"), lang: "ja_JP" },
                { name: t("LOCAL.tr_TR"), lang: "tr_TR" },
                { name: t("LOCAL.pt_BR"), lang: "pt_BR" },
                { name: t("LOCAL.vi_VN"), lang: "vi_VN" },
                { name: t("LOCAL.es_LA"), lang: "es_LA" },
                { name: t('LOCAL.ar_SA'), lang: "ar_SA"}
            ],
            mailboxShow: false,
            regisShow: false,
            mobileShow: false,
            email:"",
            code:"",
            getCode:"获取验证码",
            mobile:"",
            wayTologin: true,
            showAailbox: false,
            showMobile: false,
            showLine:false,
            gameLoginType: ''
        })
        //定时任务倒计时时间
        const countDown = reactive({
            time: 60,
            countdownTimer: null
        });

        onMounted(() => {
            store.commit('setLoginState', false)

            let username = store.getters.lastUsername
            let password = store.getters.lastPassword;
            //根据数组显示登录方式
            state.gameLoginType = store.getters.gameLoginType ? store.getters.gameLoginType : '';
            state.forgetUrl = "http://www.handzone.xyz/handzone/usercenter/findpassword.html?lang=" + store.getters.lang + "&gameId=" + store.getters.appId
   
            state.privateUrl = "https://play.handzonegame.com/privacy/index.html?tab=0&lang=" + store.getters.lang + "&gameId=" + store.getters.appId
            //初始化fb登錄sdk
            initializeFb();
            if(!store.getters.gameLoginType || store.getters.gameLoginType == "[]"){
                state.wayTologin = false;
                //清除掉登录方式
                store.commit('setLoginType', '')
            }
            if(store.getters.loginType == 1){
                state.username = username
                state.password = password
                if (validatenull(username) || validatenull(password)) {
                    showRegis()
                } else {
                    showLogin();
           
                }
                return;
            }
            if(store.getters.loginType == 2){ //默认显示邮箱登录
                mailboxLogin();
            }
            if(store.getters.loginType == 3){ //默认显示手机号登录
                mobileLogin();
            }
        });


        const initializeFb = ()=>{
                window.fbAsyncInit = function() {
                    console.log("初始化成功！！！！！！！！！")
                    window.FB.init({
                        appId      : '8415150161868868',
                        cookie     : true,
                        xfbml      : true,
                        version    : 'v2.0'
                    });
                    window.FB.AppEvents.logPageView();    
                };

                (function(d, s, id){
                    var js, fjs = d.getElementsByTagName(s)[0];
                    if (d.getElementById(id)) {return;}
                    js = d.createElement(s); js.id = id;
                    js.src = "https://connect.facebook.net/en_US/sdk.js";
                    fjs.parentNode.insertBefore(js, fjs);
                }(document, 'script', 'facebook-jssdk'));

        }

        const showRegis = () => {
            state.showRegisWin = false;
            state.regisShow = true;
            state.regusername = randomWord(1, 1) + Date.parse(new Date()) / 1000 + randomWord(1, 1);
            state.regpassword = randomWord(10, 10);

            if(state.gameLoginType.includes("4")){
                nextTick(()=>{
                    //加载谷歌登录资源
                    google_init(googleLogin);
                });
            }

        
        }
        //账号密码登录
        const showLogin = async () => {
            //打开注册弹框
            state.showRegisWin = true;
            //关闭登录弹框
            state.regisShow  = false;
            //关闭邮箱登录
            state.mailboxShow = false;
            //关闭手机号登录
            state.mobileShow = false;
            //根据游戏登录方式，显示对应登录
            if(state.gameLoginType.includes("4")){
                nextTick(()=>{
                    //加载谷歌登录资源
                    google_init(googleLogin);
                });
            }

        }



        //邮箱登录
        const mailboxLogin = () =>{
            state.email = store.getters.email;
            //关闭注册弹框
            state.regisShow = false;
            //登录弹框
            state.showRegisWin = false;
            //打开邮箱登录
            state.mailboxShow = true;
            //关闭手机号弹框
            state.mobileShow = false;
            countDown.time = 60;
            state.getCode = "获取验证码";

            if(state.gameLoginType.includes("4")){
                nextTick(()=>{
                    //加载谷歌登录资源
                    google_init(googleLogin);
                });
            }
           
        }

        //谷歌初始函数
        const google_init = (fn)=>{
            try{
                window.google.accounts.id.initialize({
                        client_id: '853899527928-f228kum3m81aud17f907gaa7rpefijef.apps.googleusercontent.com' ,//客户端ID(创建应用第三步中获得的id)  '853899527928-f228kum3m81aud17f907gaa7rpefijef.apps.googleusercontent.com'
                        callback: fn
                });
                var wid = '182';

                if( document.body.clientWidth >= 390 && document.body.clientWidth <= 413){
                    wid =   345 - (413 - document.body.clientWidth)  ;
                }

                if( document.body.clientWidth >= 414 && document.body.clientWidth <= 430){
                    wid =   355 - (430 - document.body.clientWidth) / 2 ;
                }

                if( document.body.clientWidth >=  640  ){
                    wid = 387;
                }
                window.google.accounts.id.renderButton(
                        document.getElementById("gooleLogin"),//自定义按钮
                        {
                            theme: 'outline',
                            size: 'large', 
                            logo_alignment: 'center' ,
                            width: wid,
                        }
                );
            }catch(error){
                console.log("Google登录资源文件加载失败，无法使用谷歌登录")
                document.getElementById('gooleLogin').classList.remove('goole_n')
             
            }
        }
        //谷歌登录回调
        const googleLogin = async  (response)=>{
            const responsePayload = decode_jwt(response.credential);
            const result =  await newLogin(param(4,responsePayload.sub,responsePayload.name));
            store.dispatch("login", result);
            successfully(result);
        }

        //解析jwt回调
     //解析token
        const decode_jwt = (token) => {
            var base64Url = token.split('.')[1];
            var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
            var jsonPayload = decodeURIComponent(
                window
                    .atob(base64)
                    .split('')
                    .map(function (c) {
                        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
                    })
                    .join('')
            );
            return JSON.parse(jsonPayload);
        };


        //手机号登录
        const mobileLogin = () =>{
            console.log(store.getters.mobile)
           //关闭注册弹框
            state.regisShow = false;
            state.mobile = store.getters.mobile;
            //关闭登录弹框
            state.showRegisWin = false;
            //关闭邮箱登录
            state.mailboxShow = false;
            //打开手机登录弹框
            state.mobileShow = true;
            countDown.time = 60;
            state.getCode = "获取验证码";
      
            //加载谷歌登录按钮
            if(state.gameLoginType.includes("4")){
                nextTick(()=>{
                //初始化谷歌登录参数
                google_init(googleLogin,"gooleLogin");
            })  
            }
        
        }
        //邮箱获取验证码
        const mailboxCode = async () =>{
            //校验邮箱是否正确
            var emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
            if(!emailRegex.test(state.email)){
                Toast("邮箱格式不正确！！！！")
                return;
            }
            if(state.getCode === "获取验证码"){
                 //倒计时
                countDown.countdownTimer = setInterval(startCountDown, 1000);
                //调用邮箱接口
                const result = await requestEmailVerifycode({
                    email:state.email
                });
                //提示信息
                Toast(result.msg)
            }
        }
        //手机号获取验证码
        const mobileCode = async () => {
            var mobileRegex = /^1[3|4|5|7|8|9][0-9]\d{8}$/;
            if(!mobileRegex.test(state.mobile)){
                Toast("手机号不能为空或手机号格式错误！！！")
                return;
            }
            if(state.getCode === "获取验证码"){
                 //倒计时
                countDown.countdownTimer = setInterval(startCountDown, 1000);
                //调用邮箱接口
                const result = await requestMobileVerifycode({
                    mobile:state.mobile
                });
                //提示信息
                Toast(result.msg)
            }
        }
        const emailLogin = async () =>{
            //校验邮箱是否正确
            var emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
            if(!emailRegex.test(state.email)){
                Toast("邮箱格式不正确！！！！");
                return;
            }
            if(!state.code || state.code == ""){
                Toast("验证码不能为空或验证码错误！！");
                return;
            }
            const result =  await newLogin(param(2,state.email,state.code));
            result.email =  state.email;
            result.loginType = 2;
            store.dispatch("login", result);
            successfully(result);
         }
         //手机号登录
         const mobLogin = async ()=>{
            var mobileRegex = /^1[3|4|5|7|8|9][0-9]\d{8}$/;
            if(!mobileRegex.test(state.mobile)){
                Toast("手机号不能为空或手机号格式错误！！！")
                return;
            }
            if(!state.code || state.code == ""){
                Toast("验证码不能为空或验证码错误！！");
                return;
            }
            const result =  await newLogin(param(3,state.mobile,state.code));
            result.mobile =  state.mobile;
            result.loginType = 3;
            store.dispatch("login", result);
            successfully(result);
         }

        //倒计时
        const startCountDown = ()=>{
            if(countDown.time > 0){
                state.getCode = countDown.time;
                countDown.time -=1;
            }else{
                clearInterval( countDown.countdownTimer);
                countDown.countdownTimer = null;
                countDown.time = 60;
                state.getCode = "获取验证码";
            }
        }
        //选择国家跟地区
        const nationRegion = () =>{
            console.log("sssssssss")
        }

        const randomWord = (min, max) => {
            let str = "";
            let range = min;
            let arr = ['a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n', 'o', 'p', 'q', 'r', 's', 't', 'u', 'v', 'w', 'x', 'y', 'z', '1', '2', '3', '4', '5', '6', '7', '8', '9', '0'];
            range = Math.round(Math.random() * (max - min)) + min;
            for (var i = 0; i < range; i++) {
                let pos = Math.round(Math.random() * (arr.length - 1));
                str += arr[pos];
            }
            return str;
        }

        const handlerLogin = async () => {
            if (validatenull(state.username) || validatenull(state.password)) {
                Toast(t('LAN.please_acc_pass'))
                return;
            }
            Toast.loading({ message:t('LAN.UserLogin_text4'), duration: 0, forbidClick: true});
            const result = await requestLogin({
                gameId: route.query.id ? route.query.id :   store.getters.appId,
                username: state.username,
                password: state.password,
                os: store.getters.os,
                osVersion: system.GetOs(),
                model: system.GetCurrentBrowser(),
                ssid: "",
                channel: store.getters.channel,
                locale: store.getters.lang,
                uploadLog:"1",  //store.getters.uploadLog
                recommend: route.query.recommend //推广者id
             });
            if(result.msg.indexOf("100:") != -1 ) {
                Dialog.alert({
                    title: '警告',
                    message: result.msg ,
                    width : '364px'
                    }).then(() => { //重新加载用户页
                         router.go(0)
                    });
            }else{
                router.go(0)
            }


            Toast.success(t('LAN.login_success'))
            result.loginType = 1;
            result.lastPassword = state.password;
            store.dispatch("login", result)
            //缓存用户账号以及密码

            nextTick(() => {
                var event = document.createEvent("HTMLEvents");
                event.initEvent("loginSuccess", true, true);
                window.dispatchEvent(event);

                //把标志放入缓存中
                if(route.query.test && route.query.gameId ){
                    document.cookie =  "test=" + route.query.test + "; "
                    document.cookie = "gameId="  + route.query.gameId + "; "
                }
                //根据游戏ID获取游戏的一些参数
            })
        }
        //
        const fbLogin = ()=>{
            //先验证状态是否登录
            try{
                window.FB.getLoginStatus(function(response) {
                if(response.status === "connected"){
                     fbUser();
                   //调用登录程序
                }else{
                    window.FB.login((res) => {
                        if (res.authResponse) {
                            if(res.status === "connected" ){
                                fbUser();
                            }
                        } else {
                            console.log('User cancelled login or did not fully authorize.');
                        }
                    }, { 
                        scope: 'email,public_profile', 
                        return_scopes: true 
                    });
                    }
                });
            }catch(error){
                console.log("加载FB资源文件失败，无法使用此登录");
                Toast('facebook can not utilize')
            }

        }
         
        /*
         * 获取用户信息
         */
        const fbUser =  ()=> {
            window.FB.api('/me',  async (res)=> {
                const result =  await newLogin(param(5,res.id,res.name));
                store.dispatch("login", result);
                successfully(result);
            });
        }
        //请求参数统一封装
        const param = (loginType,token,name)=>{
            var requestPara = {
                gameId: route.query.id ? route.query.id :   store.getters.appId,
                os: store.getters.os,
                osVersion: system.GetOs(),
                model: system.GetCurrentBrowser(),
                ssid: "",
                channel: store.getters.channel,
                locale: store.getters.lang,
                uploadLog:"1" , //store.getters.uploadLog
                loginType: loginType,
                recommend: route.query.recommend //推广者id
            }

            if(loginType == 5){
                requestPara.fbToken = token;
                requestPara.fbName = name
            }
            if(loginType == 3){
                requestPara.mobile =  token;
                requestPara.code =  name;
            }
            if(loginType == 2){
                requestPara.mailbox =  token;
                requestPara.code =  name;
            }
            if(loginType == 4){
                requestPara.googleToken =  token;
                requestPara.googleName =  name
            }
            return requestPara;
        }
        //登录成功统一处理
        const successfully = (result)=>{
            if(result.msg.indexOf("100:") != -1 ) {
                Dialog.alert({
                    title: '警告',
                    message: result.msg ,
                    width : '364px'
                }).then(() => { //重新加载用户页
                    router.go(0)
                });
            }else{
                router.go(0)
            }
            Toast.success(t('LAN.login_success'));
            nextTick(() => {
                var event = document.createEvent("HTMLEvents");
                event.initEvent("loginSuccess", true, true);
                window.dispatchEvent(event);

                //把标志放入缓存中
                if(route.query.test && route.query.gameId ){
                    document.cookie =  "test=" + route.query.test + "; "
                    document.cookie = "gameId="  + route.query.gameId + "; "
                }
                //根据游戏ID获取游戏的一些参数
            })
        }
        const handlerRegister = async ()=> {
            if (!state.checked) {
                //Toast("請先閱讀並同意協議")
                Toast(t('LAN.agree_protocol'))
                return
            }
            if (validatenull(state.regusername) || validatenull(state.regpassword)) {
                //Toast("請輸入賬號密碼")
                Toast(t('LAN.please_acc_pass'))
                return;
            }
            if (state.regusername.length < 6 || state.regusername.length > 18) {
                //Toast("賬號長度為6-18位字符")
                Toast(t('LAN.account_length'))
                return;
            }
            if (state.regpassword.length < 6 || state.regpassword.length > 18) {
                //Toast("密碼長度為6-18位字符")
                Toast(t('LAN.input_new_pwd'))
                return;
            }

            let deviceId = store.getters.deviceId;
            if (validatenull(deviceId)) {
                Fingerprint2.get(function(components) {
                    const values = components.map(function(component, index) {
                      if (index === 0) { //把微信浏览器里UA的wifi或4G等网络替换成空,不然切换网络会ID不一样
                        return component.value.replace(/\bNetType\/\w+\b/, '')
                      }
                      return component.value
                    })
                    // 生成最终id murmur
                    deviceId = Fingerprint2.x64hash128(values.join(''), 31)
                })
            }
            store.commit("setDeviceId", deviceId)
            let result = await reqestDevice({
                "gameId": route.query.id ? route.query.id :   store.getters.appId, 
                "deviceId": deviceId,
                "ssid": "",
                "channel": store.getters.channel,
                "browserMarking":browser()
           });

            Toast.loading({ message:t('LAN.UserLogin_text5'), duration: 0, forbidClick: true});
            await requestRegister({
                username: state.regusername,
                password: state.regpassword,
                passwordConfirmation: state.regpassword,
                gameDeviceId: result.gameDeviceId
            });
            state.username = state.regusername
            state.password = state.regpassword
            Toast.success(t('LAN.UserLogin_text6'))
            handlerLogin()
        }

        const handlerPrivate = () => {
            state.priveShow = true
        }
        //关闭弹框
        const closeLogin = (event) =>{
            //控制弹框显示
            if (event.target.getAttribute("make")) {
                //这句是说如果我们点击到了id为child以外的区域
                store.dispatch('logout')
                store.commit("setNeedLogin", false)
                router.go(0)
            }
        }
        const browser = ()=>{
                var cookieEnabled = navigator.cookieEnabled; // 返回用户浏览器是否启用了cookie
                var cpuClass = navigator.cpuClass; //返回用户计算机的cpu的型号，通常intel芯片返回"x86"（火狐没有）
                //   Win32(windows98,Me,NT,2000,xp),Mac68K(Macintosh 680x0)
                //     和ＭacPPC(Macintosh PowerPC)
                var userLanguage = navigator.userLanguage; // 用户在自己的操作系统上设置的语言（火狐没有）
                var userAgent = navigator.userAgent; //包含以下属性中所有或一部分的字符串：appCodeName,appName,appVersion,language,platform
                var systemLanguage = navigator.systemLanguage; // 用户操作系统支持的默认语言（火狐没有）
                var appCodeName = navigator.appCodeName; //与浏览器相关的内部代码名
                var appMinorVersion = navigator.appMinorVersion; //辅版本号（通常应用于浏览器的补丁或服务包)
                var language = navigator.language; //浏览器支持的语言 （IE没有）
                var onLine = navigator.onLine; //返回浏览器是否处于在线模式（IE4以上版本）
                var opsProfile = navigator.opsProfile; // 未定义   （IE、火狐没有）
                var oscpu = navigator.oscpu; //浏览器正在运行的操作系统，其中可能有CPU的信息（IE没有）
                var product = navigator.product; // 浏览器的产品名（IE没有）
                var productSub = navigator.productSub; //关于浏览器更多信息（IE没有）
                var securityPolicy = navigator.securityPolicy; // 浏览器支持的加密类型（IE没有）
                var userProfile = navigator.userProfile; //  返回一个UserProfile对象，它存储用户的个人信息（火狐没有）
                var vender = navigator.vender; // 浏览器厂商名称（IE、火狐没有）
                return md5(cookieEnabled+cpuClass+userLanguage+userAgent+systemLanguage+appCodeName+appMinorVersion+language+onLine+opsProfile+oscpu+product+productSub+securityPolicy+userProfile+vender);
        }
        return {
          ...toRefs(state),
          handlerLogin,
          handlerRegister,
          showLogin,
          showRegis,
          handlerPrivate,
          closeLogin,
          mailboxLogin,
          mobileLogin,
          mailboxCode,
          emailLogin,
          mobLogin,
          mobileCode,
          nationRegion,
          fbLogin
        }
    },
    components: {
    }
}
</script>

<style scoped lang="less">
.bg {
    width: 100vw;
    height: calc(var(--vh, 1vh) * 100);
    background-color:rgba(0,0,0,0.5);
    position:absolute;
    left:0%;
    top:0%;
    z-index: 1001;
}

iframe {
    height: 100%;
    width: 100%;
}


.spand { 
    position: absolute;
     left: 0; 
     height: 26px; 
     display: flex;
}
.input-box .user { 
    background: url(/img/login/phone-1.png) no-repeat center; 
    background-size: 18px auto; 
    position: absolute; 
    left: 0.25rem; 
    margin-top: 0.35rem;
    width: 0.62802rem;
    height: 26px; 
}

.input-box .pwd { 
    background: url(/img/login/lock-2.png) no-repeat center;
    background-size: 18px auto;
    position: absolute;  
    left: 0.25rem;  
    margin-top: 0.35rem;
    width: 0.62802rem;
    height: 26px; 
}
.eye { position: absolute; background: url(/img/login/eye.png) no-repeat center; background-size: 24px auto; right: 0; width: 40px; height: 26px;}
.eye-slash { position: absolute; background: url(/img/login/eye-slash.png) no-repeat center; background-size: 24px auto; right: 0; width: 40px; height: 26px;}

.arrow {position: absolute;left:0;width: 60px; height:55px; background: url(/img/login//arrow.png) no-repeat center; background-size: 28px auto;cursor:pointer;}

.login-box {
    position: absolute;
    width: 90vw;
    max-width: 500px;
    background-color: #ffffff;
    border-radius: 10px;
    top: 50%;
    left: 50%;
    transform: translate( -50%, -50%);
    padding-bottom: 20px;
}

.title {
    background-color: #ffffff;
    width:100%;
    height: 55px;
    border-radius: 10px 10px 0px 0px;
    text-align: center;
    color: #414141;
    font-size: 20px;
    line-height: 55px;
    border-bottom: 1px solid #e5e5e5;
}

.title .logo_1 {
    width: 0.9rem;
    position: absolute;
    left: 0.3rem;
    top: 0.3rem;

}

.input-box {
    background-color: #ffffff;
    width:92%;
    height: 110px;
    margin-left: 4%;
    margin-top: 20px;
    border-radius: 10px;
}

.input-box ul {
    position: relative;
    /*border: 1px solid #e5e5e5;*/
    border-radius: 5px;
    margin-top: 0.3rem;
  
}

.input-box ul li{
  position: relative;
  clear: both; 
  margin-top: 15px; 
  height: 1.3rem; 
  /*margin-bottom: 15px; */
  border: 1px solid #e5e5e5;
}

.input-box-i{
  float: left;
  background: none;
  border: 0;
  width: 100%;
  color: #8c8c8c;
  font-size: 16px;
  margin-left: 40px;
  margin-top: 0.4rem;
}

.line {
  border-top: 1px solid #ddd;
  margin-top: 13px;
}

a {
    text-decoration: none;
    cursor:pointer;
    color: #f3d8a9;
}
.abottom {
  border-bottom:solid #f3d8a9 1px;
}

.btn-box {
  width:92%;
  margin-left: 4%;
  margin-top: 30px;
  border-radius: 8px;
}

.btn-regis {
    display: flex;
    width: 40%;
    height: 50px;
    background-color: #6e87ea;
    border-radius: 8px;
    font-size: 18px;
    color: #fff;
    justify-content: center;
    align-items: center;
    float: left;
}

.btn-login {
    display: flex;
    width: 100%;
    height: 50px;
    background-color: #333333;
    border-radius: 8px;
    font-size: 18px;
    color: #f3d8a9;
    justify-content: center;
    align-items: center;
}

.login-bottom {
    margin-top: 20px;
    border-radius: 8px;
    font-size: 18px;
    margin-bottom: 60px;
}

.login-bottom .text1{
    display: flex;
    float: left;
    margin-left: 18px;
}

.yiyoutext1 {
    font-size: 18px;
    display: flex;
    margin-top: 15px;
    justify-content: center;
}

.login-bottom .text2{
    display: flex;
    float: right;
    margin-right: 18px;
}


.register-box {
    position: absolute;
    width: 90vw;
    max-width: 500px;
    background-color: #ffffff;
    border-radius: 10px;
    top: 50%;
    left: 50%;
    transform: translate( -50%, -50%);
    padding-bottom: 20px;
}

.btn-regis2 {
    display: flex;
    width: 100%;
    height: 50px;
    background-color: #333333;
    border-radius: 8px;
    font-size: 18px;
    color: #f3d8a9;
    justify-content: center;
    align-items: center;
    margin-top: 50px;
}


.btn-login2 {
    margin-top: 15px;
    display: flex;
    width: 100%;
    height: 50px;
    background-color: #6e87ea;
    border-radius: 8px;
    font-size: 18px;
    color: #fff;
    justify-content: center;
    align-items: center;
}

.agree {
  float: right;
  padding-top: 25px;
  padding-bottom: 15px;
  margin-right: 10px;
  font-size: 16px;
}
.closeBtn{
    display: block;
    width: 0.37333rem;
    height: 0.37333rem;
    position: absolute;
    top: 0.37333rem;
    right: 0.37333rem;
}
.other_login {
    margin: 0 auto;
    margin-top: 0.25rem;
}
.other_login fieldset {
    border: none; /* 移除fieldset的边框 */
    padding: 0; /* 移除内边距 */
    color: #cccccc;
    border-top: 1px solid #cccccc;
    margin-left: 4%;
    margin-right: 4%;
}
.other_login fieldset legend {
    text-align: center;
    padding: 0 10px;
    font-size: 0.38rem;
}
/*.other_login .other_login_mode img{
    width: 0.5rem;
    height: 0.5rem;
}*/

.other_login .other_login_mode{
     // display: flex;
    //justify-content: center;
    text-align: center;
    font-size: 0.3rem;
    margin-top: 0.25rem;
}
.other_login_mode span{
    text-align: center;
    //margin-left: 0.3rem;

	font-size: 0.35rem;
	color:#3c4043;;


        font-family: "Google Sans", arial, sans-serif;
        font-weight: 500;
        overflow: hidden;
        text-overflow: ellipsis;
        vertical-align: top;
}
.lineLogin{
    width: 0;
    border: 0.02667rem solid #666;
	margin-top: 0.06rem;
	height: 0.3rem;
}
.getCode {
	width: 2rem;
    font-size: 0.3rem;
	margin-left: 0.39rem;
	display: inline-block;
	text-align: center;
	padding-top: 0.3rem;
	background: #333333;
	color: #f3d8a9;
    border-radius: 0.19324rem;
}
.getCode span{
    width: 2rem;
    position: absolute;
    left: 0; 
    height: 26px; 
    margin-top: 0.1rem;
}
.area{
    position: absolute;
    font-size: 0.35rem;
    left: 0.7rem;
    margin-top: 0.4rem;
    width: 1.2rem;
    height: 0.62802rem;
}
.mobile{
    float: left;
    background: none;
    border: 0;
    width: 100%;
    color: #8c8c8c;
    font-size: 0.38647rem;
    margin-left: 1.8rem;
    margin-top: 0.4rem;
}
.authCode{
    width: 8.72rem;
    display: inline-block;
}
@media (min-width: 310px) and (max-width: 639px) {
    .authCode{
        width: 5.89rem;
    }
}
.mail_1{
    display: flex;
   border: 1px solid #dadce0;
   height: 1.17rem;
   border-radius: 4px;
  // margin-top: 0.4rem;

}
 .mail_2{
   // margin-top: 0.26rem;
   // margin-right: 8px;
   align-items: center;
   justify-self: center;
}
.mail_img{
    width: 0.65rem;
    height: 0.65rem;
    border-radius: 50%;
    //margin-left: 0.3rem;
    margin-left: 0.3rem;
}
#gooleLogin{
    margin-left: 4%;
    margin-right: 4%;
    margin-top: 4%;
}
.goole_n{
    margin-right: 2%;
}
.verif_login{
    margin-right: 2.3%;
    width:30.6%;
    display: grid ;
}
.fb_login{
    width: 45%;
}
.mail_span{
    text-align: left;
    margin-left: 0.2rem;
}
@media (min-width: 539px) and (max-width: 639px) {
    .other_login_mode span{
        font-size: 0.22rem;
    }
    .mail_1 {
        height: 0.77rem;
    }
    .mail_img {
        width: 0.4rem;
        height: 0.4rem;
    }
}
@media (min-width: 480px) and (max-width: 538px) {
    .other_login_mode span{
        font-size: 0.25rem;
    }
    .mail_1 {
        height: 0.78rem;
    }
    .mail_img {
        width: 0.4rem;
        height: 0.4rem;
    }
}
@media (min-width: 420px) and (max-width: 479px) {
    .other_login_mode span{
        font-size:0.3rem;
    }
    .mail_1 {
        height: 0.96rem;
    }
    .mail_img{
        width: 0.5rem;
        height: 0.5rem;
        margin-left: 0.27rem;
    }
    .verif_1{
        width: 30.4%;
    }
}
@media (min-width: 380px) and (max-width: 419px) {
    .other_login_mode span{
        font-size:0.32rem;
    }
    .mail_1 {
        height: 1rem;
    }
    .mail_img {
        width: 0.5rem;
        height: 0.5rem;
    }
    .fb_login{
        width: 42%;
    }
    .mail_img {
        margin-left: 0.2rem;
    }
    .verif_1{
        width: 30.6%;
        margin-right: 2.2%;
    }
    .mail_span{
        margin-left: 0.1rem;
    }
}

@media (min-width: 600px) and (max-width: 600px) {
    .other_login_mode span{
        font-size:0.23rem;
    }
    .mail_1 {
        height: 0.68rem;
    }
    .mail_img {
        width: 0.35rem;
        height: 0.35rem;
    }
    .fb_login{
        width: 42%;
    }
    .mail_img {
        margin-left: 0.88rem;
    }
    .verif_1{
        width: 51%;
        margin-right: 2.2%;
    }
    .verif_2{
        width: 44%;
    }
}
</style>
