import axios from '@/utils/axios'

export function requestLogin(params) {
    return axios.post('/login', params)
}

export function requestRegister(params) {
    return axios.post('/register', params)
}

export function requestInfo() {
    return axios.post('/userinfo', {})
}

export function requestEmailVerifycode(params) {
    return axios.post('/verifycode', params)
}

export function requestBindEmail(params) {
    return axios.post('/bindemail', params)
}

export function reqestDevice(params) {
    return axios.post('/device', params)
}

export function reqestPassword(params) {
    return axios.post('/password', params)
}

export function requestMobileVerifycode(params) {
    return axios.post('/verifymobile', params)
}

export function requestBindMobile(params) {
    return axios.post('/bindmobile', params)
}
export function requestCancelInfo() {
    return axios.get(`/cancel/info`, {})
}

export function requestCancelCreate(params) {
    return axios.post('/cancel/create', params)
}

export function requestCancelDelete(params) {
    return axios.post('/cancel/delete', params)
}
export function requestGetAvatar() {
    return axios.get('/avatar', {})
}
export function requestSetAvatar(params) {
    return axios.post('/avatar', params)
}

export function newLogin(params){
    return axios.post('/newLogin',params);
}

export function playerGameList(params){
    return axios.get(`/userGameList/list?uid=`+params.uid, {}) 
}