import { setStore, getStore } from '@/utils/store'
import {  LANG } from '@/configs/global'
// import { useI18n } from "vue-i18n";

const app = {
    state: {
        appId: 1040,    // 游戏id
        gameName: getStore({ name: 'gameName' }) || "",
        channel: 1 ,   // 渠道 getStore({ name: 'channel' }) || CHANNEL.GOOGLE_PLAY
        lang: getStore({ name: 'lang' }) || "zh_CN",
        os:"html5",
        deviceId: getStore({ name: 'deviceId' }) || '',
        uploadLog: getStore({ name: 'uploadLog' }) || '',  //日志服务
        sandbox:getStore({ name: 'sandbox' }) || 0,   //沙盒模式
        test: getStore({ name: 'test' }) || 0  ,       //是否测试模式 0-等于正常模式 1-登录测试模式
        gameId:  getStore({ name: 'gameId' }) || '',
        gameLoginType: getStore({ name: 'gameLoginType' }) || '',
        version: getStore({ name: 'version' }) || ''
    },

    mutations: {
        setGameId(state, value) {
            state.gameId = value
            setStore({ name: 'gameId', content: state.gameId })
        },
        setGameName(state, value) {
            state.gameName = value
            setStore({ name: 'gameName', content: state.gameName })
        },
        setChannel(state, value) {
            state.channel = value
            setStore({ name: 'channel', content: state.channel })
        },
        setUploadLog(state,value){
            state.uploadLog = value
            setStore({ name: 'uploadLog', content: state.uploadLog })
        },
        setLang(state, value) {
            if (!LANG[value]) {
                value = LANG.EN_US
            }
            state.lang = value
            setStore({ name: 'lang', content: state.lang })
            if (state.lang == 'ar_SA') {
                document.querySelector('html').setAttribute('lang', "ar");
                document.querySelector('html').setAttribute('dir', 'rtl');
            } else {
                document.querySelector('html').removeAttribute('dir')
            }
        },
        setOfficial(state, value) {
            state.official = value
            setStore({ name: 'official', content: state.official })
        },
        setDeviceId(state, value) {
            state.deviceId = value
            setStore({ name: 'deviceId', content: state.deviceId })
        },
        setSandbox(state,value){
            state.sandbox = value;
            setStore({ name: 'sandbox', content:  state.sandbox })
        },
        setTest(state,value){
            state.test = value;
            setStore({ name: 'test', content:  state.test })
        },
        setGameLoginType(state,value){
            state.gameLoginType = value;
            setStore({
                name: 'gameLoginType' , content: state.gameLoginType
            })
        },
        setVersion(state,value){
            state.version = value;
            setStore({
                name: 'version' , content: state.version
            })
        }
    },

    actions: {
        initGame (state, info) {
           // state.commit('setGameId', parseInt(info.gameId))
            state.commit('setGameName', info.gameName)
            state.commit('setChannel', parseInt(info.channel))
            if (info.lang) {
                state.commit('setLang', info.lang)
            }
        },
        gameLoginType(state,info){
            if(info && info.gameLoginType){
                state.commit('setGameLoginType', info.gameLoginType);
                return;
            }
            state.commit('setGameLoginType', '');
        }
    }
}

export default app;