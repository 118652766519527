module.exports = {
    LOCAL:{
        zh_CN:"简体中文",
        zh_TW:"繁体中文",
        en_US:"English",
        ko_KR:"한국어",
        ru_RU:"Русский",
        de_DE:"Deutsch",
        ja_JP:"日本",
        pt_BR:"Português",
        tr_TR:"Türkiye",
        vi_VN:"Tiếng Việt",
        es_LA:"Español Americano",
        ar_SA:"عربي",
    },
    SERVER:{
        server_wz_limit_count:"تم الوصول إلى الحد الأقصى",
        server_wz_task_cd:"في فتره الانتظار",
        server_wz_lack_money:"رصيد غير كاف",
        server_result_success:"نجاح",
        server_result_fail:"فشل",
    },
    LAN:{
        change_pwd:"تغيير كلمة المرور",
        change_pwd_tips:"أنت تقوم بتغيير كلمة مرور الحساب ، يرجى الاحتفاظ بكلمة المرور الجديدة",
        unbound:"(غير مربوط)",
        default_language:"اللغة الافتراضية",
        user_service:"شروط الخدمة",
        bind_email:"الربط بالبريد الإلكتروني",
        loading:"تحميل...",
        helper:"مساعدة",
        gift:"حزمة",
        mine:"الخاص بي",
        confirm:"تأكيد",
        cancel:"إلغاء",
        bind_email_tips:"ربط حسابك بالبريد الإلكتروني. من خلال القيام بذلك ، ستتم حماية حسابك بشكل أفضل ويمكنك استرداد كلمة مرورك عبر بريدك الإلكتروني المربوط.",
        input_old_email:"الرجاء إدخال بريدك الإلكتروني السابق",
        input_old_verify_code:"الرجاء إدخال رمز التحقق من بريدك الإلكتروني السابق",
        input_new_verify_code:"الرجاء إدخال رمز التحقق الخاص ببريدك الإلكتروني",
        input_validator_email:"يرجى إدخال عنوان البريد الإلكتروني الصحيح",
        input_bind_email:"الرجاء إدخال البريد الإلكتروني الذي ترغب في ربطة",
        verify_code:"رمز التحقق",
        next:"التالي",
        bind_now:"ربط الآن",
        tips:"*تذكير",
        email_tips:"قد يتم تقيم رسالتنا بشكل خاطئ من خلال بعض رسائل البريد الإلكتروني المجانية كرسائل غير مرغوب فيها. يرجى الذهاب إلى [مجلد الرسائل غير المرغوب فيها] للتحقق من رمز التحقق. ",
        send_verify_code:"أرسل رمز التحقق",
        sending:"إرسال...",
        send_success:"تم الإرسال بنجاح",
        resend:"إعادة إرسال",
        old_pwd:"كلمة المرور السابقة",
        input_old_pwd:"الرجاء إدخال كلمة المرور السابقة",
        new_pwd:"كلمة المرور الجديدة",
        input_new_pwd:"الرجاء إدخال كلمة المرور الجديدة (بين 6 و 15 حرف)",
        confirm_pwd:"تأكيد كلمة المرور",
        input_confirm_pwd:"الرجاء إدخال كلمة المرور الجديدة مرة أخرى",
        change_now:"تغيير الآن",
        switch_account:"تبديل الحساب",
        playing:"أنت تلعب",
        search_placeholder:"الرجاء إدخال الكلمة",
        search:"بحث",
        empty:"لا يوجد بيانات",
        self_service:"خدمة المساعدة الذاتية",
        submit_feedback:"إرسال ملاحظات",
        online_service:"CS متصل",
        official_verification:"التحقق الرسمي",
        myservice:"ملاحظاتي",
        questionTabs_1:"قضايا ساخنة",
        questionTabs_2:"الشحن",
        questionTabs_3:"اللعبة",
        questionTabs_4:"تسجيل الدخول",
        questionTabs_999:"المزيد",
        question_detail:"التفاصيل",
        question_common:"الأسئلة الشائعة",
        search_empty:"لا يوجد نتائج",
        feedback_detail:"تفاصيل الملاحظات",
        contact_email:"تواصل بالبريد الاكتروني",
        screenshot:"لقطة شاشة",
        detailed_info:"التفاصيل",
        my_reply:"الرد الخاص بي",
        kf_reply:"الرد من CS /0/",
        input_feedback:"يرجى وصف المشكلة بالتفصيل",
        service_score:"يرجى تقييم هذه الخدمة",
        score:"/0/",
        scored:"مصنفة ، شكرا لملاحظاتك",
        thank_feedback:"مصنفة ، شكرا لملاحظاتك",
        score_tips:"الرجاء اختيار تصنيف",
        feedback_processing:"جاري معالجة ملاحظاتك ، الرجاء الانتظار ...",
        feedback_tips:"إذا ظلت المشكلة دون حل ، فيرجى عدم إرسالها بشكل متكرر. يمكنك الرد هنا مرة أخرى",
        submit:"رفع",
        processing:"جاري المعالجة",
        replied:"تم الرد",
        completed:"مكتمل",
        uploading:"جاري التحميل ...",
        upload_failed:"فشل التحميل",
        upload_tips:"يرجى الانتظار حتى يتم تحميل الصورة",
        robot_name:"الروبوت",
        robot_name2:"الروبوت في خدمتك",
        welcom_msg:"شكرا لزيارتكم. يمكنك الاستفسار عن طريق إدخال الكلمة الأساسية وسوف يساعدك الروبوت.",
        change_batch:"دفعة أخرى",
        forget_pwd:"نسيت كلمة السر",
        game_web:"الموقع الرسمي",
        contact_customer:"خدمة الموظفين",
        ques_placeholder:"يرجى وصف المشكلة",
        send:"إرسال",
        connection:"جاري الاتصال ...",
        enter_question:"الرجاء إدخال المشكلة أولا",
        are_you_asking:"هل تحاول أن تسأل:",
        robot_not_found:"آسف ، أنا لا أتابعك تمامًا. لحل مشكلتك بشكل أفضل ، أقترح عليك وضعها بطريقة أخرى. إذا كنت بحاجة إلى خدمة من موظفينا ، فالرجاء النقر فوق أيقونة خدمة الموظفين.",
        customer_service:"خدمة الموظفين",
        feedback_0:"الشحن غير مكتمل",
        feedback_1:"المشاكل",
        feedback_2:"تقرير",
        feedback_3:"اقتراحات",
        feedback_4:"استرجاع الحساب",
        feedback_5:"إرسال لقطة الشاشة",
        feedback_server:"سيرفر",
        feedback_rolename:"اسم الشخصية",
        feedback_price:"مبلغ الطلب",
        feedback_order:"رقم الطلب",
        feedback_report:"تقرير",
        feedback_username:"الحساب",
        feedback_registerTime:"وقت التسجيل",
        feedback_registerDevice:"الجهاز",
        feedback_lastLoginTime:"آخر تسجيل دخول",
        feedback_lastPayTime:"الشحن الشهر الماضي",
        feedback_lastPayPrice:"مبلغ الشحن الشهر الماضي",
        feedback_lastPayOrder:"رقم طلب الشحن الشهر الماضي",
        feedback_server_tips:"الرجاء إدخال اسم السيرفر",
        feedback_rolename_tips:"الرجاء إدخال اسم الشخصية",
        feedback_price_tips:"الرجاء إدخال مبلغ الطلب",
        feedback_order_tips:"الرجاء إدخال رقم الطلب",
        feedback_report_tips:"الرجاء إدخال الشخصية التي ترغب في الإبلاغ عنها",
        feedback_username_tips:"الرجاء إدخال الحساب الذي ترغب في استرداده",
        feedback_registerTime_tips:"الرجاء إدخال وقت تسجيل الحساب",
        feedback_registerDevice_tips:"الرجاء إدخال الجهاز الذي استخدمته للتسجيل",
        feedback_lastLoginTime_tips:"الرجاء إدخال وقت تسجيل الدخول الأخير الخاص بك",
        feedback_lastPayTime_tips:"الرجاء إدخال وقت الشحن الشهر الماضي",
        feedback_lastPayPrice_tips:"الرجاء إدخال مبلغ الشحن الشهر الماضي",
        feedback_lastPayOrder_tips:"الرجاء إدخال رقم الطلب الشهر الماضي",
        order_helper:"كيف يمكنني البحث عن رقم طلبي؟",
        max_img:"يمكن تحميل ما يصل إلى 3 صور",
        contact:"معلومات الاتصال",
        detailed_desc:"التفاصيل",
        myservice_count:"جاري المعالجة الخاص بك",
        myservice_count2:"الملاحظات الحالية ",
        nomore:"لا يوجد المزيد",
        feedback_state:"وضع",
        feedback_type:"نوع",
        create_time:"وقت الابتكار",
        gift_title:"حزم اللعبة",
        mygift_title:"الحزم الخاص بي",
        recommend:"الألعاب الموصى بها",
        popularity:"شائع",
        play:"جديد",
        hot:"ساخن",
        gift_empty:"لا يوجد حزم ، يرجى الترقب",
        input_contact_email:"الرجاء إدخال البريد الإلكتروني للاتصال",
        same_password:"كلمة المرور التي أدخلتها هي نفسها كلمة مرورك السابقة. لا حاجة للتغيير",
        success:"نجاح",
        input_email:"يرجى إدخال عنوان البريد الإلكتروني",
        email:"البريد الإلكتروني",
        forgot:"استرجاع كلمة المرور",
        forgot_from_email:"من خلال البريد الإلكتروني المربوط",
        forgot_from_service:"من خلال خدمة العملاء",
        reset_password:"التأكيد لإعادة تعيين كلمة المرور",
        unbind_email:"يرجى ربط بريدك الإلكتروني قبل إلغاء الربط",
        unbind_fb:"عند إلغاء الربط ، لن تتمكن من تسجيل الدخول إلى هذا الحساب باستخدام حسابك على Facebook. هل تريد إلغاء الربط على أي حال؟",
        unbind_google:"عند إلغاء الربط ، لن تتمكن من تسجيل الدخول إلى هذا الحساب باستخدام حساب Google الخاص بك. هل تريد إلغاء الربط على أي حال؟",
        unbind_twitter:"عند إلغاء الربط ، لن تتمكن من تسجيل الدخول إلى هذا الحساب باستخدام حساب Twitter الخاص بك. هل تريد إلغاء الربط على أي حال؟",
        tips_title:"نصائح",
        copy_success:"نسخ~",
        copy_faild:"فشل النسخ. المتصفح لا يدعم النسخ",
        gift_rate_task:"يرجى كتابة تعليقك قبل استلام هذه الحزمة",
        requesting:"جاري الطلب",
        get_gift:"استلام الحزمة",
        gift_rate:"الذهاب إلى التعليق",
        gift_rate2:"الذهاب إلى التقيم",
        gift_share:"الذهاب إلى المشاركة",
        gift_bind:"الذهاب إلى الربط",
        gift_fill:"الذهاب إلى الملئ",
        gift_ads:"شاهد الآن",
        gift_copy:"نسخ رمز الهدية",
        gift_exchange:"الفداء",
        gift_exchange_desc:"الامتيازات - رمز التفعيل - أدخل رمز الهدية للتفعيل",
        password_help:"كيف يمكنني مشاهدة كلمة المرور الخاصة بحسابي؟",
        password_help_1:"1. انقر على الصورة الرمزية في الزاوية اليسرى العليا",
        password_help_2:"2. انقر على الأيقونة الحمراء 'مركز المستخدم' في الجزء السفلي",
        password_help_3:"3. انقر على 'تبديل الحساب'",
        password_help_4:"4. انقر على أيقونة العين لعرض كلمة مرور الحساب الحالي",
        payhelp_title:"الرجاء تحديد منصة الدفع",
        payhelp_google:"البحث على Google Play",
        payhelp_google1:"1. افتح Google Play وانقر على شريط القائمة على اليسار",
        payhelp_google2:"2. انقر فوق حسابي",
        payhelp_google3:"3. انقر فوق الطلبات",
        payhelp_google4:"4. انقر فوق الأمر المقابل لمشاهدة الإيصال",
        payhelp_wechat:"البحث في Wechat",
        payhelp_wechat1:"1. انقر لفتح Wechat",
        payhelp_wechat2:"2. انقر فوق [My]",
        payhelp_wechat3:"3. انقر على الدفع - المحفظة",
        payhelp_wechat4:"4. انقر فوق الفاتورة",
        payhelp_wechat5:"5. انقر فوق الطلب المقابل لعرض إيصال استلام الطلب",
        payhelp_paypal:"البحث في Paypal",
        payhelp_paypal1:"1. تسجيل الدخول إلى حساب Paypal الخاص بك",
        payhelp_paypal2:"2. انقر فوق سجلات المعاملات",
        payhelp_paypal3:"3. انقر فوق 'تفاصيل'",
        payhelp_paypal4:"4. انقر فوق الطلب المقابل لعرض التفاصيل",
        payhelp_alipay:"البحث في Alipay",
        payhelp_alipay1:"1. انقر لفتح Alipay",
        payhelp_alipay2:"2. انقر فوق [My]",
        payhelp_alipay3:"3. انقر فوق الفاتورة",
        payhelp_alipay4:"4. انقر فوق الطلب المقابل لعرض الإيصال",
        bind_mobile:"رابط الهاتف المحمول",
        bind_mobile_tips:"أنت تربط هذا الحساب بهاتفك المحمول",
        input_old_mobile:"الرجاء إدخال رقم هاتفك المحمول القديم",
        input_validator_mobile:"الرجاء إدخال رقم الهاتف المحمول الصحيح",
        input_bind_mobile:"الرجاء إدخال رقم الهاتف المحمول الذي ترغب في ربطه",
        mobile_tips:"قد يقيم بعض الهواتف المحمولة رسائلنا كرسائل غير مرغوب فيها. يرجى الذهاب إلى [رسائل غير مهمة] للتحقق من رمز التحقق.",
        contact_mobile:"رقم الهاتف المحمول للاتصال",
        input_contact_mobile:"الرجاء إدخال رقم الهاتف المحمول الخاص بك",
        input_mobile:"الرجاء إدخال رقم هاتفك المحمول",
        mobile:"رقم الهاتف المحمول",
        unbind_mobile:"يرجى ربط بريدك الإلكتروني قبل إلغاء الربط",
        input_old_verify_mobile:"الرجاء إدخال رمز التحقق على هاتفك القديم",
        input_new_verify_mobile:"الرجاء إدخال رمز التحقق على هاتفك",
        pay_title:"إجمالي :",
        pay_change_currency:"تبديل العملة",
        pay_tips:"(قد يختلف مبلغ الدفع النهائي وفقًا لطرق الدفع المختلفة وأسعار الصرف)",
        pay_select_mode:"تحديد طريقة الدفع",
        wechat:"دفع WeChat",
        alipay:"Alipay",
        pay_paying:"جاري طلب الدفع ...",
        pay_fail:"فشل الشحن. الرجاء معاودة المحاولة في وقت لاحق.",
        pay_return_tips:"الرجاء إغلاق هذه الصفحة إذا تم استكمال الدفع الخاص بك ، أو سيتم البدء في دفع أخر.",
        pay_discount:"(خصم /0/%)",
        pay_late_mode:"تم تحديد طريقة الدفع الأخيرة",
        pay_other_mode:"طرق الدفع الأخرى",
        pay_more:"المزيد من الطرق ",
        pay_point:"الشحن /0/ نقطة لتحصل على /1/ نقطة مجانا",
        points:"شحن نقاط اللعبة",
        current_points:"النقاط الحالية:",
        pay:"الشحن",
        pwd:"كلمة المرور",
        pay_setting:"تعيين كلمة مرور الدفع",
        select_amount:"الرجاء تحديد عدد النقاط لـ الشحن",
        forget:"استرجاع كلمة مرور الدفع",
        forget_pay:"نسيت كلمة مرور الدفع",
        input_pwd:"الرجاء إدخال كلمة مرور دفع مكونة من 6 أرقام",
        different:"كلمات المرور غير متطابقة",
        setting_tips:"أنت تقوم بتعيين كلمة مرور للدفع. يرجى الحفاظ على كلمة مرور الدفع الخاصة بك",
        remain:"الرصيد",
        add:"نقاط الشحن",
        pay_mode:"طرق الدفع",
        change_payPwd_tips:"أنت تقوم بتغيير كلمة مرور الدفع الخاصة بك. يرجى الاحتفاظ بكلمة مرور الدفع الجديدة الخاصة بك",
        unsetting:"لا توجد كلمة مرور للدفع",
        email_unbound:"لا يوجد بريد إلكتروني مربوط",
        confirm_pay_pwd:"أكد كلمة مرور الدفع",
        input_confirm_pay_pwd:"الرجاء إعادة إدخال كلمة مرور الدفع",
        payPassword_error:"كلمة مرور دفع خاطئة",
        balance:"الرصيد غير كافي",
        gamePoints:"نقاط",
        pay_complete:"إكمال الدفع",
        pay_new_tips:"لا تغلق هذه الصفحة حتى يكتمل الشحن",
        pay_new_message:"الرجاء إكمال الشحن على الصفحة الجديدة",
        pay_cancel:"إلغاء الدفع",
        back:"رجوع",
        fee_name:"برنامج التسويق بالعمولة",
        fee_text1:"العمل مع HandZone Games",
        fee_text2:"كشريك في HandZone Games ، ستحصل على رابط تسويقي فريد. أي عمليات شحن تتم عبر هذا الرابط ستجلب لك أرباحًا إضافية.",
        fee_text3:"تحتاج إلى تلبية المتطلبات التالية للانضمام إلى برنامج التسويق بالعمولة HandZone Games.",
        fee_price:"مبلغ الشحن مساوي:",
        fee_text4:"سيكون برنامج التسويق بالعمولة متاحًا لك عند استيفاء المتطلبات ، وستحصل على 10%-70% (تختلف بناءً على مستويات الشركة التابعة) من المبلغ الذي تمت إضافته بواسطة لاعبين جدد قاموا بتثبيت اللعبة من خلال الرابط الخاص بك.",
        fee_text5:"تهانينا! لقد انضممت إلى برنامج شريك التسويق",
        fee_text6:"رابط التسويق الخاص بك:",
        fee_copy:"نسخ الرابط",
        fee_text7:"المستويات الحالية للعمولات:",
        fee_text8:"ثلاثة لاعبين, A, B and C, يشحنون /0/ على التوالي",
        fee_text9:"سوف تحصل:",
        fee_text10:"يمكن تطويرها بشكل أكبر",
        fee_text11:"لديك الآن /0/ Lv.1 شركاء, /1/ Lv.2 شركاء و /2/ Lv.3 شركاء",
        fee_price2:"المبلغ المتاح للسحب:",
        fee_start:"ابدأ التسويق",
        fee_submit:"سحب",
        fee_tips1:"لم يتم استيفاء متطلبات السحب",
        contact_kf:"اتصل بخدمة العملاء",
        fee_tips2:"التعاقد مع خدمة العملاء لعمليات السحب",
        account_destroy:"إلغاء الحساب",
        account_destroy_now:"قدم طلب إلغاء الحساب",
        account_destroy_cancel:"إبطال طلب إلغاء حسابك",
        account_destroy_time:"الوقت المتبقي لإلغاء الحساب:",
        account_destroy_time2:"/0/ يوم(s) في وقت لاحق",
        account_destroy_cancel_tips:"لقد ألغيت طلب إلغاء الحساب",
        account_destroy_read_tips:"لقد قرأت ووافقت على ",
        account_destroy_read_tips2:"قواعد إلغاء الحساب",
        account_destroy_content1:"●1. عند الإلغاء <a>لا يمكن لهذا الحساب تسجيل الدخول إلى النظام الأساسي لحساب شركتنا أو أي منتجات</a> & <a>لا يمكن استرداد الحساب والبيانات</a>;",
        account_destroy_content2:"●2. نحتاج إلى <a>جمع معلوماتك الشخصية الحساسة (بما في ذلك رمز التحقق من البريد الإلكتروني)</a> لغرض التحقق من الهوية لمعالجة طلب إلغاء حسابك. عندما يتم إلغاء حسابك ، فإن شركتنا <a>ستحذف المعلومات المذكورة أعلاه أو تجعلها مجهولة</a>;",
        account_destroy_content3:"●3. عندما يتم إلغاء حسابك ، سيتم <a>حذف جميع المحتويات والمعلومات والتسجيلات في حسابك أو جعلها مجهولة</a>. لم يعد بإمكانك البحث عن أي شيء في حسابك أو الوصول إليه أو الحصول عليه أو الاستمرار في استخدامه أو استرداده ، أو أن تطلب من شركتنا استرداده لك (ما لم يقتضي القانون أو السلطات التنظيمية خلاف ذلك);",
        account_destroy_content4:"●4. عندما يتم إلغاء حسابك ، لم يعد بإمكانك تسجيل الدخول إلى منصة معاملات الحساب المقابلة. <a>يشير طلب إلغاء حسابك إلى مصادرتك طواعية لجميع الاهتمامات التي لديك في هذا الحساب</a>. <a>قبل الإلغاء ، يرجى التأكد من اكتمال طلبات المعاملة والسحب على النظام الأساسي</a> لمنع أي خسارة غير ضرورية نتيجة عدم قدرتك على الوصول إلى النظام الأساسي بعد إلغاء الحساب;",
        account_destroy_content5:"●5. عند إلغاء حسابك, <a>يحق لشركتنا التوقف عن تقديم أي خدمة متعلقة بهذا الحساب</a>. سيتم إنهاء اتفاقية المستخدم والمستندات الأخرى التي تحمل حقوقًا والتزامات وقعتها مع شركتنا (ما لم يتم الاتفاق على خلاف ذلك بيننا أو مطلوبًا بموجب القانون);",
        account_destroy_content6:"●6. أنت وحدك المسؤول عن أي إزعاج أو عواقب سلبية ناجمة عن إلغاء حسابك. لن تكون شركتنا مسؤولة عن أي من ذلك.",
        account_destroy_content7:"●7. إذا كان هناك أي نزاع على الملكية أثناء إلغاء الحساب ، يحق لشركتنا إنهاء طلب الإلغاء الخاص بك;",
        account_destroy_content8:"●8. هناك فترة تأكيد مدتها 7 أيام لإلغاء الحساب ، يمكنك خلالها الدخول إلى هذه الواجهة لإلغاء طلبك. بعد فترة التأكيد ، لا يمكنك إلغاء طلب إلغاء حسابك.",
        unbind_account_destroy:"يرجى ربط بريدك الإلكتروني قبل إلغاء الحساب",
        pay_go:"الشحن",
        either_or:"أو",
        my_info_avatar:"الصورة الرمزية",
        avatar:"الصورة الرمزية",
        look_avatar:"مشاهدة الصورة الرمزية",
        change_avatar:"تتغير الصورة الرمزية",
        avatar_reviewing:"المراجعة",
        avatar_review_fail:"فشل المراجعة",
        zbm0006:"عزيزي اللاعب ، الصورة الرمزية المخصصة التي قمت بتحميلها تحتوي على /0/ ولم يتم الموافقة عليها",
        shhz0001:"محتويات حساسة سياسياً",
         shhz0002:"محتويات إباحية",
          shhz0003:"محتويات القمار",
        shhz0004:"محتويات متعلقة بالمخدرات",
        shhz0005:"محتويات حساسة دينيًا",
        shhz0006:"مواقع الويب أو عناوين مواقع الويب الأخرى",
        shhz0007:"إعلانات",
        shhz0008:"محتويات غير لائقة أو مبتذلة",
        account:"اسم المستخدم",
        vip_kf:"خدمة العملاء VIP",
        super_vip:"سوبر VIP",
        super_vip_kf:"خدمة عملاء سوبرVIP الحصرية",
        super_vip_be:"أصبح سوبر VIP<br/>استمتع بخدمة حصرية",
        super_vip_info1:"خدمة سوبر VIP الحصرية",
        super_vip_info2:"(اللاعبين البالغين فقط)",
        super_vip_info3:"1. قناة حصرية<br/>خدمة حصرية فردية. سيتم إرسال نصيحتك مباشرة إلى المطورين!<br/>2. أولوية حصرية<br/>احصل على أولوية الوصول إلى معلومات اللعبة الأكثر موثوقية<br/>3. حزم حصرية<br/>أصبح سوبر VIP لدينا للحصول على حزم سوبر كبرى!<br/>4. أحداث حصرية<br/>استمتع بالأحداث والامتيازات الحصرية من وقت لآخر!",
        super_vip_gain1:"كيف تصبح سوبر VIP",
        super_vip_gain2:"الطريقة الأولى: يصل إجمالي مبلغ الشحن إلى /0/",
        super_vip_get:"احصل عليه الآن",
        super_vip_gift:"حزمة سوبر VIP الحصرية",
        super_vip_gift_tips:"يمكنك استلام الحزمة الحصرية عند التحقق بنجاح. اذهب واتصل بخدمة العملاء لدينا!",
        super_vip_gift_1:"اللقب داخل اللعبة",
        super_vip_gift_2:"زينة نادرة",
        super_vip_gift_3:"حيوان أليف",
        super_vip_gift_4:"حزمة مخصصة",
        super_vip_gift_5:"عنصر نادر",
        super_vip_gift_wechat:"WeChat:",
        feedback_reward_1:"مكافأة خدمة العملاء",
        feedback_reward_2:"المكافأة",
        feedback_reward_3:"المكافأة هي هديتك التطوعية لخدمة العملاء لدينا وسيتم دفع كامل قيمتها لموظفي خدمة العملاء المسؤولين",
        wechat_native_1:"مبلغ الدفع:",
        wechat_native_2:"يرجى فتح WeChat على هاتفك المحمول وإجراء المسح الضوئي لإكمال الدفع",
        wechat_native_3:"تم الدفع",
        login_usern:"تسجيل الدخول",
        register_username:"التسجيل",
        quick_register:"تسجيل سريع",
        enterNow:"تسجيل الدخول الآن",
        btn_register:"التسجيل الآن",
        agreed1:"أوافق",
        btn_have_usern:"لدي حساب",
        to_login:"الذهاب إلى تسجيل الدخول",
        login_success:"نجح تسجيل الدخول",
        agree_protocol:"يرجى الموافقة على شروط الخدمة",
        UserLogin:"تسجيل الدخول",
        UserLogin_text1:"أدخل اسم المستخدم",
        UserLogin_text2:"أدخل كلمة المرور",
        UserLogin_text4:"تسجيل الدخول",
        UserLogin_text5:"تسجيل",
        UserLogin_text6:"تم التسجيل بنجاح",
        online_earning:"الربح من الإنترنت",
        online_earning_center:"مركز الربح",
        online_earning_sigtiaoin:"تحدي تسجيل الدخول",
        online_earning_text1:"إكمال المهام<br>مقابل المال",
        online_earning_text2:"شاهد الفيديو مرة واحدة لتسجيل الدخول<br/>احصل على مكافآت سخية لمدة 7 أيام إجمالاً",
        online_earning_text3:"يمكن استخدامها لاسترداد المكافآت",
        online_earning_text4:"شاهد واستلم المكافآت",
        online_earning_text5:"المتبقي: /0/",
        online_earning_text6:"محاولات غير كافية",
        online_earning_text7:"غير مستلم",
        exchange:"تبادل: /0/",
        exchange_center:"مركز الاسترداد",
        exchange_prompt:"نصائح الاسترداد",
        exchange_confirm:"تأكيد",
        exchange_consume:"التكلفة: /0/",
        exchange_balance:"الرصيد",
        exchange_detail:"التفاصيل",
        exchange_balance_detail:"تفاصيل الرصيد",
        exchange_reward:"تبادل المكافآت",
        exchange_text1:"/0/ النقاط",
        exchange_text2:"$/0/",
        exchange_text3:"نقاط",
        exchange_text4:"حزمة نقدية",
        exchange_text5:"حد التبادل: /0///1/",
        exchange_text6:"الحد اليومي：/0///1/",
        exchange_text7:"أدخل كود الدعوة للربط",
        exchange_text8:"أدخل حساب PayPal الخاص بك",
        exchange_text9:"ربط الداعي",
        exchange_text10:"تأكيد",
        exchange_text11:"متبادل. يمكنك التحقق من النتائج في مركز المستخدم",
        exchange_text12:"كمية غير كافية",
        exchange_text13:"إظهار أحدث 100 سجل فقط",
        exchange_rule:"قواعد",
        exchange_rule2:"قواعد الاسترداد",
        exchange_rule2_html:"1. يلزم ربط البريد الإلكتروني لتنشيط استرداد الحساب;<br/>2. يرجى التأكد من المعلومات الدقيقة أثناء الاسترداد. أي خسائر ناجمة عن أخطاء شخصية في الربط أو الاسترداد هي مسؤوليتك الخاصة;<br />3. في حالة فشل الاسترداد، يرجى تقديم رسالة الخطأ. إذا لم يتم حل المشكلة، فيرجى الاتصال بخدمة العملاء;<br />4. إن بدء الاسترداد يعني موافقتك على القواعد. حقوق الترجمة النهائية للاسترداد مملوكة لشركة هاندزون.",
        invite_Friends:"دعوة أصدقاء",
        invite_binding:"ربط الداعي",
        new_envelope:"حزمة المبتدئين",
        signReward:"إكمال تسجيل الدخول",
        completeTask:"إكمال /0/ المهام",
        completeExchange:"استرداد: /0/، التكلفة",
        language:"لغة",
        country:"البلد / المنطقة",
        select_pay_mode:"اختر الحصول على الدفعة المحلية الصحيحة",
        exchange_text14:"لم تفتح بعد",
        hint_func: "الوظيفة غير متاحة بعد، يرجى المتابعة",
        game_hub:"مركز اللعبة",
        not_login:"لم تقم بتسجيل الدخول",
        unlock:"قم بتسجيل الدخول لفتح محتوى أكثر إثارة",
        for_the_account:"أنت تقوم بإنشاء حساب",
        for_the_account_1:"ربط بريدك الإلكتروني يمكن أن يضمن ربط بريدك الإلكتروني أمان حسابك بشكل فعال. يمكنك استرداد كلمة المرور الخاصة بك من خلال بريدك الإلكتروني.",
        account_length:"طول الحساب هو 6-18 حرفا",
        modify_successfully:"تم التعديل بنجاح",
        binding_successful:"الربط ناجح",
        start_game:"يبدأ",
        game_down:"تحميل",
        new_travel:"جولة جديدة",
        please_acc_code:"الرجاء إدخال اسم المستخدم",
        please_paw_code:"الرجاء إدخال كلمة المرور",
        agree:"يوافق",
        install:"يثبت",
        logout:"خروج",
        is_logout_account:"ما إذا كان سيتم تسجيل الخروج من حساب تسجيل الدخول",
        top_free:"شعبية مجانية",
        top_jackpot:"شعبية الأكثر مبيعا",
        peace:"و",
        priv_policy:"سياسة الخصوصية",
        tally:"نقاط",
        give_away:"يشمل الهدايا",
        voucher:"قسائم",
        waiting_used:"لاستخدامها"
    }
}
