//语言
import { createI18n } from 'vue-i18n'		//引入vue-i18n组件
// import { 引入的组件 export 出来的 变量} from 'vue-i18n'

//注册i8n实例并引入语言文件
const i18n = createI18n({
    locale: 'zh_CN',		//默认显示的语言
    globalInjection:true,
    silentTranslationWarn: true,
	missingWarn: false,
	silentFallbackWarn: true,
	fallbackWarn: false,
	legacy: false,
    fallbackLocale:"en_US",
    messages: {
        zh_CN:require('./zh_CN.js'),	//引入语言文件
        en_US:require('./en_US.js'),
        zh_TW:require('./zh_TW.js'),
        ko_KR:require('./ko_KR.js'),
        ru_RU:require('./ru_RU.js'),
        de_DE:require('./de_DE.js'),
        ja_JP:require('./ja_JP.js'),
        tr_TR:require('./tr_TR.js'),
        pt_BR:require('./pt_BR.js'),
        vi_VN:require('./vi_VN.js'),
        es_LA:require('./es_LA.js'),
        ar_SA:require('./ar_SA.js'),
    }
})
export default i18n; //将i18n暴露出去，在main.js中引入挂载